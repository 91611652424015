import { Button, Image, Modal, Row } from "antd";
import classes from "./ConfirmSubscription.module.css";
import Title from "antd/es/typography/Title";
import subscriptionImg from "./../../images/Subscription/subscribe.png";

const ConfirmSubscription = (props) => {
  const selectedPlan = props.selectedPlan;

  const TitleCMP = (
    <div className="w-100 text-center">
      <Title level={4} className="mb-0">
        Confirm Subscription Plan
      </Title>
      <p className={classes.description}>
        Choose the Perfect Subscription Plan for Your Needs and Budget
      </p>
    </div>
  );

  const PlanCmp = (
    <>
      <div className={classes.parentDiv}>
        <div className={classes.div1}>
          <p>{selectedPlan?.description}</p>
          <div className={classes.div3}>
            <div className="d-flex align-items-center">
              <Image src={subscriptionImg} preview={false} />
              <Title level={4} className={classes.title}>
                {`${selectedPlan?.name} Plan`}
              </Title>
            </div>
            <div className="d-flex align-items-center">
              <p className={classes.icon}>$</p>
              <Title level={2} className={classes.title2}>
                {selectedPlan?.defaultPrice}
              </Title>
              <span className={classes.monthTxt}>/month</span>
            </div>
          </div>
        </div>
        <ul className={classes.div2}>
          {selectedPlan?.features?.map((feature, index) => (
            <li key={`plans-${feature}-${index}`}>{feature}</li>
          ))}
        </ul>
      </div>
      <div className={classes.parentDiv2}>
        <Button
          type="primary"
          className="w-100"
          onClick={() => props.makePayment()}
        >
          Make Payment
        </Button>
      </div>
    </>
  );

  return (
    <Modal
      title={TitleCMP}
      open={props.isModalOpen}
      onCancel={props.modalClose}
      footer={null}
      centered
    >
      <Row gutter={16}>{PlanCmp}</Row>
    </Modal>
  );
};

export default ConfirmSubscription;
