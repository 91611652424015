import classes from "./VerifyEmail.module.css";

import { Button, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { verifyEmailOfuser } from "../../services/VerifyEmailService";
import { useDispatch } from "react-redux";
import { fetchUser } from "../../store/slice/user_slice";

const VerifyEmail = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    verifyEmailFunction();
  }, [params?.token]);

  const verifyEmailFunction = async () => {
    try {
      setLoading(true);
      const res = await verifyEmailOfuser({ token: params.token });
      if (res?.data) {
        setData(res.data);
        dispatch(fetchUser());
      } else {
        setData(null);
        throw new Error(res?.message);
      }
      setLoading(false);
    } catch {
      setData(null);
      setLoading(false);
    }
  };

  return (
    <div className={classes.main}>
      {!loading ? (
        <div className="d-flex flex-column" >
          <Result
          style={{color:"white !important",fontSize:"1.5rem"}}
            status={data ? "success" : "500"}
            title={
              data ? "Successfully Verified Your Email" : "Token Is Invalid"
            }
          />
          <Button
            onClick={() => {
              if (data) {
                navigate("/app/dashboard");
              } else {
                navigate("/");
              }
            }}
          >
            {data ? "Go To Pribr" : "Go To Pribr"}
          </Button>
        </div>
      ) : (
        <Spin tip="Email Verifiaction in process..." size="large" style={{fontSize:'1.2rem'}}></Spin>
      )}
    </div>
  );
};

export default VerifyEmail;
