const config = {
  components: {
    Radio: {
      colorPrimary: "#0D8BFF",
      colorPrimaryHover: "#0D8BFF",
    },
    Checkbox: {
      colorPrimary: "#0D8BFF",
      colorPrimaryHover: "#0D8BFF",
    },
    Segmented: {
      colorBgElevated: "#0D8BFF",
      colorBgLayout: "#F3F9FF",
      colorText: "#FFFFFF",
      colorTextLabel: "#000000",
      borderRadius: 8,
      fontSize: 16,
    },
    Steps: {
      colorPrimary: "#0D8BFF",
      colorText: "#0D8BFF",
      colorTextDescription: "#0D8BFF",
      controlItemBgActive: "#314259",
      colorFillContent: "#E7F3FF",
      controlHeight: 64,
      controlHeightSM: 24,
      fontSizeLG: 16, // heading text size
      fontSize: 14, // description text size
    },
    Select: {
      colorBgContainer: "#F9F9F9",
      controlHeight: 52,
      borderRadius: 6,
    },
  },
  token: {
    fontFamily: "DMSansRegular",
    colorPrimary: "#314259",
    colorPrimaryHover: "#0D8BFF",
    colorError: "#D20000",
  },
};

export default config;
