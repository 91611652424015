import { Col, Image, Modal, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import notFoundImage from "../../../../src/images/NoResult/not-found.jpg";
import { getDepartmentByID } from "../../../services/GeneralService";
import classes from "./ProductDetailModal.module.css";
const { Title } = Typography;

const dataObj = {
  name: { name: "Product Name" },
  description: { name: "Description" },
  categoryId: { name: "Category" },
  departmentId: { name: "Department" },
  caseDimension: { name: "Case Pack dimensions" },
  caseWeight: { name: "Case pack weight" },
  minimumOrderQuantity: { name: "Minimum Order Quantity" },
  avgUnitSize: { name: "Average Unit Size" },
  avgUnitWeight: { name: "Average Unit Weight" },
};

const arraysAreEqual = (arr1, arr2) => {
  if (arr1 === arr2) return true;
  if (arr1 == null || arr2 == null) return false;
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};
const ProductDetailModal = (props) => {
  const isModalOpen = props.showModel;
  const handleOk = props.handleOk;
  const handleCancel = props.handleCancel;
  const [carousalItems, setCarousalItems] = useState([]);
  const [slideToShow, setSlideToShow] = useState(2);

  const [currentImage, setCurrentImage] = useState("");
  const generalData = useSelector((state) => state.generalData);
  const [departmentName, setDepartmentName] = useState("");

  useEffect(() => {
    const newArray = props.product?.mediaURLs.map((element) => {
      return {
        src: element,
      };
    });

    // Compare newArray with the current carousalItems state using isEqual
    if (!arraysAreEqual(newArray, carousalItems)) {
      if (newArray.length < 2) {
        setSlideToShow(1);
      }
      setCurrentImage(newArray[0].src);
      setCarousalItems(newArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.product?.mediaURLs]);

  async function fetchData(id) {
    try {
      const res = await getDepartmentByID(id);
      setDepartmentName(res?.data?.name);
    } catch (err) {
      // console.log("Error is ", err);
      // Handle error
    }
  }
  useEffect(() => {
    fetchData(props.product.departmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const productDetails = Object.entries(props.product).flatMap(
    ([name, value]) => {
      const item = dataObj[name];
      let mappedName = item?.name;

      if (
        name === "id" ||
        name === "mediaURLs" ||
        name === "moqType" ||
        name === "userId" ||
        name === "createdAt" ||
        name === "updatedAt" ||
        name === "departmentId" ||
        name === "categoryId" ||
        name === "userId" ||
        name === "department_id" ||
        name === "category_id" ||
        name === "user_id"
      ) {
        return []; //not required to show
      }

      if (name === "categoryId") {
        value = generalData.categories.find((obj) => obj.id === value)?.name;
      }

      if (name === "departmentId") {
        value = departmentName;
      }

      return {
        name: mappedName,
        data: value,
      };
    },
  );

  const infoComp = productDetails.map((item, i) => (
    <Row key={`product-list-${item.name}-${i}`}>
      <Col xs={12} sm={12} md={12} lg={12}>
        <p className={classes.dataheading}>{item.name}</p>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} align="right">
        <p className={classes.dataContent}>{item.data}</p>
      </Col>
    </Row>
  ));

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: slideToShow,
    slidesToScroll: 1,
    vertical: true, // Enable vertical mode
    verticalSwiping: false, // Enable vertical swiping
    autoplay: true,
    autoplaySpeed: 500,
    responsive: [
      {
        breakpoint: 767,
        settings: { vertical: false },
      },
    ],
  };

  const carousalClickHandler = (item) => {
    setCurrentImage(item);
  };
  const carousal = carousalItems.map((item, index) => (
    <div style={{ marginBottom: "5px" }} key={`carousal-item-${index}`}>
      <Image
        src={item.src}
        onClick={() => {
          carousalClickHandler(item.src);
        }}
        preview={false}
        className={classes.carasoulImage}
        width={150}
        height={150}
      />
    </div>
  ));

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={980}
      footer={null}
      centered
    >
      <Row justify="center">
        <Title
          level={3}
          style={{
            textAlign: "center",
            marginBottom: "25px",
            marginTop: "0px",
          }}
        >
          Product Details
        </Title>
      </Row>
      <Row justify="center">
        {carousalItems.length > 1 && (
          <Col xs={24} md={6} lg={4} xl={4} xxl={4}>
            <Slider {...settings}>{carousal}</Slider>
          </Col>
        )}
        <Col
          xs={24}
          md={18}
          lg={20}
          xl={20}
          xxl={20}
          className={classes.carasoul}
        >
          <Image
            src={currentImage ? currentImage : notFoundImage}
            preview={false}
            height={"100%"}
            width={"auto"}
            className={classes.mainImage}
          />
        </Col>
      </Row>

      <div className={`${classes.section} ${classes.customModalSection}`}>
        <Title level={3} style={{ padding: "0 0 0 20px" }}>
          Details
        </Title>
        {infoComp}
      </div>
    </Modal>
  );
};

export default ProductDetailModal;
