import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import RegisterFlowLayout from "../Layout/RegisterFlowLayout";
import classes from "./Signup.module.css";
import SupplierSignup from "./SupplierSignup";

const Signup = () => {
  return (
    <div className={"maxWidth"}>
      <div className={classes.container}>
        <RegisterFlowLayout title={"Sign Up"}>
          <SupplierSignup />

          <Row>
            <Col span={24}>
              <p className={classes.terms}>
                By continuing you agree to our{" "}
                <Link to="/public/terms">Terms of Usage</Link> and{" "}
                <Link to="/public/privacy">Privacy Policy</Link>
              </p>
            </Col>
            <Col span={24}>
              <p className={classes.terms}>
                Existing user? <Link to="/public/signin">Sign In</Link>
              </p>
            </Col>
          </Row>
        </RegisterFlowLayout>
      </div>
    </div>
  );
};

export default Signup;
