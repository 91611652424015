import APIConstant from "../constant/APIConstant";
import Request from "./UtilsService";

export async function GetAllSubscriptions() {
  return await Request({
    url: APIConstant.STRIPE_BASE_URL + APIConstant.ALL_SUBSCRIPTION,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//For purchase subscription
export async function CreateCustomerStripe(data) {
  return await Request({
    url: APIConstant.STRIPE_BASE_URL + APIConstant.CUSTOMERS,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//For upgrade subscription
export async function UpgradeSubscription(data) {
  return await Request({
    url: APIConstant.STRIPE_BASE_URL + APIConstant.UPGRADE_SUBSCRIPTION,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//For cancel subscription
export async function CancelSubscription(data) {
  return await Request({
    url: APIConstant.STRIPE_BASE_URL + APIConstant.CANCEL_SUBSCRIPTION,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//For get subscription of user
export async function GetUserSubscription(data) {
  return await Request({
    url:
      APIConstant.STRIPE_BASE_URL +
      APIConstant.GET_SUBSCRIPTION +
      APIConstant.CUSTOMER +
      APIConstant.DETAILS +
      "/" +
      data.userId,
    method: "GET",
    //body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
