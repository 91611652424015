import { Button, Progress } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import classes from "./ProfileCompleteNoticeDash.module.css";

const ProfileCompleteNoticeDash = () => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  const percentage = useSelector((state) => state.percentage.value);
  const navigate = useNavigate();

  const completeNowClickHandler = () => {
    navigate("/app/createprofile");
  };

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isSmallScreen = screenWidth < 1175;

  return (
    <div className={classes["bar"]}>
      <div className={classes["percentage"]}>
        <div className={classes["flex-center"]}>
          <Progress
            type="circle"
            percent={percentage}
            size={"small"}
            width={isSmallScreen ? 20 : 40}
            height={isSmallScreen ? 20 : 40}
            strokeWidth={12}
            strokeColor="#fff"
          />
          <div className={classes["percentage-text"]}>
            <h3 className={classes["text"]}>{percentage}%</h3>
            <h5 className={classes["text"]}>Profile Complete</h5>
          </div>
        </div>

        <Button
          className={classes["complete-btn"]}
          onClick={completeNowClickHandler}
        >
          Complete Now
        </Button>
      </div>
    </div>
  );
};

export default ProfileCompleteNoticeDash;
