import { createSlice, nanoid } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    activeChat: null,
    chatListLoading: true,
    chatList: [],
    chatMessageLoading: false,
    chatMessages: [],
    floatingWindows: [
      {
        id: "v5PQlG3hxY4c6R1LrB1gv",
        open: false,
        windowType: "chat",
        activeChat: null,
      },
    ],
  },
  reducers: {
    addWindow(state, action) {
      const { windowType, activeChat = null } = JSON.parse(
        JSON.stringify(action.payload),
      );
      const activeChatUsr = activeChat?.users?.map((item) => item.id);
      const windows = JSON.parse(JSON.stringify(state.floatingWindows));
      const windowOpened = windows?.some((w) =>
        w?.activeChat?.users?.every((u) => activeChatUsr?.includes(u.id)),
      );
      if (activeChatUsr && windowOpened) {
        return;
      }
      const id = nanoid();
      const data = { id, open: true, windowType, activeChat };
      const chatWindowNotExist = state.floatingWindows.length
        ? state.floatingWindows.some((window) => window.windowType === "chat")
        : true;
      if (data.windowType === "chat" && chatWindowNotExist) {
        state.floatingWindows.splice(0, 1, data);
      } else if (state.floatingWindows.length < 3) {
        state.floatingWindows.push(data);
      } else {
        state.floatingWindows.splice(1, 1, data);
      }
    },
    removeWindow(state, action) {
      state.floatingWindows = state.floatingWindows.filter(
        (w) => w.id !== action.payload,
      );
    },
    toggleWindow(state, action) {
      state.floatingWindows = state.floatingWindows.map((w) =>
        w.id === action.payload ? { ...w, open: !w.open } : w,
      );
    },
    setActiveChat(state, action) {
      state.activeChat = action.payload;
      state.chatMessageLoading = !!action.payload;
    },
    setChatList(state, action) {
      state.chatList = action.payload || [];
      state.chatListLoading = false;
    },
    updateChatList(state, action) {
      const itemIndex = state.chatList.findIndex(
        (chat) => chat.id === action.payload.id,
      );
      if (itemIndex >= 0) state.chatList.splice(itemIndex, 1);
      state.chatList.unshift(action.payload);
    },
    setMessages(state, action) {
      state.chatMessages = action.payload;
      state.chatMessageLoading = false;
    },
    updateMessage(state, action) {
      const index = state.chatMessages?.findIndex(
        (item) => item.id === action.payload.id,
      );
      if (index >= 0) {
        state.chatMessages.splice(index, 1, action.payload);
      } else {
        state.chatMessages.push(action.payload);
      }
    },
  },
});

export default chatSlice.reducer;

export const {
  addWindow,
  removeWindow,
  toggleWindow,
  setChatList,
  updateChatList,
  setActiveChat,
  setMessages,
  updateMessage,
} = chatSlice.actions;
