import { Suspense, lazy } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import { useSelector } from "react-redux";
import MainLayout from "./components/Layout/Layout";
import LandingPage from "./pages/LandingPage";

const FloatWindowChatNew = lazy(() =>
  import("./components/Chats/FloatWindows"),
);

const PrivateWrapper = () => {
  const isAuthenticated = localStorage.getItem("token");

  return isAuthenticated ? <Outlet /> : <Navigate to="/public/signin" />;
};

export const RenderRoutes = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();

  return (
    <Suspense>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" index element={<LandingPage />} />
        </Route>
        <Route path="public/*" element={<PublicRoutes />} />
        <Route element={<PrivateWrapper />}>
          <Route path="app/*" element={<PrivateRoutes />} />
        </Route>
      </Routes>
      {location.pathname !== "/app/chat" &&
      !location.pathname.includes("/public/verify-email") &&
      isAuthenticated ? (
        <Suspense fallback={null}>
          <FloatWindowChatNew />
        </Suspense>
      ) : null}
    </Suspense>
  );
};
