import {
  HomeOutlined,
  MoreOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Tooltip } from "antd";
import {
  addDoc,
  collection,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useWindowSize from "../../hooks/useWindowSize";
import { AddToMyNetwork } from "../../services/DashboardService";
import { db } from "../../services/firebase";
import { getMemberInformation } from "../../services/MyProfileService";
import { addWindow, setActiveChat } from "../../store/slice/chat_slice";
import { fireStoreAutoId } from "../../utils/chat.helper";
import classes from "./Card.module.css";

const Card = (props) => {
  const user = props.user;
  // console.log("user:", user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [initializingChat, setInitializingChat] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const chatList = useSelector((state) => state.chat.chatList);
  const userObj = useSelector((state) => state.user.data);

  const handleOptionClick = async (e) => {
    if (e.key === "option1") {
      navigate(`/app/supplier/${user.userId}`);
    } else if (e.key === "option2") {
      initiateChat();
    } else if (e.key === "option3") {
      try {
        let objUser = {
          userId: userObj.id,
          networkUserId: user.userId,
        };
        const res = await AddToMyNetwork(objUser);
        if (res) {
          toast.success(res.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
      props.setUpdateUsers();
    }
    setIsDropdownOpen(false);
  };

  const initiateChat = async () => {
    try {
      const id = userObj.id;
      const receiverId = String(user?.userId);
      const username = userObj?.userName;
      if (id === receiverId) {
        throw new Error("You can't chat with your own.");
      }
      setInitializingChat(true);
      const chatExist = chatList.find((item) =>
        item.users.every(
          (u) => String(u.id) === id || String(u.id) === receiverId,
        ),
      );
      if (chatExist) {
        if (width < 768) {
          dispatch(setActiveChat(chatExist));
          navigate("/app/chat");
        } else {
          dispatch(addWindow({ windowType: "chat" }));
          dispatch(addWindow({ windowType: "message", activeChat: chatExist }));
        }
        setInitializingChat(false);
        return;
      }
      const receiverUserDetails = await getMemberInformation({
        userId: receiverId,
      });
      if (!id || !username || !receiverId || !receiverUserDetails?.data?.name) {
        throw new Error("Required user details not found.");
      }
      const sender = {
        id,
        name: username || "",
        avatar: userObj?.profilePath || "",
      };
      const receiver = {
        id: receiverId,
        name: receiverUserDetails?.data?.name || "",
        avatar: receiverUserDetails?.data?.profilePath || "",
      };
      const users = [sender, receiver];
      const data = {
        chatId: fireStoreAutoId(),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        lastMessage: {},
        users,
        [`unread_${receiverId}`]: 0,
        [`unread_${id}`]: 0,
      };
      const newChat = await addDoc(collection(db, "chats"), data);
      const newChatTemp = await getDoc(newChat);
      const newActiveChat = { ...newChatTemp.data(), id: newChatTemp.id };
      if (width < 768) {
        dispatch(setActiveChat(newActiveChat));
        navigate("/app/chat");
      } else {
        dispatch(addWindow({ windowType: "chat" }));
        dispatch(
          addWindow({ windowType: "message", activeChat: newActiveChat }),
        );
      }
      setInitializingChat(false);
    } catch (err) {
      setInitializingChat(false);
      toast.error(err.message);
    }
  };

  const items = [
    {
      label: "View Profile",
      key: "option1",
      onClick: handleOptionClick,
    },
    {
      label: "Chat",
      key: "option2",
      disabled: initializingChat,
      onClick: handleOptionClick,
    },
    {
      label: user.inNetwork ? "Remove from Network" : "Add to Network",
      key: "option3",
      onClick: handleOptionClick,
    },
  ];

  const AddOrRemoveUser = async () => {
    try {
      let objUser = {
        userId: userObj.id,
        networkUserId: user.userId,
      };
      const res = await AddToMyNetwork(objUser);
      if (res) {
        toast.success(res.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    props.setUpdateUsers();
  };

  return (
    <div
      className={`${classes.card} ${
        user?.userType === "SUPPLIER"
          ? classes["card-supplier"]
          : user?.userType === "RETAILER"
          ? classes["card-retailer"]
          : ""
      }`}
    >
      <div
        className="d-flex"
        onClick={() => {
          if (!isDropdownOpen) {
            navigate(`/app/supplier/${user.userId}`);
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <div className={classes["card-img"]}>
          <Avatar
            src={user.companyLogoURL}
            alt="user"
            className={classes["profile-img"]}
          />
          {/* <CheckCircleOutlined
            className={
              classes["card-select-icon"] + " " + classes["de-selected"]
            }
          />
          <CheckCircleFilled
            className={classes["card-select-icon"] + " " + classes["selected"]}
          /> */}
        </div>
        <div className={classes["card-detail"]}>
          <h3 className={classes["card-title"]}>{user.memberName}</h3>
          <h5 className={classes["card-description"]}>
            <HomeOutlined /> {user.companyName}
          </h5>
        </div>
      </div>
      <div style={{ cursor: "pointer" }} className={classes["add-network"]}>
        {user?.userType === "SUPPLIER" && (
          <h5 className={classes["card-supplier-tag"]}>{user.userType}</h5>
        )}
        {user?.userType === "RETAILER" && (
          <h5 className={classes["card-retailer-tag"]}>{user.userType}</h5>
        )}
        <div>
          {user.inNetwork ? (
            <Tooltip placement="top" title={"Remove From Network"}>
              <UserDeleteOutlined
                style={{
                  fontSize: "24px",
                  color: "#ff4d4f",
                  marginRight: "5px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  AddOrRemoveUser();
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="top" title={"Add To Network"}>
              <UserAddOutlined
                style={{
                  fontSize: "24px",
                  color: "#1890ff",
                  marginRight: "5px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  AddOrRemoveUser();
                }}
              />
            </Tooltip>
          )}

          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            onOpenChange={(visible) => {
              setIsDropdownOpen(visible);
            }}
          >
            <MoreOutlined
              style={{ fontSize: "26px", color: "black" }} // Add color property here
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Card;
