import { Navigate, Route, Routes } from "react-router-dom";
// import AboutusPage from "../pages/AboutusPage";
import MainLayout from "../components/Layout/Layout";
import ForgotPassPage from "../pages/ForgotPassPage";
import PrivacyPage from "../pages/PrivacyPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import SigninPage from "../pages/SigninPage";
import SignupPage from "../pages/SignupPage";
import VerifyOtpPage from "../pages/VerifyOtpPage";
// import TermsPage from "../pages/TermsPage";
import AboutUsPage from "../pages/AboutusPage";
import PlansAndFeatures from "../pages/PlansAndFeatures";
import TermsPage from "../pages/TermsPage";
import UnsubscribeNewsletterPage from "../pages/UnsubscribeNewsletterPage";
import VerifyEmailPage from "../pages/VerifyEmailPage";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/signup" element={<SignupPage isSupplier={1} />} />
        {/* Hide Terms & Conditions and About Us page as per client message on
        skype group on 26th Dec, 2023 */}
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/signin" element={<SigninPage isSupplier={1} />} />
        <Route path="/forgotpassword" element={<ForgotPassPage />} />
        <Route path="/verifyOtp" element={<VerifyOtpPage />} />
        <Route path="/resetpassword" element={<ResetPasswordPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/*" element={<Navigate to="/public/signin" />} />
        <Route path="/plans-and-features" element={<PlansAndFeatures />} />
      </Route>
      <Route path="/verify-email/:token" element={<VerifyEmailPage />} />
      <Route
        path="/unsubscribe/:email"
        element={<UnsubscribeNewsletterPage />}
      />
    </Routes>
  );
};

export default PublicRoutes;
