import React, { useMemo } from "react";
import classes from "./ChatPage.module.css";
import { Col, Layout, Row } from "antd";
import Footer from "../Footer/Footer2";
import ChatList from "./ChatList";
import ChatMessages from "./ChatMessages";
import useWindowSize from "../../hooks/useWindowSize";

const { Footer: AntDFooter, Content } = Layout;

const style = {
  width: "0px",
  height: "0px",
  visibility: "hidden",
  display: "none",
};

const ChatPage = (props) => {
  const { width } = useWindowSize();
  const {
    activeChat,
    chatList,
    chatListLoading,
    chatMessages,
    chatMessageLoading,
    setActiveChat,
    setMessages,
    updateMessage,
  } = props;

  const chatListShow = width > 767 ? true : !activeChat;
  const chatMessagesShow = width > 767 ? true : !!activeChat;

  const chatListComponent = useMemo(
    () => (
      <ChatList
        chatList={chatList}
        chatListLoading={chatListLoading}
        setActiveChat={setActiveChat}
      />
    ),
    [chatList, chatListLoading, setActiveChat],
  );
  const chatMessagesComponent = useMemo(
    () => (
      <ChatMessages
        activeChat={activeChat}
        chatMessages={chatMessages}
        chatMessageLoading={chatMessageLoading}
        setMessages={setMessages}
        updateMessage={updateMessage}
      />
    ),
    [activeChat, chatMessages, chatMessageLoading, setMessages, updateMessage],
  );

  return (
    <Layout>
      <Content className={classes.container}>
        <Row wrap className={classes.row} gutter={16}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 9 }}
            lg={{ span: 8 }}
            xl={{ span: 7 }}
            xxl={{ span: 6 }}
            style={!chatListShow ? style : undefined}
          >
            <div className={classes["chat-list-container"]}>
              {chatListComponent}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 15 }}
            lg={{ span: 16 }}
            xl={{ span: 17 }}
            xxl={{ span: 18 }}
            style={!chatMessagesShow ? style : undefined}
          >
            <div className={classes["chat-messages-container"]}>
              {chatMessagesComponent}
            </div>
          </Col>
        </Row>
      </Content>
      <AntDFooter className={classes.footer}>
        <Footer />
      </AntDFooter>
    </Layout>
  );
};

export default ChatPage;
