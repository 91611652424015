import { DownOutlined } from "@ant-design/icons";
import { Col, Dropdown, Empty, Image, Menu, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetAllCategoryByDepartmentId } from "../../../services/GeneralService";
import {
  setCategoryId,
  setDepartmentId,
} from "../../../store/slice/flyout_slice";
import { getDepartments } from "../../../store/slice/generalData_slice";
import departmentIcon from "./../../../images/Navbar/Navbar2/departmentIcon-light.png";
import classes from "./DepartmentFlyout.module.css";

const DepartmentFlyout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [, setSelectedCategory] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownStyle, setDropdownStyle] = useState({});
  const windowWidth = window.innerWidth;

  const departmentFromState = useSelector(
    (state) => state.generalData.departments,
  );

  useEffect(() => {
    // let timer = setTimeout(() => {
    const fetchDepartments = async () => {
      if (!departmentFromState.length) {
        await dispatch(getDepartments({}));
      } else {
        setDepartments(departmentFromState);
      }
    };
    fetchDepartments();
    // }, 1000);
    // return () => clearTimeout(timer);
  }, [departmentFromState, dispatch]);

  //get department end
  const handleMouseEnter = async (e) => {
    const departmentId = e.key;
    setSelectedDepartment(departmentId);
    if (categories.length === 0 || selectedDepartment !== departmentId) {
      try {
        const res = await GetAllCategoryByDepartmentId({ departmentId: e.key });
        if (res?.data && Array.isArray(res.data)) {
          const response = res.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setCategories(response);
        } else {
          setCategories([]);
        }
      } catch (error) {
        setCategories([]);
      }
    }
  };

  const categoryClickHandler = (e) => {
    setSelectedCategory(e.key);
    dispatch(setDepartmentId(Number(selectedDepartment)));
    dispatch(setCategoryId(Number(e.key)));

    navigate(`/app/users`);
  };

  const handleDropdownOpenChange = (open) => {
    setDropdownOpen(open);
  };

  useEffect(() => {
    if (windowWidth < 768 && dropdownOpen) {
      setDropdownStyle({
        marginTop: "10px",
      });
    } else {
      setDropdownStyle({});
    }
  }, [dropdownOpen, windowWidth]);

  const fontSize = windowWidth < 768 ? "10px" : "inherit";

  const menu = (
    <Row
      className={classes["department-hover-layout"] + " flyout-dropdown"}
      justify={"space-around"}
      style={dropdownStyle}
    >
      <Col
        span={12}
        style={{
          maxHeight: windowWidth < 768 ? "500px" : "auto",
          overflowY: "auto",
        }}
      >
        <Menu className={classes["menu-left"]}>
          <h2 className={classes["mt-0"]}>Departments</h2>
          {departments?.length ? (
            departments.map((item) => (
              // <SubMenu
              //   key={item.id}
              //   title={item.name}
              //   onMouseEnter={handleMouseEnter}
              //   style={{
              //     cursor: "pointer",
              //     width: "100%",
              //   }}
              // />
              <Menu.Item
                key={item.id}
                title={item.name}
                style={{ colorBgTextHover: "#EBF6FF", fontSize: fontSize }}
                onMouseEnter={handleMouseEnter}
                onClick={(e) => e.preventDefault()}
              >
                {item.name}
              </Menu.Item>
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Menu>
      </Col>
      <Col
        span={12}
        style={{
          maxHeight: windowWidth < 768 ? "500px" : "auto",
          overflowY: "auto",
        }}
      >
        <Menu multiple className={classes["menu-right"]}>
          <h2 className={classes["mt-0"]}>Category</h2>
          {categories?.length ? (
            categories.map((item) => (
              <Menu.Item
                key={item.value}
                title={item.label}
                style={{
                  colorBgTextHover: "#EBF6FF",
                  fontSize: fontSize,
                }}
                onClick={categoryClickHandler}
              >
                {item.label}
              </Menu.Item>
            ))
          ) : (
            <Empty
              style={{ fontSize: fontSize }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                !selectedDepartment
                  ? "Please Hover On Department For Categories"
                  : "No Categories Of Selected Department"
              }
            />
          )}
        </Menu>
      </Col>
      <div className={classes.note}>
        *Hovering over Dept./Category will expand sub-menus, clicking over them
        will redirect you to results.
      </div>
    </Row>
  );

  const handleMouseLeave = () => {
    setSelectedDepartment(null);
    setCategories([]);
  };

  return (
    <div className={classes.divDepartment} onMouseLeave={handleMouseLeave}>
      <Dropdown
        onOpenChange={handleDropdownOpenChange}
        items={[]}
        placement="bottom"
        dropdownRender={() => menu}
      >
        <div className={classes.dptBtn}>
          <Image src={departmentIcon} alt="department-icon" preview={false} />
          <span>Departments</span>
          <DownOutlined />
        </div>
      </Dropdown>
    </div>
  );
};

export default DepartmentFlyout;
