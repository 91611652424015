import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import APIConstant from "../../constant/APIConstant";

// Async thunk action creator
export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (_, { rejectWithValue }) => {
    // Add 'rejectWithValue' as the second argument

    try {
      //create url for java url
      const authToken = localStorage.getItem("token");
      if (!authToken) {
        throw new Error("User not logged in");
      }

      const BASE_URL = process.env.REACT_APP_JAVA_APP_API_PATH;
      const url = BASE_URL + APIConstant.USER_BASE_URL + "/";
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          // "access-token": authToken,
          Authorization: `Bearer ${authToken}`,
        },
      });
      // Return the percentage value
      return response.data;
    } catch (error) {
      // If an error occurs, reject the thunk with the error value
      return rejectWithValue(error.response.data);
    }
  },
);

// Create the slice
const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {
      isVerified: false,
    },
  },
  reducers: {
    clearUser(state) {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.data = data;
    });
  },
});

// Export the async thunk and the reducer
export const { clearUser } = userSlice.actions;
export default userSlice.reducer;
