import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    subscription: 1,
    isAuthenticated: false,
    token: null,
    id: null,
  },
  reducers: {
    login(state, action) {
      state.subscription = action.payload.subscription;
      state.isAuthenticated = true;
      state.id = action.payload.id;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.token = null;
      state.id = null;
      state.subscription = null;
      localStorage.clear();
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    loadTokenFromStorage(state) {
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("id");
      const subscription = parseInt(localStorage.getItem("subscription"), 10);

      if (token) {
        state.isAuthenticated = true;
        state.token = token;
        state.id = id;
        state.subscription = subscription;
      } else {
        authSlice.caseReducers.logout(state);
      }
    },
  },
});

export const { login, logout, setToken, loadTokenFromStorage } =
  authSlice.actions;
export default authSlice;
