import {
  CloseCircleOutlined,
  DownCircleOutlined,
  LeftOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import { Avatar, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  removeWindow,
  setActiveChat,
  toggleWindow,
} from "../../../store/slice/chat_slice";
import classes from "./Header.module.css";
import { getChatListTime } from "../../../utils/chat.helper";

const { Title, Text } = Typography;

const ChatMessageHeader = ({
  windowId,
  lastMsgTime,
  anotherUser,
  floating,
  iconSize,
}) => {
  const dispatch = useDispatch();
  const chats = useSelector((state) => state.chat.floatingWindows);
  const w = chats?.find((c) => c.id === windowId);
  const IconCom = w?.open ? DownCircleOutlined : UpCircleOutlined;
  const date = lastMsgTime
    ? "Last talked on " + getChatListTime(lastMsgTime?.seconds)
    : null;

  return (
    <div className={classes["chat-message-header"]} key={windowId}>
      {!floating && (
        <LeftOutlined
          className={classes["back-icon"]}
          onClick={() => dispatch(setActiveChat(null))}
        />
      )}
      <Avatar
        size={iconSize || { xs: 42, sm: 42, md: 64, lg: 64, xl: 64, xxl: 64 }}
        src={anotherUser?.avatar}
        style={
          floating
            ? { minWidth: iconSize + "px", minHeight: iconSize + "px" }
            : {}
        }
        className={!floating && classes["chat-message-user-img"]}
      >
        {anotherUser?.name?.[0] || "U"}
      </Avatar>
      <div className={classes["chat-message-info"]}>
        <div className="d-flex justify-content-between">
          <Title
            level={5}
            className={classes["chat-message-info-name"]}
            ellipsis={{ tooltip: anotherUser?.name || "User" }}
            style={
              floating ? { width: `calc(284px - ${iconSize + 74}px)` } : {}
            }
          >
            {anotherUser?.name || "User"}
          </Title>
          {floating ? (
            <div>
              <IconCom
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={classes["icon"]}
                onClick={() => dispatch(toggleWindow(windowId))}
              />
              <CloseCircleOutlined
                className={classes["icon"]}
                onClick={() => dispatch(removeWindow(windowId))}
              />
            </div>
          ) : undefined}
        </div>
        <Text
          className={classes["chat-message-info-date"]}
          ellipsis={{ tooltip: date }}
          style={floating ? { width: `calc(284px - ${iconSize + 14}px)` } : {}}
        >
          {date}
        </Text>
      </div>
    </div>
  );
};

export default ChatMessageHeader;
