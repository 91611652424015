import React, { useState } from "react";
import classes from "./ChatList.module.css";
import { Typography } from "antd";
import ChatList from "./ListComponents/List";
import SearchInput from "./ListComponents/SearchInput";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const ChatListSection = (props) => {
  const { chatList, chatListLoading, setActiveChat } = props;
  const [list, setList] = useState(chatList);

  return (
    <>
      {!window?.opener ? (
        <Link to={"/app/dashboard"}>
          <ArrowLeftOutlined /> Back to Dashboard
        </Link>
      ) : null}
      <Title level={2} className={classes.heading}>
        Messaging {chatList?.length > 0 ? `(${chatList?.length})` : null}
      </Title>
      <SearchInput chatList={chatList} setList={setList} />
      <div className={classes["chat-list-section"]}>
        <ChatList
          chatList={list}
          loading={chatListLoading}
          onSelect={(item) => setActiveChat(item)}
        />
      </div>
    </>
  );
};

export default ChatListSection;
