import { useSelector } from "react-redux";
import LandingPageCmp from "../components/LandingPage/LandingPageCmp";
import LandingPageCmpUser from "../components/LandingPage/LandingPageCmpUser";

const LandingPage = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return <>{!isAuthenticated ? <LandingPageCmp /> : <LandingPageCmpUser />}</>;
};

export default LandingPage;
