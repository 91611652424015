import { Route, Routes } from "react-router-dom";
import ChatsPage from "../pages/ChatsPage";
import CreateProfilePage from "../pages/CreateProfilePage";
import ModelPage from "../pages/ModelPage";
import MyProductsPage from "../pages/MyProductsPage";
import MyProfilePage from "../pages/MyProfilePage";
import SettingsPage from "../pages/SettingPage";
import MainLayout from "../components/Layout/Layout";
import DashboardPage from "../pages/DashboardPage";
import SupplierDetailsPage from "../pages/SupplierDetailsPage";
import GlobalUsersPage from "../pages/GlobalUsersPage";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/users" element={<GlobalUsersPage />} />

        <Route path="/model" element={<ModelPage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/products" element={<MyProductsPage />} />
        <Route path="/createprofile" element={<CreateProfilePage />} />

        <Route path="/myprofile" element={<MyProfilePage />} />
        <Route path="/supplier/:supplierId" element={<SupplierDetailsPage />} />
      </Route>
      <Route path="/chat" element={<ChatsPage />} />
    </Routes>
  );
};

export default PrivateRoutes;
