

import { Button, Modal,Typography } from "antd";
import { useState } from "react";
import { toast } from "react-toastify";
import { deleteAccount } from "../../../services/AuthService";
import classes from "./ManageAccount.module.css";
import { logout } from "../../../store/slice/auth_slice";
import { clearUser } from "../../../store/slice/user_slice";
import {useSelector ,useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
const { Title, Paragraph } = Typography;
const ManageAccount = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const userObj = useSelector((state) => state.user.data);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    
    const deleteAccountHandler = async () => {
        try {
        const res = await deleteAccount({userId:userObj?.id});
        if (res && res.success === true) {
            toast.success(res.message);
            //logout user
          
            dispatch(logout());
            dispatch(clearUser());
            navigate("/public/signin");
        } else {
            toast.error(res.message);
        }
        } catch (err) {
        toast.error(err.message);
        } finally {
        setModalVisible(false);
        }
    };
    
    return (
        <div className={classes["manage-account-container"]}>
            <Title level={3} className={classes["page-title"]}>
                Delete Your Account
            </Title>
            <Paragraph className={classes["intro-text"]}>
                We&apos;re sorry to see you go! Deleting your account is a permanent action and cannot be undone. All your data, including your preferences and saved information, will be lost. If you&apos;re sure you want to delete your account, please confirm below.
            </Paragraph>

            <Button
                type="primary"
                danger
                onClick={() => setModalVisible(true)}
                className={classes["delete-account-btn"]}
            >
                Delete Account
            </Button>

            <Modal
                title="Confirm Account Deletion"
                visible={modalVisible}
                onOk={deleteAccountHandler}
                onCancel={() => setModalVisible(false)}
                okText="Yes, Delete"
                cancelText="Cancel"
                className={classes["modal-styling"]}
            >
                <p>
                    Are you sure you want to delete your account? This action is permanent and cannot be undone.
                </p>
                <p className={classes["warning-text"]}>
                    You will lose access to all your data, settings, and saved information.
                </p>
            </Modal>
        </div>
    );
    };

export default ManageAccount;