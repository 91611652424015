// slices/percentageSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import APIConstant from "../../constant/APIConstant";

// Async thunk action creator
export const fetchPercentage = createAsyncThunk(
  "percentage/fetchPercentage",
  async (_, { rejectWithValue }) => {
    // Add 'rejectWithValue' as the second argument

    try {
      //create url for java url
      const authToken = localStorage.getItem("token");
      const userId = localStorage.getItem("id");
      if (!userId) {
        throw new Error("User not found");
      }
      const BASE_URL = process.env.REACT_APP_JAVA_APP_API_PATH;
      const url =
        BASE_URL +
        APIConstant.USER_BASE_URL +
        APIConstant.UPDATE_PROFILE +
        APIConstant.GET_PROFILE_PERCENTAGE +
        "/" +
        userId;

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          // "access-token": authToken,
          Authorization: `Bearer ${authToken}`,
        },
      });

      // Return the percentage value
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Create the slice
const percentageSlice = createSlice({
  name: "percentage",
  initialState: {
    value: 0,
    stageCompleted: {
      CompanyDetails: false,
      ProfileArtwork: false,
      ProfileCategoryAndProduct: false,
      ProfileMember: false,
      ProfileQA: false,
    },
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPercentage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPercentage.fulfilled, (state, action) => {
        const { ...rest } = action.payload;
        state.isLoading = false;
        state.value = action.payload.data;
        state.stageCompleted = rest;
      })
      .addCase(fetchPercentage.rejected, (state, action) => {
        state.value = 0;
        state.stageCompleted = {
          CompanyDetails: false,
          ProfileArtwork: false,
          ProfileCategoryAndProduct: false,
          ProfileMember: false,
          ProfileQA: false,
        };
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

// Export the async thunk and the reducer

export default percentageSlice.reducer;
