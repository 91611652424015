import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  departmentId: null,
  categoryId: null,
};

const flyoutSlice = createSlice({
  name: "flyout",
  initialState,
  reducers: {
    setDepartmentId: (state, action) => {
      state.departmentId = action.payload;
    },
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
    clearSelection: (state) => {
      state.departmentId = null;
      state.categoryId = null;
    },
  },
});

export const { setDepartmentId, setCategoryId, clearSelection } =
  flyoutSlice.actions;

export default flyoutSlice.reducer;
