import React, { useState } from "react";
import classes from "./Dashboard.module.css";
import { Col, Row } from "antd";
import Suppliers from "./Suppliers";
import "./Dashboard.css";
import SubscriptionModal from "../SubscriptionModal/SubscriptionModal";
import ConfirmSubscription from "../SubscriptionModal/ConfirmSubscription";

const Dashboard = () => {
  const [selectPlan, setSelectPlan] = useState(null);
  const [planListModalOpen, setPlanListModalOpen] = useState(false);
  const [confirmSubModalOpen, setConfirmSubModalOpen] = useState(false);

  return (
    <section className={classes["container"] + " dashboard"}>
      {/* <VerticalCarousel />
      {percentage === "100" || percentage === 0 ? null : (
        <ProfileCompleteNotice />
      )} */}
      <Row gutter={{ xl: 8, lg: 0 }}>
        <Col lg={24} xl={24}>
          <Suppliers />
        </Col>
        {/* <Col lg={24} xl={8}>
          <Favorites />
        </Col> */}
      </Row>
      <SubscriptionModal
        isModalOpen={planListModalOpen}
        modalClose={() => {
          setPlanListModalOpen(false);
        }}
        selectPlan={(plan) => {
          setSelectPlan(plan);
          setPlanListModalOpen(false);
          setConfirmSubModalOpen(true);
        }}
      />
      <ConfirmSubscription
        modalClose={() => {
          setConfirmSubModalOpen(false);
        }}
        selectedPlan={selectPlan}
        isModalOpen={confirmSubModalOpen}
      />
    </section>
  );
};

export default Dashboard;
