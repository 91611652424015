import { useSelector } from "react-redux";
import CreateProfileRetailer from "../components/CreateProfile/Retailer/CreateProfileRetailer";
import CreateProfile from "../components/CreateProfile/Supplier/CreateProfile";

const CreateProfilePage = () => {
  //get below variable value based on user login
  let isSupplier = 1;

  //const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  //const auth = useSelector((state) => state.auth);
  const userObj = useSelector((state) => state.user.data);
  if (userObj.user_type == "SUPPLIER") {
    isSupplier = 1;
  } else {
    isSupplier = 2;
  }

  return isSupplier == 1 ? <CreateProfile /> : <CreateProfileRetailer />;
};

export default CreateProfilePage;
