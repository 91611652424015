import { useSelector } from "react-redux";
import classes from "../ProfileInfoTabs.module.css";
import { Avatar, Button, Col, Empty, Row, Skeleton } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { getMemberInformation } from "../../../services/MyProfileService";
import { useNavigate } from "react-router-dom";

const type = {
  address: "Address",
  country: "Location",
  departmentId: "Department",
  email: "Email",
  member_timezone_id: "Timezone",
  phone: "Phone",
  title: "Title",
  website: "Website",
  isBroker: "Is Broker",
  name: "Name",
};

const isBroker = {
  [true]: "Yes",
  [false]: "No",
};

const MemberInfoData = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const generalData = useSelector((state) => state.generalData);

  const [loading, setLoading] = useState(false);
  const [infoData, setInfoData] = useState([]);
  const [logoUrl, setLogoUrl] = useState("");

  async function fetchData() {
    try {
      setLoading(true);
      const res = await getMemberInformation({ userId: auth.id });
      if (res?.data) {
        const data = res.data;

        const infoData = Object.entries(data)
          .filter(([name]) => name in type) // Filter to only include keys present in `type`
          .flatMap(([name, value]) => {
            let mappedName = type[name]; // Map the name using the `type` object

            // Handle special cases
            if (name === "departmentId") {
              value = generalData?.departments?.find(
                (obj) => obj.id === value,
              )?.name;
            } else if (name === "member_timezone_id") {
              value = generalData?.timezone?.find(
                (obj) => obj.id === value,
              )?.timezone;
            } else if (name === "isBroker") {
              value = isBroker[value];
            } else if (name === "profilePath") {
              setLogoUrl(value);
              return []; // Skip this element
            }

            // Only include the item if the value exists
            if (value) {
              return {
                name: mappedName,
                data: value,
              };
            } else {
              return [];
            }
          });

        setInfoData(infoData);
      }
    } catch (err) {
      console.error("Error fetching member information:", err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const infoCmp = infoData.map((item, index) => (
    <Col
      xs={24}
      md={24}
      lg={12}
      xl={12}
      xxl={12}
      className={classes["data-item"]}
      key={`list-item-${item.name}-${index}`}
    >
      <Title level={5} className={classes.itemHeading}>
        {item.name}
      </Title>
      <p className={classes.itemData}>{item.data}</p>
    </Col>
  ));

  return loading ? (
    <>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
    </>
  ) : infoData.length !== 0 ? (
    <>
      {logoUrl !== `${process.env.REACT_APP_S3_URL}/profilePhoto/null` && (
        <Avatar src={logoUrl} className={classes.avatar} />
      )}
      <Row gutter={12} className={classes["data-container"]}>
        {infoCmp}
      </Row>
    </>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="Your profile is incomplete. Click below button to complete your profile."
    >
      <Button type="primary" onClick={() => navigate("/app/createprofile")}>
        Complete Now
      </Button>
    </Empty>
  );
};

export default MemberInfoData;
