import React from "react";
import classes from "./RegisterFlowLayout.module.css";
import { Col, Row } from "antd";
import Title from "antd/es/typography/Title";

const RegisterFlowLayout = ({ title, children }) => {
  return (
    <section style={{ backgroundColor: "white" }}>
      <Row>
        <Col
          xl={{ order: 1, span: 24 }}
          lg={{ order: 1, span: 24 }}
          md={{ order: 2, span: 24 }}
          sm={{ order: 2, span: 24 }}
          xs={{ order: 2, span: 24 }}
          className={classes["register-section"]}
        >
          {title ? <Title>{title}</Title> : null}
          {children}
        </Col>
      </Row>
    </section>
  );
};

export default RegisterFlowLayout;
