import { Col, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import "./TermsAndConditions.css";
import { getStaticPage } from "../../services/GeneralService";

const TermsAndConditionsPage = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getStaticPage({ searchKeyword: "" });

      const targetObject = res.data.content.find(
        (obj) => obj.pageName === "Terms and conditions",
      );
      if (targetObject) {
        setData(targetObject?.content);
        setLoading(false);
      } else {
        setData("");
        setLoading(false);
      }
    } catch (error) {
      setData("");
      setLoading(false);
    }
  };
  const SkellotonCtrl = (
    <>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
    </>
  );

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Row justify="center">
        <Col xs={22} md={22} lg={22} xl={22} xxl={22}>
          {!loading ? (
            <div
              className="terms-and-conditions"
              dangerouslySetInnerHTML={{ __html: data }}
            />
          ) : (
            SkellotonCtrl
          )}
        </Col>
      </Row>
    </>
  );
};

export default TermsAndConditionsPage;
