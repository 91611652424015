import React from "react";
import { Link } from "react-router-dom";
import classes from "./Footer2.module.css";
//import fb from "./../../images/Footer/Facebook.png";
//import google from "./../../images/Footer/Google.png";
import instagram from "./../../images/Footer/Instagram.png";
import mail from "./../../images/Footer/Mail.png";
import whatsapp from "./../../images/Footer/WhatsApp.png";
import twitter from "./../../images/Footer/twitter_x.png";
import linkedin from "./../../images/Footer/linkedin.png";
import tiktok from "./../../images/Footer/tiktok.png";

const socialIcons = [
  // { icon: fb, name: "fb-logo", link: "#" },
  // { icon: google, name: "google-logo", link: "#" },
  { icon: mail, name: "mail-logo", link: "#" },
  { icon: whatsapp, name: "whatsapp-logo", link: "#" },
  {
    icon: twitter,
    name: "twitter-logo",
    link: "https://twitter.com/PRIBR_LLC",
  },
  {
    icon: linkedin,
    name: "linkedin-logo",
    link: "https://www.linkedin.com/company/pribr",
  },
  {
    icon: tiktok,
    name: "tiktok-logo",
    link: "https://www.tiktok.com/@pribr.llc ",
  },
  {
    icon: instagram,
    name: "instagram-logo",
    link: "https://www.instagram.com/pribr.llc/ ",
  },
];

const Footer2 = () => {
  return (
    <div className={classes.container}>
      <p className={classes.copyrightText}>
        Copyright © {new Date().getFullYear()} PriBr
      </p>
      <div className={classes.icons}>
        {socialIcons.map((icons, index) => (
          <Link to={icons.link} key={`social-icons-${icons.name}-${index}`}>
            <img
              className={classes["social-icon"]}
              src={icons.icon}
              alt={icons.name}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default React.memo(Footer2);
