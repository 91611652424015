import APIConstant from "../constant/APIConstant";
import Request from "./UtilsService";

export async function addProduct(data) {
  return await Request({
    url: APIConstant.PRODUCT_BASE_URL + APIConstant.ADD_PRODUCT,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function getAllProducts(data) {
  return await Request({
    url:
      APIConstant.PRODUCT_BASE_URL +
      APIConstant.GET_ALL_PRODUCT_OF_USER +
      "?offset=" +
      data.offset +
      "&pageNumber=" +
      data.pageNumber +
      "&pageSize=" +
      data.pageSize +
      "&userId=" +
      data.userId,
    method: "GET",
    //body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteProduct(data) {
  return await Request({
    url:
      APIConstant.PRODUCT_BASE_URL + APIConstant.DELETE_PRODUCT + "/" + data.id,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteProductFile(data) {
  return await Request({
    url: APIConstant.PRODUCT_BASE_URL + APIConstant.DELETE_FILE,
    method: "DELETE",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
