import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Layout, Row } from "antd";
import classes from "./Aboutus.module.css";

import p1image from "../../images/Aboutus/p1.png";
import p2image from "../../images/Aboutus/p2.png";
import fb from "../../images/SocialIcons/Facebook.png";
import google from "../../images/SocialIcons/Google.png";
import instagram from "../../images/SocialIcons/Instagram.png";
import mail from "../../images/SocialIcons/Mail.png";
import overlayWhoWeAre from "../../images/LandingPage/overlayWhoWeAre.png";

const AboutUs = () => {
  const [countRetailers] = useState(12000);
  const [countSuppliers] = useState(28000);
  const [countProducts] = useState(15000);

  const contentWhoWeAre = (
    <>
      We are a B2B portal that connects businesses across industries and
      geographies. Our platform provides a comprehensive suite of services,
      including networking, marketing, sales, and procurement solutions. Our
      team comprises experienced professionals with diverse backgrounds in
      technology, marketing, sales, and business development. We are passionate
      about empowering businesses to succeed in the digital age and are
      committed to delivering exceptional value to our clients. Our core values
      of honesty, integrity, innovation, and teamwork guide everything we do.
    </>
  );

  const contentOurValues = (
    <>
      At PriBr, we strive to uphold a set of core values that guide our actions
      and decisions. We believe in honesty, integrity, and transparency in all
      our dealings. We value innovation, creativity, and continuous learning. We
      respect diversity, inclusivity, and teamwork. We are committed to
      providing exceptional service and building long-term relationships with
      our clients.
    </>
  );

  const content1OurApproch = (
    <>
      We take a comprehensive and personalized approach to serving our clients
      on our B2B portal. Our team works closely with clients to understand their
      unique needs and goals, and tailor our solutions accordingly. We leverage
      our industry expertise, technological capabilities, and network of
      partners to deliver results. We are committed to transparency,
      accountability, and continuous improvement in our approach.
    </>
  );

  const approchListContent = (
    <>
      <li>
        To provide a seamless and efficient B2B platform for businesses to
        connect, collaborate, and grow.
      </li>
      <li>
        To empower businesses by providing them with the tools, resources, and
        opportunities they need to succeed in today&apos;s rapidly changing
        market.
      </li>
      <li>
        To foster a vibrant community of businesses that share our commitment to
        excellence, innovation, and sustainability.
      </li>
    </>
  );

  return (
    <Layout style={{ backgroundColor: "white" }}>
      <Row>
        <Col xs={19} sm={19} md={19} lg={19} offset={2}>
          <h2 className={classes.heading11}>About Us</h2>
        </Col>
      </Row>

      <Row>
        <Col xs={19} sm={19} md={19} lg={19} offset={2}>
          <img
            className={classes.img1}
            src={p1image}
            style={{ width: "100%", height: "auto" }}
            alt=""
          />
        </Col>
      </Row>

      <Row>
        <Col xs={19} sm={19} md={19} lg={19} offset={2}>
          <Row>
            <Col xs={4} sm={4} md={4} lg={4} offset={6}>
              <h3 className={classes.headingRetailer}>{countRetailers}</h3>
              <hr className={classes.vertical1} />
              <p className={classes.contentRetailer}>Retailers</p>
            </Col>

            <Col xs={4} sm={4} md={4} lg={4}>
              <h3 className={classes.headingSupplier}>{countSuppliers}</h3>
              <hr className={classes.vertical1} />
              <p className={classes.contentSupplier}>Suppliers</p>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4}>
              <h3 className={classes.headingProducts}>{countProducts}</h3>
              <p className={classes.contentProducts}>
                Products being discovered
              </p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: "3%" }}>
        <Col xs={19} sm={19} md={19} lg={19} offset={2}>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ position: "relative" }}
            >
              <img
                src={p2image}
                className={classes.img2}
                style={{ width: "100%", height: "90%" }}
                alt=""
              />
              <div className={classes.whoWeAreOverlay}>
                <Row>
                  <Col xs={9} sm={9} md={9} lg={9}>
                    <img
                      src={overlayWhoWeAre}
                      className={classes.imgoverlayWhoWeAre}
                      alt=""
                    />
                  </Col>
                  <Col
                    xs={15}
                    sm={15}
                    md={15}
                    lg={15}
                    style={{ marginTop: "11%" }}
                  >
                    <Row>
                      <p className={classes.overlayWhoWeAreText}>
                        {countSuppliers}+ Suppliers
                      </p>
                    </Row>
                    <Row>
                      <p className={classes.overlayWhoWeAreText}>
                        {countRetailers}+ Retailers
                      </p>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={10} sm={10} md={10} lg={10} offset={2}>
              <h3 className={classes.headingWhoWeAre}>Who We Are</h3>
              <p className={classes.contentWhoWeAre}>{contentWhoWeAre}</p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: "3%" }}>
        <Col xs={19} sm={19} md={19} lg={19} offset={2}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div>
                <h3 className={classes.headingOurValue}>Our Values</h3>
                <p className={classes.contentOurValue}>{contentOurValues}</p>
              </div>

              <div>
                <h3 className={classes.headingOurApproach}>Our Approach</h3>
                <p className={classes.contentOurApproach}>
                  {content1OurApproch}
                </p>
                <ul className={classes.listOurApproach}>
                  {approchListContent}
                </ul>
              </div>
            </Col>

            <Col xs={8} sm={8} md={8} lg={8} offset={4}>
              <Row>
                <Col xs={22} sm={22} md={22} lg={22} offset={2}>
                  <h3 className={classes.headingSociallist}>
                    Find us on Social Media
                  </h3>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <ul className={classes.sociallist}>
                    <li>
                      <Link to="#" className={classes.socialLink}>
                        <img
                          className={classes.social}
                          src={instagram}
                          alt=""
                        />
                        <span className={classes.socialLinkspan}>
                          pribr_llc
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className={classes.socialLink}>
                        <img className={classes.social} src={fb} alt="fb" />
                        <span className={classes.socialLinkspan}>
                          pribr_llc
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className={classes.socialLink}>
                        <img
                          className={classes.social}
                          src={google}
                          alt="google"
                        />
                        <span className={classes.socialLinkspan}>
                          PriBr LLC, USA
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className={classes.socialLink}>
                        <img className={classes.social} src={mail} alt="mail" />
                        <span className={classes.socialLinkspan}>
                          business@pribr.com
                        </span>
                      </Link>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default AboutUs;
