import "./MainCarasoul.css";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography } from "antd";
import { getBannerByLocation } from "../../../services/LandingPageService";
const { Title } = Typography;

const MainCarasoul = () => {
  const [images, setImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);

  //API Integration start
  const getCarasoulData = async () => {
    try {
      let bannerDataObj = { location: "LOGGED_IN_LANDING_CAROUSEL" };
      const res = await getBannerByLocation(bannerDataObj);

      if (res && res.data.content) {
        setImages(res.data.content);
      } else {
        setImages([]);
      }
    } catch (error) {
      setImages([]);
    }
  };

  useEffect(() => {
    getCarasoulData();
  }, []);

  //API Integration end

  const settings = {
    infinite: true,
    lazyLoad: true,
    adaptiveHeight: true,
    variableWidth: false,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => setImageIndex(next),
  };

  return (
    <div className="carasoul-container">
      <Slider
        {...settings}
        style={{ height: "100%" }}
        className="main-carasoul"
      >
        {images.map((item, idx) => (
          <div
            className={idx === imageIndex ? "slide activeSlide" : "slide"}
            key={`images-${item.title}-${idx}`}
          >
            <img src={item.bannerPath} alt={item.title} />
            <Title level={4} className={"title11"}>
              {item.title}
            </Title>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MainCarasoul;
