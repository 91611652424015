import { Button, Col, Input, Modal, Row, Spin } from "antd";
import React, { useState } from "react";
import { subscribeNewsletter } from "../../services/GeneralService";
import EmpowerBusiness from "./../../images/LandingPageCMP/EmpowerBusiness.png";
import classes from "./JoinNowbadge.module.css";

const JoinNowBadge = () => {
  // const navigate = useNavigate();
  // const [modalVisible, setModalVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  //const empowerBusinessContent = "";

  const handleSubscribe = async () => {
    setLoading(true);
    try {
      const response = await subscribeNewsletter({ email });
      if (response.success) {
        setSubscriptionStatus("success");
        // setTimeout(() => {
        //   setModalVisible(false);
        // }, 2000);
      } else {
        setSubscriptionStatus("error");
      }
    } catch (error) {
      setSubscriptionStatus("error");
      console.error("Subscription error:", error);
    } finally {
      setLoading(false);
      setEmail("");
    }
  };

  return (
    <Row className={classes.outerDiv}>
      <Col xs={24} sm={24} md={24} lg={24} className={classes.maindiv}>
        <div className="d-flex align-content-center justify-content-center">
          <img src={EmpowerBusiness} className={classes.EmpowerBusinessImg} />

          <div className={classes.EmpowerBusinessDiv}>
            <h4 className={classes.heading}>Subscribe to Our Newsletter</h4>
            {/* <p className={classes.content}>{empowerBusinessContent}</p> */}{" "}
            <div className={classes.inputBox}>
              <Input
                className={classes.inputBox2}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your email"
              />
              <Button
                key="submit"
                type="primary"
                onClick={handleSubscribe}
                disabled={email === "" || loading}
              >
                {loading ? <Spin size="small" /> : "Subscribe"}
              </Button>
            </div>
          </div>
        </div>
      </Col>
      <Modal
        title={subscriptionStatus === "success" ? "Success!" : "Error"}
        visible={subscriptionStatus !== null}
        onCancel={() => setSubscriptionStatus(null)}
        footer={[
          <Button key="ok" onClick={() => setSubscriptionStatus(null)}>
            OK
          </Button>,
        ]}
      >
        <p>
          {subscriptionStatus === "success"
            ? "Successfully subscribed to the newsletter!"
            : "You are already subscribed to the newsletter."}
        </p>
      </Modal>
    </Row>
  );
};

export default JoinNowBadge;
