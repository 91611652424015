import { getTime } from "../../../utils/chat.helper";
import classes from "./Item.module.css";

const ChatMessageItem = ({ id, item }) => {
  const time = getTime(item?.createdAt?.seconds);
  const sender = id === item?.sender;
  return (
    <div
      className={
        classes["chat-message-container"] +
        " " +
        (sender ? classes["sender"] : classes["receiver"])
      }
    >
      <div
        className={
          classes["chat-message-item"] +
          " " +
          (sender ? classes["sender-item"] : classes["receiver-item"])
        }
      >
        {item?.messageType === "text" ? (
          item?.text
        ) : item?.messageType === "image" ? (
          <img src={item?.mediaUrl} alt="alt-text" />
        ) : null}
      </div>
      {/* {sender ? (
        <p className={classes["read-container"]}>
          {item.isRead ? <CheckOutlined className={classes["message-read"]} /> : <CheckOutlined />}
        </p>
      ) : null} */}
      <p className={classes["chat-message-timer"]}>{time}</p>
    </div>
  );
};

export default ChatMessageItem;
