import React, { useMemo } from "react";
import { Button, Table, Typography } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import "./PlansandFeatures.module.css";
import classes from "./PlansandFeatures.module.css";
import { useNavigate } from "react-router-dom";

const PlansandFeatures = () => {
  const navigate = useNavigate();
  const subPLan= localStorage.getItem("subscription");
  const userToken = localStorage.getItem("token");
  const handleButtonClick = () => {
    if (userToken) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/public/signin");
    }
  };
  const companyInformation = useMemo(
    () => [
      // Section: Company Information
      {
        key: "1",
        info: (
          <Typography style={{ fontSize: "larger", fontWeight: "bold" }}>
            Company Information
          </Typography>
        ),
        free: "",
        standard: "",
        premium: "",
      },
      {
        key: "2",
        info: "Company Logo",
        free: <CheckOutlined className={classes["checked-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "3",
        info: "Company Name",
        free: <CheckOutlined className={classes["checked-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "4",
        info: "Company Location",
        free: <CheckOutlined className={classes["checked-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "5",
        info: "Company Phone",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "6",
        info: "Company Website",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "7",
        info: "CEO",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "8",
        info: "Number of Employees",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "9",
        info: "Category Name",
        free: <CheckOutlined className={classes["checked-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "10",
        info: "Company Address",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "11",
        info: "Company Email",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "12",
        info: "Company Type",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "13",
        info: "Annual Revenue",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "14",
        info: "Key Competitors",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "15",
        info: (
          <Typography style={{ fontSize: "larger", fontWeight: "bold" }}>
            Member Information
          </Typography>
        ),
        free: "",
        standard: "",
        premium: "",
      },
      {
        key: "16",
        info: "Headshot (personal profile photo)",
        free: <CheckOutlined className={classes["checked-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "17",
        info: "Name",
        free: <CheckOutlined className={classes["checked-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "18",
        info: "Title",
        free: <CheckOutlined className={classes["checked-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "19",
        info: "Are you a Broker?",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "20",
        info: "Location",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "21",
        info: "Phone",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "22",
        info: "Email",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "23",
        info: "Website",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "23",
        info: (
          <Typography style={{ fontSize: "larger", fontWeight: "bold" }}>
            Sourcing & Shipping Information
          </Typography>
        ),
        free: "",
        standard: "",
        premium: "",
      },
      {
        key: "25",
        info: "Min Order Quantity",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "26",
        info: "Print Pack Ship Time",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "27",
        info: "Pickup Options",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "28",
        info: "Pickup Points",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "29",
        info: "EDI Compliance",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "30",
        info: "Do you work with Allowance Programs",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "31",
        info: "Do you work with Dead Net Programs",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "32",
        info: "Certificate of Insurance",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "33",
        info: "Other Certificates",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "34",
        info: "FTP or Web Download Link for retailers",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "35",
        info: (
          <Typography style={{ fontSize: "larger", fontWeight: "bold" }}>
            Artwork Information
          </Typography>
        ),
        free: "",
        standard: "",
        premium: "",
      },
      {
        key: "36",
        info: "Upload Product Catalog",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "37",
        info: "Artwork and Label Design Capabilities",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "38",
        info: "Agency Name",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "39",
        info: "Artwork Requirements available",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "40",
        info: "Artwork requirements",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "41",
        info: "Printer Specs",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "42",
        info: "Dielines",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "43",
        info: "Primary Contact for Artwork (Name)",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "44",
        info: "Primary Contact for Artwork (Email)",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "45",
        info: (
          <Typography style={{ fontSize: "larger", fontWeight: "bold" }}>
            Quality Assurance
          </Typography>
        ),
        free: "",
        standard: "",
        premium: "",
      },
      {
        key: "46",
        info: "Primary Contact for QA (Name)",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "47",
        info: "Primary Contact for QA (Email)",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "48",
        info: "Which QA Lab do you work with?",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "49",
        info: "Sourcing Partners",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "50",
        info: (
          <Typography style={{ fontSize: "larger", fontWeight: "bold" }}>
            My Products
          </Typography>
        ),
        free: "",
        standard: "",
        premium: "",
      },
      {
        key: "51",
        info: "Product Images",
        free: <CheckOutlined className={classes["checked-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "52",
        info: "Product Name",
        free: <CheckOutlined className={classes["checked-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "53",
        info: "Product Description",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "54",
        info: "Category",
        free: <CheckOutlined className={classes["checked-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "55",
        info: "Case Pack Dimension",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "56",
        info: "Case Pack Weight",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "57",
        info: "Average Unit Size",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "58",
        info: "Average Unit Weight",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CloseOutlined className={classes["close-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
      {
        key: "59",
        info: "Min Order Quantity",
        free: <CloseOutlined className={classes["close-icon"]} />,
        standard: <CheckOutlined className={classes["checked-icon"]} />,
        premium: <CheckOutlined className={classes["checked-icon"]} />,
      },
    ],
    [classes],
  );

  const columns = useMemo(
    () => [
      {
        title: (
          <div>
            <Typography style={{ fontSize: "18px" }}>
              Features and Plans
            </Typography>
          </div>
        ),
        dataIndex: "info",
        key: "info",
      },
      {
        title: (
          <div>
            <Typography style={{ fontSize: "16px" }}>Free</Typography>
            <Typography style={{ fontSize: "14px" }}>$0 /mo</Typography>
            {subPLan === "1" ? (
              <Button className={classes["header-button"]}>Current Plan</Button>
            ) : (
              <Button
                className={classes["header-button"]}
                onClick={handleButtonClick}
              >
                Get Started
              </Button>
            )}
          </div>
        ),
        dataIndex: "free",
        key: "free",
        align: "left",
      },
      {
        title: (
          <div>
            <Typography style={{ fontSize: "16px" }}>Standard</Typography>
            <Typography style={{ fontSize: "14px" }}>$29 /mo</Typography>
            {subPLan === "2" ? (
              <Button className={classes["header-button"]}>Current Plan</Button>
            ) : (
              <Button
                className={classes["header-button"]}
                onClick={handleButtonClick}
              >
                Get Started
              </Button>
            )}
          </div>
        ),
        dataIndex: "standard",
        key: "standard",
        align: "left",
      },
      {
        title: (
          <div>
            <Typography style={{ fontSize: "16px" }}>Premium</Typography>
            <Typography style={{ fontSize: "14px" }}>$99 /mo</Typography>
            {subPLan === "3" ? (
              <Button className={classes["header-button"]}>Current Plan</Button>
            ) : (
              <Button
                className={classes["header-button"]}
                onClick={handleButtonClick}
              >
                Get Started
              </Button>
            )}
          </div>
        ),
        dataIndex: "premium",
        key: "premium",
        align: "left",
      },
    ],
    [classes, handleButtonClick, subPLan]
  );

  return (
    <div className={classes["main"]}>
      <h1
        style={{
          color: "white",
          fontSize: "24px",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Compare Plans & Features
      </h1>
      <div className={classes["table-container"]}>
        <Table
          columns={columns}
          dataSource={companyInformation}
          pagination={false}
          bordered
          className={classes["custom-table"]}
        />
      </div>
    </div>
  );
};

export default PlansandFeatures;