import APIConstant from "../constant/APIConstant";
import Request from "./UtilsService";

export async function SearchUser(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      // APIConstant.SEARCH_USER +
      APIConstant.SEARCH_USER,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function AddToMyNetwork(data) {
  return await Request({
    url: APIConstant.USER_BASE_URL + APIConstant.ADD_TO_MY_NETWORK,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
