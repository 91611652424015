import APIConstant from "../constant/APIConstant";
import Request from "./UtilsService";

export async function sendVerificationMail() {
  return await Request({
    url: APIConstant.AUTH_BASE_URL + APIConstant.SEND_VERIFICATION_EMAIL,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function verifyEmailOfuser(data) {
  return await Request({
    url:
      APIConstant.AUTH_BASE_URL +
      APIConstant.VERIFY_EMAIL +
      "?token=" +
      data.token,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
