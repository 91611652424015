import { Col, Row } from "antd";
import classes from "./VerticalCarasoul.module.css";

const VerticalCarasoul = () => {
  return (
    <div className={classes.bannerContainer}>
      <Row className={classes.overlay} justify="center" align="middle">
        <Col xs={24} md={12} className={classes.headingText}>
          <div
            style={{
              maxWidth: "450px",
              wordWrap: "break-word",
              textAlign: "center",
            }}
          >
            <h2>PRIVATE BRANDS SIMPLIFIED</h2>
          </div>
        </Col>
        <Col xs={24} md={12} className={classes.mainContent}>
          <div
            style={{
              maxWidth: "450px",
              wordWrap: "break-word",
            }}
          >
            <p>
              Simple and easy to use strategic sourcing for merchandising teams
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VerticalCarasoul;
