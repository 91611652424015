import { Button, Card, Col, Input, Modal, Row, Spin } from "antd";
import React, { useState } from "react";
import BannerCarasoul from "./Carasoul/BannerCarasoul";
import VerticalCarasoul from "./Carasoul/VerticalCarasoul";
import classes from "./LandingPageCmp.module.css";
//import whatisImg from "./../../images/LandingPageCMP/What_is_PriBr.png";
import howITWorksPribr from "./../../images/LandingPageCMP/howPribrLeads.png";
import mustHavesRetailer from "./../../images/LandingPageCMP/musthave_for_retailers.jpg";
import mustHaveVendors from "./../../images/LandingPageCMP/MustHave_for_Vendors.jpg";
//import arrow from "./../../images/LandingPageCMP/arrow.png";
import { subscribeNewsletter } from "../../services/GeneralService";
//import vect2 from "./../../images/LandingPageCMP/vect2.png";
//import whyPribr from "./../../images/LandingPageCMP/whyPribr.png";
//import whyChoosePribrImg from "./../../images/LandingPageCMP/whyChoosePribr.png";
//import howPribrLeads from "./../../images/LandingPageCMP/howPribrLeads.png";
import JoinNowBadge from "./JoinNowbadge";
//import TestimonialCarousel from "./Carasoul/TestimonialCarousel";
// import ProductsCarosoul from "./Carasoul/ProductsCarosoul";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

const LandingPageCmp = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const navigate = useNavigate();
  // let ExpandTxt = "Expand";
  // let ExpandHowTxt = "Expand";
  // const [whatIsExpanded, setWhatIsExpanded] = useState(false);
  // const whatExpand = () =>
  //   setWhatIsExpanded((whatIsExpanded) => !whatIsExpanded);

  // const [howIsExpanded, setHowIsExpanded] = useState(false);
  // const howExpand = () => setHowIsExpanded((howIsExpanded) => !howIsExpanded);

  // if (whatIsExpanded) {
  //   ExpandTxt = "Close";
  // }
  // if (howIsExpanded) {
  //   ExpandHowTxt = "Close";
  // }
  //const whatispribrImg1 = whatisImg;
  //const whatIsPribrExpand = false;
  //const howPribrWorksExpand = false;
  //const img2 = arrow;
  // const whatIsPribrContent = [
  //   "PriBr is a game changer in the private brands industry. Designed for convenience, seamless usability, and innovation in mind, PriBr is swiftly becoming an indispensable tool for retailers, vendors, and business partners alike. It is a platform where retail merchandising teams can easily login, explore and connect with vendors while staying on top of what products and innovations are coming next in their categories.",
  //   "PriBr is more than just a platform; it's a powerful private brand growth accelerator. By offering a one-of-a-kind space for retailers and vendors to connect, PriBr facilitates the creation of discreet, highly focused networks that act as catalysts for unparalleled private brand growth and development. It challenges conventional limitations and offers a simplified route to retailer<>vendor connectivity for agile growth and success. PriBr primarily focuses on Consumables, serving US-based retailers with a clear understanding of their unique needs and the day-to-day pain points of buyers, category leads, sourcing teams, QA, and private brand teams. Backed by industry experts PriBr delivers simple and easy access to quality vendors providing essential information at your fingertips and the ability to communicate instantly.",
  // ];

  // const howPribrWorks = [
  //   "PriBr is a fast-growing platform of vendors serving US-based retailers focused specifically on consumable products making it a niche space for quality suppliers and retailers to connect, network, communicate, and build and grow private brands.",
  //   "Retailers join PriBr via a free and simple sign-up process and start building their network of quality vendors after reviewing their products and profiles, their information is not shared with vendors by PriBr. ",
  //   "Vendors join PriBr by a simple sign-up. Complete their profile and upload details about their company and products. Vendors can showcase their offerings, product catalog, and upcoming products. They can upload all basic documents and details that retailers are looking for cutting down massive amounts of time in the sourcing process and helping retailers make faster and more informed decisions.",
  //   "Users can utilize their dashboard to organize their contacts and communicate via PriBr's messaging system. They can download and exchange documentation, review products, and even order samples. The level of information exposed is based on the available subscription plans.",
  // ];

  const whyPribrContent = [
    "PriBr is a fast-growing platform of vendors serving US-based retailers focused specifically on consumable products making it a niche space for quality suppliers and retailers to connect, network, communicate, and build and grow private brands.",
    "Retailers join PriBr via a free and simple sign-up process and start building their network of quality vendors after reviewing their products and profiles, their information is not shared with vendors by PriBr.",
    "Vendors join PriBr by a simple sign-up. Complete their profile and upload details about their company and products. Vendors can showcase their offerings, product catalog, and upcoming products. They can upload all basic documents and details that retailers are looking for cutting down massive amounts of time in the sourcing process and helping retailers make faster and more informed decisions.",
    "Users can utilize their dashboard to organize their contacts and communicate via PriBr's messaging system. They can download and exchange documentation, review products, and even order samples. The level of information exposed is based on the available subscription plans.",
  ];

  const whyPribrContentRetailer = [
    "PriBr is quickly becoming the go-to tool for retailers, primarily tapped by buyers, category managers, and product manager.",
    "Merchants and merchandising support teams cannot afford to miss out on the latest products offered in their specific consumable categories.",
    "As the market becomes more competitive, PriBr serves retailers the quickest and easiest access to the options and potential opportunities available on-hand.",
    "PriBr cuts down on the traditional bidding, sourcing, and procurement time significantly by providing key information, documentation, and product details.",
    "Competitor merchandising teams are most likely using PriBr to catalyze their owned brands' growth and penetration.",
    "Sourcing, quality assurance, and packaging design teams can save crucial lead time by tapping into PriBr and getting the basic information for their specific needs or requesting that information instantly and directly.",
    "Maintaining quality vendors on PriBr is a top priority. Retailers can report suppliers who are not responsive or with any quality concerns.",
  ];

  // const whyChoosepribrContent =
  //   "We take a comprehensive and personalized approach to serving our clients on our B2B portal. Our team works closely with clients to understand their unique needs and goals, and tailor our solutions accordingly. We leverage our industry expertise, technological capabilities, and network of partners to deliver results. We are committed to transparency, accountability, and continuous improvement in our approach.";
  // const howPribrLeadsContent =
  //   "We are a B2B portal that connects businesses across industries and geographies. Our platform provides a comprehensive suite of services, including networking, marketing, sales, and procurement solutions. Our team comprises experienced professionals with diverse backgrounds in technology, marketing, sales, and business development. We are passionate about empowering businesses to succeed in the digital age and are committed to delivering exceptional value to our clients. Our core values of honesty, integrity, innovation, and teamwork guide everything we do.";

  const handleSubscribe = async () => {
    setLoading(true);
    try {
      const response = await subscribeNewsletter({ email });
      if (response.success) {
        setSubscriptionStatus("success");
        setTimeout(() => {
          setModalVisible(false);
        }, 2000);
      } else {
        setSubscriptionStatus("error");
      }
    } catch (error) {
      setSubscriptionStatus("error");
      console.error("Subscription error:", error);
    } finally {
      setLoading(false);
      setEmail("");
    }
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.innerHeight + window.scrollY;
  //     const documentHeight = document.documentElement.offsetHeight;

  //     console.log(scrollPosition);
  //     console.log(documentHeight);

  //     if (scrollPosition >= documentHeight - 5 && !modalVisible) {
  //       setModalVisible(true);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [modalVisible]);

  return (
    <div className={classes.container}>
      <VerticalCarasoul></VerticalCarasoul>
      <div className={classes.verticalSlider}></div>
      <div className={classes.bannerContent}>
        <BannerCarasoul />
      </div>

      <section>
        <div className={classes.sectionDiv}>
          <Row
            gutter={16}
            align="middle"
            style={{ width: "100%", maxWidth: "1200px" }}
          >
            <Col
              xs={24}
              md={12}
              style={{ textAlign: "center" }}
              className={classes.sideImage}
            >
              <img
                src="https://pribr-prod.s3.amazonaws.com/pb_web/prod/banner-images/Screenshot%202024-01-09%20at%204.56.15%20PM-T-2d5e1467-b1d5-490b-9485-d8fd40219021.png"
                alt="What is PriBr"
                className="card-img"
              />
            </Col>
            <Col xs={24} md={12}>
              <Card className={classes.whatIsPribrText}>
                <h2 style={{ color: "#1a76bd", margin: 0 }}>What is PriBr?</h2>
                <p>
                  {
                    "PriBr transforms the private brands industry with a user-friendly platform for retailers, vendors, and business partners. It allows retail merchandising teams to connect with vendors, explore products, and stay updated on innovations. Acting as a growth accelerator, PriBr creates focused networks for private brand development and success. It simplifies retailer-vendor connectivity, ensuring agile growth. PriBr primarily serves US-based retailers, addressing the unique needs of buyers, sourcing teams, and QA professionals with easy access to quality vendors and instant communication."
                  }
                </p>
              </Card>
            </Col>
          </Row>
        </div>
      </section>
      <section>
        <div className={classes.sectionDiv}>
          <Row
            gutter={16}
            align="middle"
            style={{ width: "100%", maxWidth: "1200px" }}
          >
            <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
              <Card className={classes.howPribrWorksText}>
                <h2 style={{ color: "#1a76bd", margin: 0 }}>
                  How PriBr Works?
                </h2>
                <p>
                  {
                    "PriBr is a fast-growing platform of vendors serving US-based retailers focused specifically on consumable products making it a niche space for quality suppliers and retailers to connect, network, communicate, and build and grow private brands. Retailers join PriBr via a free and simple sign-up process and start building their network of quality vendors after reviewing their products and profiles, their information is not shared with vendors by PriBr. Vendors join PriBr by a simple sign-up. Complete their profile and upload details about their company and products. Vendors can showcase their offerings, product catalog, and upcoming products."
                  }
                </p>
              </Card>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ span: 12, order: 2 }}
              style={{ textAlign: "center" }}
              className={classes.sideImage}
            >
              <img
                src={howITWorksPribr}
                alt="How it works"
                className="card-img"
              />
            </Col>
          </Row>
        </div>
      </section>
      <section className={classes.sections}>
        <div className={"maxWidth1200"}>
          <Row gutter={32} className="d-flex align-items-center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <div className={classes.titles}>
                <img
                  src={mustHaveVendors}
                  className={classes.whyPribrImg}
                ></img>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Title level={3} className={classes.headingWhyPribr}>
                Why PriBr is a Must Have for your Private Brands (Vendors)
              </Title>
              <ul>
                {whyPribrContent.map((item, index) => (
                  <li
                    className={classes.whyPribrContent}
                    key={`why-pb-${item}-${index}`}
                  >
                    {item}
                  </li>
                ))}
              </ul>
              <Button
                className={classes.plansBtn}
                type="primary"
                size="large"
                onClick={() => navigate("/public/plans-and-features")}
              >
                View Plans & Join for FREE!
              </Button>
            </Col>
          </Row>
        </div>
      </section>
      <section className={classes.sections}>
        <div className={"maxWidth1200"}>
          <Row gutter={32} className="d-flex align-items-center mb-10">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Title level={3} className={classes.headingWhyPribr}>
                Why PriBr is a Must Have for your Private Brands (Retailers)
              </Title>
              <ul>
                {whyPribrContentRetailer.map((item, index) => (
                  <li
                    className={classes.whyPribrContent}
                    key={`why-pb-${item}-${index}`}
                  >
                    {item}
                  </li>
                ))}
              </ul>
              <Button
                className={classes.plansBtn}
                type="primary"
                size="large"
                onClick={() => navigate("/public/plans-and-features")}
              >
                View Plans & Join for FREE!
              </Button>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <div className={classes.titles}>
                <img
                  src={mustHavesRetailer}
                  className={classes.whyPribrImg}
                ></img>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      {/* It is Useful For Future Feature */}
      {/* <Row style={{ marginTop: "18px" }}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          className={classes.whyChoosePribrDiv}
        >
          <div className={classes.whyChoosePribrChildDiv}>
            <h3 className={classes.headingWhyChoosePribr}>Why Choose PriBr</h3>
            <p className={classes.contentWhyChoosePribr}>
              {whyChoosepribrContent}
            </p>
            <ul className={classes.listWhyChoosePribr}>
              <li className={classes.listItemWhyChoosePribr}>
                To provide a seamless and efficient B2B platform for businesses
                to connect, collaborate, and grow.
              </li>
              <li className={classes.listItemWhyChoosePribr}>
                To empower businesses by providing them with the tools,
                resources, and opportunities they need to succeed in
                today&apos;s rapidly changing market.
              </li>
            </ul>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          className={classes.whyChoosePribrImgDiv}
        >
          <img src={whyChoosePribrImg} className={classes.whyChoosePribrImg} />
        </Col>
      </Row>

      <Row gutter={32} className="d-flex align-items-center">
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          md={{ span: 24, order: 2 }}
          lg={{ span: 12, order: 1 }}
        >
          <div className={classes.titles}>
            <img src={howPribrLeads} className={classes.howPribrLeadsImg}></img>
          </div>
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          md={{ span: 24, order: 1 }}
          lg={{ span: 12, order: 2 }}
        >
          <Title level={3} className={classes.headingHowPribrLeads}>
            How PriBr leads the market
          </Title>
          <p className={classes.howPribrLeadsContent}>{howPribrLeadsContent}</p>
        </Col>
      </Row> */}
      {/* It is Useful For Future Feature */}

      <div className={"maxWidth marginAuto"}>
        <JoinNowBadge />
      </div>
      <Modal
        title="Subscribe to Our Newsletter"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubscribe}
            disabled={email === "" || loading}
          >
            {loading ? <Spin size="small" /> : "Subscribe"}
          </Button>,
        ]}
      >
        <p>Enter your email address to receive updates:</p>
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your email"
        />
      </Modal>
      <Modal
        title={subscriptionStatus === "success" ? "Success!" : "Error"}
        visible={subscriptionStatus !== null}
        onCancel={() => setSubscriptionStatus(null)}
        footer={[
          <Button key="ok" onClick={() => setSubscriptionStatus(null)}>
            OK
          </Button>,
        ]}
      >
        <p>
          {subscriptionStatus === "success"
            ? "Successfully subscribed to the newsletter!"
            : "You are already subscribed to the newsletter."}
        </p>
      </Modal>

      {/* It is Useful For Future Feature */}
      {/* <TestimonialCarousel /> */}
      {/* It is Useful For Future Feature */}
      {/* <ProductsCarosoul /> */}
    </div>
  );
};

export default LandingPageCmp;
