import { useMemo } from "react";
import { Empty, Skeleton } from "antd";
import { useSelector } from "react-redux";
import classes from "./List.module.css";
import ChatItem from "./Item";

const ChatList = ({ floating, loading, iconSize, chatList, onSelect }) => {
  const activeChat = useSelector((state) => state.chat.activeChat);

  const chatListItem = useMemo(() => {
    const userId = localStorage?.getItem("id");
    return chatList.map((item, index) => {
      const { users, ...restItem } = item;
      return (
        <ChatItem
          key={`chat-list-${item.id}-${index}-${Number(floating)}`}
          item={restItem}
          anotherUser={users.find((user) => Number(user.id) !== Number(userId))}
          iconSize={iconSize || 60}
          divider={index !== chatList.length - 1}
          activeChat={item.id === activeChat?.id && !floating}
          unreadCount={item[`unread_${userId}`]}
          onClick={() => onSelect(item)}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat?.id, chatList]);

  return (
    <div className={classes["chat-list"]}>
      {loading ? (
        Array.from({ length: 4 }).map((_, index) => (
          <Skeleton
            key={index}
            avatar
            className={classes["chat-list-skeleton"]}
            paragraph={{ rows: 1 }}
          />
        ))
      ) : chatList?.length ? (
        chatListItem
      ) : (
        <Empty
          rootClassName={classes["chat-list-empty"]}
          description="No Chat Item Found."
        />
      )}
    </div>
  );
};

export default ChatList;
