import { DownloadOutlined } from "@ant-design/icons";
import { Button, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getArtworkInformation } from "../../../services/MyProfileService";
import classes from "./ArtworkInfoTab.module.css";

const handleDownload = (fileUrl) => {
  window.open(fileUrl, "_blank");
};

const artworlLabelObj = {
  1: "Full InHouse label design",
  2: "Through our Ad Agency",
  3: "Basic InHouse capabilities",
  4: "Require vendors/suppliers to provide artwork",
};
const costPrefrenceObj = {
  1: "Retailer covers artwork cost",
  2: "Retailer requires vendors to cover for artwork cost",
  3: "Retailer shares artwork cost",
  4: "Retailer may share some artwork cost, depending on the order",
};

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    render: (text) => <span className={classes.itemHeading}>{text}</span>,
  },
  {
    title: "",
    dataIndex: "data",
    key: "data",
    align: "right",
    render: (text) => {
      if (Array.isArray(text)) {
        return (
          <>
            {text.map((item, index) =>
              Array.isArray(item) ? (
                <div key={index}>
                  {item.map((innerItem, innerIndex) => (
                    <Button
                      style={{ marginRight: "5px" }}
                      key={innerIndex}
                      onClick={() => handleDownload(innerItem)}
                    >
                      Download <DownloadOutlined />
                    </Button>
                  ))}
                </div>
              ) : (
                <Button key={index} onClick={() => handleDownload(item)}>
                  Download <DownloadOutlined />
                </Button>
              ),
            )}
          </>
        );
      } else {
        return <span className={classes.itemData}>{text}</span>;
      }
    },
  },
];

const ArtworkInfoTab = (props) => {
  console.log(props.currentPlan);
  const navigate = useNavigate();
  const [infoData, setInfoData] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state
  const params = useParams();

  const dataObj = {
    adAgencyName: {
      name: "Agency Name",
      required: [3],
    },
    artworkContact: {
      name: "Primary contact for Artwork",
      required: [3],
    },
    artworkEmail: {
      name: "Email",
      required: [3],
    },
    artworkLabel: {
      name: "Artwork and Label Design Capabilities",
      required: [3],
    },
    artworkRequirements: {
      name: "Artwork Requirements Given",
      required: [3],
    },
    artworkRequirementPath: { name: "Artwork Requirement", required: [3] },
    artworkCostPreference: { name: "Artwork Cost Preference", required: [3] },
  };
  if (props.userType !== "RETAILER") {
    dataObj.dielinesPath = { name: "Dielines", required: [3] };
    dataObj.printerSpecsPath = { name: "Printer Specs", required: [3] };
  }
  async function fetchData() {
    try {
      setLoading(true); // Start loading
      const res = await getArtworkInformation({ userId: params.supplierId });
      // if (res && res.data) {
      const data = res.data || {};

      const orderedKeys = Object.keys(dataObj);

      const infoData = orderedKeys.map((key) => {
        const value = data[key];
        const item = dataObj[key];

        const userPlan =
          parseInt(localStorage.getItem("subscription"), 10) || 1;

        let mappedName = item?.name;
        let displayValue =
          value === null ||
          value === undefined ||
          value === "" ||
          (Array.isArray(value) && value.length === 0)
            ? "Field not entered by profile owner"
            : value;

        if (key === "artworkLabel") {
          displayValue =
            artworlLabelObj[displayValue] ||
            "Field not entered by profile owner";
        } else if (key === "artworkRequirements") {
          displayValue = displayValue ? "Yes" : "No";
        } else if (key === "artworkRequirementPath") {
          if (value != null) {
            displayValue = [value];
          }
        } else if (key === "productCatalogPath") {
          return [];
        } else if (key === "artworkCostPreference") {
          displayValue =
            costPrefrenceObj[displayValue] ||
            "Field not entered by profile owner";
        }

        const isAllowed = item.required.includes(userPlan);
        return {
          name: mappedName,
          data: isAllowed ? displayValue : null,
          isAllowed,
        };
      });

      setInfoData(infoData);
      // }
      setLoading(false);
    } catch (err) {
      setLoading(false);

      // Handle error
    }
  }

  useEffect(() => {
    fetchData();
  }, [props.currentPlan]);

  const SkellotonCtrl = (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );

  const tableData = infoData.map((item) => {
    if (!item.isAllowed) {
      return {
        name: item.name,
        data: (
          <span>
            <p>Please Upgrade Plan to View this Information</p>
            <Button
              type="primary"
              onClick={() => {
                navigate("/app/settings");
              }}
            >
              Subscribe Now
            </Button>
          </span>
        ),
      };
    }

    return {
      name: item.name,
      data: item.data,
    };
  });

  return loading ? (
    SkellotonCtrl
  ) : (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      showHeader={false}
      bordered={false}
    />
  );
};

export default ArtworkInfoTab;
