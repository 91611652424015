import { useState } from "react";
import classes from "./ResetPassword.module.css";
import { Button, Col, Form, Input, Row } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/AuthService";
import { toast } from "react-toastify";
import RegisterFlowLayout from "../Layout/RegisterFlowLayout";
import { confirmPasswordSchema, passwordSchema } from "../../schemas";

const initialValues = {
  password: "",
  confirm_password: "",
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formLoading, setFormLoading] = useState(false);

  const submitHandler = async (values) => {
    try {
      setFormLoading(true);
      const objData = {};
      objData["password"] = values.password;
      objData["confirm_password"] = values.confirm_password;
      objData["email"] = location.state.email;
      objData["otp"] = location.state.otp;

      const res = await resetPassword(objData);
      if (res && res.success === true) {
        toast.success(res.message);
        navigate("/public/signin");
      } else {
        throw new Error(res.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <RegisterFlowLayout title={"Reset Password"}>
      <Form onFinish={submitHandler} initialValues={initialValues}>
        <label htmlFor="password">New Password</label>
        <Form.Item name="password" rules={passwordSchema}>
          <Input.Password
            id="password"
            type="password"
            placeholder="Enter new Password"
            className={classes["custom-input"]}
          />
        </Form.Item>

        <label htmlFor="confirm_password">Confirm Password</label>
        <Form.Item
          name="confirm_password"
          dependencies={["password"]}
          rules={confirmPasswordSchema}
        >
          <Input.Password
            id="confirm_password"
            type="password"
            placeholder="Enter confirm Password"
            className={classes["custom-input"]}
          />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          className={classes["submit-btn"]}
          loading={formLoading}
        >
          Submit
        </Button>
      </Form>
      <Row>
        <Col span={24}>
          <p className={classes.terms}>
            By continuing you agree to our{" "}
            <Link to="/public/terms">Terms of Usage</Link> and{" "}
            <Link to="/public/privacy">Privacy Policy</Link>
          </p>
        </Col>
        <Col span={24}>
          <p className={classes.terms}>
            Don’t have an account? <Link to="/public/signup">Sign Up</Link>
          </p>
        </Col>
      </Row>
    </RegisterFlowLayout>
  );
};

export default ResetPassword;
