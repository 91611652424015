import APIConstant from "../constant/APIConstant";
import Request from "./UtilsService";

export async function registerUser(data) {
  return await Request({
    url: APIConstant.AUTH_BASE_URL + APIConstant.REGISTER_USER,
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function loginUser(data) {
  return await Request({
    url: APIConstant.AUTH_BASE_URL + APIConstant.LOGIN_USER,
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//forgot password related funstions start here

//send otp to email address to FORGOT_PASSWORD
export async function sendOtp(data) {
  return await Request({
    url: APIConstant.AUTH_BASE_URL + APIConstant.FORGOT_PASSWORD,
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//verify otp and email
export async function verifyOtp(data) {
  return await Request({
    url: APIConstant.AUTH_BASE_URL + APIConstant.VERIFY_OTP,
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//reset password
export async function resetPassword(data) {
  return await Request({
    url: APIConstant.AUTH_BASE_URL + APIConstant.RESET_PASSWORD,
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//forgot password related funstions end here

//service for settings change password start
export async function changePasswordSettings(data) {
  return await Request({
    url: APIConstant.USER_BASE_URL + APIConstant.CHANGE_PASSWORD,
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
//service for settings change password end


//for delete account start
export async function deleteAccount(data) {
  return await Request({
    url: APIConstant.AUTH_BASE_URL  + APIConstant.DELETE_ACCOUNT,
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
//for delete account end