import React from 'react'
import PlansandFeatures from '../components/PlansandFeatures/PlansandFeatures'

const PlansAndFeatures = () => {
  return (
    <>
      <PlansandFeatures/>
    </>
  )
}

export default PlansAndFeatures
