import { CameraOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Skeleton,
  Tooltip,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FileValidationConstant from "../../../../constant/FileValidationConstant";
import { companyNameSchema, emailSchema } from "../../../../schemas";
import {
  companyInformationSupplier,
  getCompanyInformationSupplier,
} from "../../../../services/CreateProfileService";
import { getUserPlan } from "../../../../services/GeneralService";
import { fetchUser } from "../../../../store/slice/user_slice";
import classes from "./CompanyInfoForm.module.css";

const companyTypeArr = [
  { value: 1, label: "LLC" },
  { value: 2, label: "Public" },
  { value: 3, label: "Partnership" },
  { value: 4, label: "Sole proprietor" },
  { value: 5, label: "Other" },
];
const annualRevenueArr = [
  { value: "< $100K" },
  { value: "$100K - $500K" },
  { value: "$500K - $1M" },
  { value: "$1M - $10M" },
  { value: "$10M - $50M" },
  { value: "$50M - $100M" },
  { value: "$100M - $500M" },
  { value: "$500M - $1B" },
  { value: "$1B - $2B" },
  { value: "$2B - $3B" },
  { value: "> $3B" },
];
const noOfEmployeesArr = [
  { value: "< 50" },
  { value: "50 to 100" },
  { value: "100 to 500" },
  { value: "500 to 1000" },
  { value: "1000 to 5000" },
  { value: "5000 to 10000" },
  { value: "> 10000" },
];

const CompanyInfoForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [logoError, setLogoError] = useState({ message: "", exists: false });
  const auth = useSelector((state) => state.auth);
  const generalData = useSelector((state) => state.generalData);
  const dispatch = useDispatch();
  const [userPlan, setUserPlan] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSvgClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  async function fetchUserPlan() {
    try {
      const userId = auth?.id;
      const newplanid = await getUserPlan({ userId });
      if (newplanid.data) {
        setUserPlan(newplanid.data.plan.id);
      }
    } catch (error) {
      console.error("Error fetching user plan:", error);
    }
  }

  useEffect(() => {
    fetchUserPlan();
  });

  const locationArr = generalData?.location?.map((obj) => {
    return {
      value: obj.name,
      label: obj.name,
    };
  });
  const [saveData, setSaveData] = useState(false);

  const [form] = Form.useForm();

  const [initialValues, setInitialValues] = useState({
    companyLogoURL: "",
    companyName: "",
    companyLocation: null,
    companyEmail: "",
    companyAddress: "",
    companyPhone: "",
    companyWebsite: "",
    timezone: null,
    companyType: null,
    ceoName: "",
    //annualSale: "",
    annualRevenue: null,
    noOfEmployee: null,
    keyCompetitors: "",
  });

  useEffect(() => {
    if (auth.id) {
      setLoading(true);
      getCompanyInformationSupplier({ userId: auth.id })
        .then((res) => {
          if (res?.data) {
            const fetchedData = res.data;

            const data = {
              companyLogoURL: fetchedData.companyLogoURL,
              companyName: fetchedData.companyName,
              companyLocation:
                fetchedData.companyLocation !== "null"
                  ? fetchedData.companyLocation
                  : null,
              companyEmail: fetchedData.companyEmail,
              companyAddress: fetchedData.companyAddress,
              companyPhone: fetchedData.companyPhone,
              companyWebsite: fetchedData.companyWebsite,
              companyType: fetchedData.companyType,
              timezone: fetchedData.company_timezone_id,
              ceoName: fetchedData.ceoName,
              annualRevenue:
                fetchedData.annualRevenue !== "null"
                  ? fetchedData.annualRevenue
                  : null,
              noOfEmployee: fetchedData.noOfEmployee,
              keyCompetitors: fetchedData.keyCompetitors,
            };

            setInitialValues(data);
            setLoading(false);
          } else {
            throw new Error(res.message);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [auth.id]);

  const submitHandler = async (values) => {
    if (logoError.exists) {
      return;
    }

    const formData = new FormData();

    if (values.companyLogoURL?.file) {
      formData.append(
        "companyLogo",
        values.companyLogoURL?.file?.originFileObj,
      );
    }

    values.companyName && formData.append("companyName", values.companyName);
    values.companyLocation &&
      formData.append("companyLocation", values.companyLocation);
    values.companyAddress &&
      formData.append("companyAddress", values.companyAddress);
    values.companyPhone && formData.append("companyPhone", values.companyPhone);
    values.companyEmail && formData.append("companyEmail", values.companyEmail);
    values.companyWebsite &&
      formData.append("companyWebsite", values.companyWebsite);
    values.timezone && formData.append("company_timezone_id", values.timezone);
    values.companyType && formData.append("companyType", values.companyType);
    values.ceoName && formData.append("ceoName", values.ceoName);
    values.annualRevenue &&
      formData.append("annualRevenue", values.annualRevenue);
    values.noOfEmployee && formData.append("noOfEmployee", values.noOfEmployee);
    values.keyCompetitors &&
      formData.append("keyCompetitors", values.keyCompetitors);
    auth.id && formData.append("userId", auth.id);

    setSaveData(true);
    try {
      const res = await companyInformationSupplier(formData);
 
      if (res?.data) {
        toast.success(res.message);
        dispatch(fetchUser());
      } else {
        throw new Error(res.message);
      }
      setSaveData(false);
    } catch (err) {
      setSaveData(false);
      toast.error(err.message);
    }
  };
  const changeUploadFile = (fileObj) => {
    let file = { file: fileObj?.fileList?.[0] };
    const allowedFileTypeLogo =
      FileValidationConstant.ALLOWED_FILE_TYPE_LOGO_CP_SUPPLIER_FORM1;
    const allowedFileSizeLogo =
      FileValidationConstant.ALLOWED_FILE_SIZE_LOGO_CP_SUPPLIER_FORM1;

    const fileExtension = `.${file?.file?.name
      ?.split(".")
      .pop()
      .toLowerCase()}`;
    const isValidFileType = allowedFileTypeLogo.includes(fileExtension);
    const isValidFileSize = file?.file?.size <= allowedFileSizeLogo;

    if (!file.file) {
      form.setFieldValue("companyLogoURL", null);
      setInitialValues((preVal) => ({ ...preVal, companyLogoURL: null }));
      setLogoError({ message: "", exists: false });
      return;
    }

    if (!isValidFileType && file.file) {
      setLogoError({
        message: `Only ${allowedFileTypeLogo.join(", ")} files are allowed.`,
        exists: true,
      });
      return;
    }

    if (!isValidFileSize && file.file) {
      setLogoError({
        message: `File size must be within ${
          allowedFileSizeLogo / (1024 * 1024)
        }MB.`,
        exists: true,
      });
      return;
    }
    setLogoError({ message: "", exists: false });

    if (file.file) {
      file.file.status = "done";
      setInitialValues((preVal) => ({
        ...preVal,
        companyLogoURL: file.file?.originFileObj,
      }));
    }
  };

  if (loading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  return (
    <Form
      form={form}
      onFinish={submitHandler}
      initialValues={initialValues}
      autoComplete="off"
    >
      <label className={classes["form-label"]}>Logo/Profile Picture</label>
      <p className="m-0" style={{ marginBottom: "8px" }}>
        {`Only ${FileValidationConstant.ALLOWED_FILE_TYPE_LOGO_CP_SUPPLIER_FORM1.join(
          ", ",
        )} file types are supported.`}
      </p>
      <Form.Item
        name="companyLogoURL"
        rules={[{ required: false, message: "Please upload company logo" }]}
      >
        <Upload
          maxCount={1}
          action={null}
          listType="picture-circle"
          showUploadList={false}
          onChange={changeUploadFile}
          accept={FileValidationConstant.ALLOWED_FILE_TYPE_LOGO_CP_SUPPLIER_FORM1.join(
            ", ",
          )}
        >
          {initialValues.companyLogoURL ? (
            <div className="w-100 h-100" style={{ position: "relative" }}>
              <MinusCircleOutlined
                className={classes["remove-image"]}
                title="Remove Picture"
                onClick={(e) => {
                  e.stopPropagation();
                  changeUploadFile(e);
                }}
              />
              <img
                src={
                  typeof initialValues.companyLogoURL === "object"
                    ? URL.createObjectURL(initialValues.companyLogoURL)
                    : initialValues.companyLogoURL
                }
                alt="img"
                className={classes["company-logo"]}
              />
            </div>
          ) : (
            <div>
              <CameraOutlined style={{ fontSize: "27px" }} />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
      </Form.Item>
      {logoError.exists ? (
        <p className={classes["error-text"] + " mt-0"}>{logoError.message}</p>
      ) : null}

      <label htmlFor="companyName" className={classes["form-label"]}>
        Company Name
      </label>
      <Form.Item name="companyName" rules={companyNameSchema}>
        <Input
          id="companyName"
          placeholder="Enter Company Name"
          className={classes["custom-input"]}
        />
      </Form.Item>

      <label htmlFor="companyLocation" className={classes["form-label"]}>
        Location
      </label>
      <Form.Item name="companyLocation">
        <Select
          id="companyLocation"
          showSearch
          bordered={false}
          placeholder="Select company location"
          className={classes["custom-input"]}
          options={locationArr}
        />
      </Form.Item>

      <Tooltip
        title={
          userPlan === 1 ? "Please upgrade your plan to unlock this field" : ""
        }
      >
        <label htmlFor="companyAddress" className={classes["form-label"]}>
          Address{" "}
          {userPlan === 1 && (
            <svg
              onClick={handleSvgClick}
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="15"
              height="15"
              viewBox="0 0 50 50"
              style={{ cursor: "pointer" }}
            >
              <path d="M25,2C12.297,2,2,12.297,2,25s10.297,23,23,23s23-10.297,23-23S37.703,2,25,2z M25,11c1.657,0,3,1.343,3,3s-1.343,3-3,3 s-3-1.343-3-3S23.343,11,25,11z M29,38h-2h-4h-2v-2h2V23h-2v-2h2h4v2v13h2V38z"></path>
            </svg>
          )}
        </label>
        <Form.Item
          name="companyAddress"
          rules={
            userPlan === 1
              ? []
              : [{ required: false, message: "Please enter your address" }]
          }
        >
          <Input
            id="companyAddress"
            placeholder={
              userPlan === 1
                ? "Please upgrade your plan to unlock this field"
                : "Enter Address"
            }
            className={`${classes["custom-input"]} ${
              userPlan == 1 ? classes["disabled-field"] : ""
            }`}
            disabled={userPlan === 1}
          />
        </Form.Item>
      </Tooltip>

      <Tooltip
        title={
          userPlan === 1 || userPlan === 2
            ? "Please upgrade your plan to unlock this field"
            : ""
        }
      >
        <label htmlFor="companyPhone" className={classes["form-label"]}>
          Phone{" "}
          {(userPlan === 1 || userPlan === 2) && (
            <svg
              onClick={handleSvgClick}
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="15"
              height="15"
              viewBox="0 0 50 50"
              style={{ cursor: "pointer" }}
            >
              <path d="M25,2C12.297,2,2,12.297,2,25s10.297,23,23,23s23-10.297,23-23S37.703,2,25,2z M25,11c1.657,0,3,1.343,3,3s-1.343,3-3,3 s-3-1.343-3-3S23.343,11,25,11z M29,38h-2h-4h-2v-2h2V23h-2v-2h2h4v2v13h2V38z"></path>
            </svg>
          )}
        </label>
        <Form.Item
          name="companyPhone"
          rules={
            userPlan === 1 || userPlan === 2
              ? []
              : [
                  {
                    required: false,
                    message: "Please enter your phone number",
                  },
                ]
          }
        >
          <Input
            id="companyPhone"
            placeholder={
              userPlan === 1 || userPlan === 2
                ? "Please upgrade your plan to unlock this field"
                : "Enter phone number"
            }
            className={`${classes["custom-input"]} ${
              userPlan == 1 || userPlan === 2 ? classes["disabled-field"] : ""
            }`}
            disabled={userPlan === 1 || userPlan === 2}
          />
        </Form.Item>
      </Tooltip>

      <Tooltip
        title={
          userPlan === 1 || userPlan === 2
            ? "Please upgrade your plan to unlock this field"
            : ""
        }
      >
        <label htmlFor="companyEmail" className={classes["form-label"]}>
          Email{" "}
          {(userPlan === 1 || userPlan === 2) && (
            <svg
              onClick={handleSvgClick}
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="15"
              height="15"
              viewBox="0 0 50 50"
              style={{ cursor: "pointer" }}
            >
              <path d="M25,2C12.297,2,2,12.297,2,25s10.297,23,23,23s23-10.297,23-23S37.703,2,25,2z M25,11c1.657,0,3,1.343,3,3s-1.343,3-3,3 s-3-1.343-3-3S23.343,11,25,11z M29,38h-2h-4h-2v-2h2V23h-2v-2h2h4v2v13h2V38z"></path>
            </svg>
          )}
        </label>
        <Form.Item
          name="companyEmail"
          rules={userPlan === 1 || userPlan === 2 ? [] : emailSchema}
        >
          <Input
            id="companyEmail"
            placeholder={
              userPlan === 1 || userPlan === 2
                ? "Please upgrade your plan to unlock this field"
                : "Enter Email"
            }
            className={`${classes["custom-input"]} ${
              userPlan == 1 || userPlan === 2 ? classes["disabled-field"] : ""
            }`}
            disabled={userPlan === 1 || userPlan === 2}
          />
        </Form.Item>
      </Tooltip>

      <Tooltip
        title={
          userPlan === 1 || userPlan === 2
            ? "Please upgrade your plan to unlock this field"
            : ""
        }
      >
        <label htmlFor="companyWebsite" className={classes["form-label"]}>
          Website{" "}
          {(userPlan === 1 || userPlan === 2) && (
            <svg
              onClick={handleSvgClick}
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="15"
              height="15"
              viewBox="0 0 50 50"
              style={{ cursor: "pointer" }}
            >
              <path d="M25,2C12.297,2,2,12.297,2,25s10.297,23,23,23s23-10.297,23-23S37.703,2,25,2z M25,11c1.657,0,3,1.343,3,3s-1.343,3-3,3 s-3-1.343-3-3S23.343,11,25,11z M29,38h-2h-4h-2v-2h2V23h-2v-2h2h4v2v13h2V38z"></path>
            </svg>
          )}
        </label>

        <Form.Item
          name="companyWebsite"
          rules={
            userPlan === 1 || userPlan === 2
              ? []
              : [{ required: false, message: "Please enter your website" }]
          }
        >
          <Input
            id="companyWebsite"
            placeholder={
              userPlan === 1 || userPlan === 2
                ? "Please upgrade your plan to unlock this field"
                : "Enter website"
            }
            className={`${classes["custom-input"]} ${
              userPlan == 1 || userPlan === 2 ? classes["disabled-field"] : ""
            }`}
            disabled={userPlan === 1 || userPlan === 2}
          />
        </Form.Item>
      </Tooltip>

      {/* <label htmlFor="timezone" className={classes["form-label"]}>
        Timezone
      </label>
      <Form.Item
        name="timezone"
        rules={[{ required: false, message: "Please select timezone" }]}
      >
        <Select
          id="timezone"
          showSearch
          bordered={false}
          placeholder="Select timezone"
          className={classes["custom-input"]}
          options={timezoneArr}
        />
      </Form.Item> */}

      <Tooltip
        title={
          userPlan === 1 ? "Please upgrade your plan to unlock this field" : ""
        }
      >
        <label htmlFor="companyType" className={classes["form-label"]}>
          Company Type{" "}
          {userPlan === 1 && (
            <svg
              onClick={handleSvgClick}
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="15"
              height="15"
              viewBox="0 0 50 50"
              style={{ cursor: "pointer" }}
            >
              <path d="M25,2C12.297,2,2,12.297,2,25s10.297,23,23,23s23-10.297,23-23S37.703,2,25,2z M25,11c1.657,0,3,1.343,3,3s-1.343,3-3,3 s-3-1.343-3-3S23.343,11,25,11z M29,38h-2h-4h-2v-2h2V23h-2v-2h2h4v2v13h2V38z"></path>
            </svg>
          )}
        </label>
        <Form.Item
          name="companyType"
          rules={
            userPlan === 1
              ? []
              : [
                  {
                    required: false,
                    message: "Please select your company type",
                  },
                ]
          }
        >
          <Select
            id="companyType"
            showSearch
            bordered={false}
            placeholder={
              userPlan === 1
                ? "Please upgrade your plan to unlock this field"
                : "Select Company Type"
            }
            className={`${classes["custom-input"]} ${
              userPlan == 1 ? classes["disabled-field"] : ""
            }`}
            options={companyTypeArr}
            disabled={userPlan === 1}
          />
        </Form.Item>
      </Tooltip>

      <Tooltip
        title={
          userPlan === 1 ? "Please upgrade your plan to unlock this field" : ""
        }
      >
        <label htmlFor="ceoName" className={classes["form-label"]}>
          President or CEO Name{" "}
          {userPlan === 1 && (
            <svg
              onClick={handleSvgClick}
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="15"
              height="15"
              viewBox="0 0 50 50"
              style={{ cursor: "pointer" }}
            >
              <path d="M25,2C12.297,2,2,12.297,2,25s10.297,23,23,23s23-10.297,23-23S37.703,2,25,2z M25,11c1.657,0,3,1.343,3,3s-1.343,3-3,3 s-3-1.343-3-3S23.343,11,25,11z M29,38h-2h-4h-2v-2h2V23h-2v-2h2h4v2v13h2V38z"></path>
            </svg>
          )}
        </label>
        <Form.Item
          name="ceoName"
          rules={
            userPlan === 1
              ? []
              : [{ required: false, message: "Please enter your CEO name" }]
          }
        >
          <Input
            id="ceoName"
            placeholder={
              userPlan === 1
                ? "Please upgrade your plan to unlock this field"
                : "Enter President or CEO Name"
            }
            className={`${classes["custom-input"]} ${
              userPlan == 1 ? classes["disabled-field"] : ""
            }`}
            disabled={userPlan === 1}
          />
        </Form.Item>
      </Tooltip>

      {/* <label htmlFor="annualSale" className={classes["form-label"]}>
        Gross Annual Sales for last fiscal year
      </label>
      <Form.Item
        name="annualSale"
        rules={[{ required: true, message: "Please Enter your Annual Sales" }]}
      >
        <Input
          id="annualSale"
          placeholder="Enter Gross Annual Sales for last fiscal year"
          className={classes["custom-input"]}
        />
      </Form.Item> */}

      <Tooltip
        title={
          userPlan === 1 ? "Please upgrade your plan to unlock this field" : ""
        }
      >
        <label htmlFor="annualRevenue" className={classes["form-label"]}>
          Annual Revenue{" "}
          {userPlan === 1 && (
            <svg
              onClick={handleSvgClick}
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="15"
              height="15"
              viewBox="0 0 50 50"
              style={{ cursor: "pointer" }}
            >
              <path d="M25,2C12.297,2,2,12.297,2,25s10.297,23,23,23s23-10.297,23-23S37.703,2,25,2z M25,11c1.657,0,3,1.343,3,3s-1.343,3-3,3 s-3-1.343-3-3S23.343,11,25,11z M29,38h-2h-4h-2v-2h2V23h-2v-2h2h4v2v13h2V38z"></path>
            </svg>
          )}
        </label>
        <Form.Item
          name="annualRevenue"
          rules={
            userPlan === 1
              ? []
              : [{ message: "Please Select your Annual Revenue" }]
          }
        >
          <Select
            id="annualRevenue"
            showSearch
            bordered={false}
            placeholder={
              userPlan === 1
                ? "Please upgrade your plan to unlock this field"
                : "Select Annual Revenue"
            }
            className={`${classes["custom-input"]} ${
              userPlan == 1 ? classes["disabled-field"] : ""
            }`}
            options={annualRevenueArr}
            disabled={userPlan === 1}
          />
        </Form.Item>
      </Tooltip>

      <Tooltip
        title={
          userPlan === 1 || userPlan === 2
            ? "Please upgrade your plan to unlock this field"
            : ""
        }
      >
        <label htmlFor="noOfEmployee" className={classes["form-label"]}>
          Number of Employees{" "}
          {(userPlan === 1 || userPlan === 2) && (
            <svg
              onClick={handleSvgClick}
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="15"
              height="15"
              viewBox="0 0 50 50"
              style={{ cursor: "pointer" }}
            >
              <path d="M25,2C12.297,2,2,12.297,2,25s10.297,23,23,23s23-10.297,23-23S37.703,2,25,2z M25,11c1.657,0,3,1.343,3,3s-1.343,3-3,3 s-3-1.343-3-3S23.343,11,25,11z M29,38h-2h-4h-2v-2h2V23h-2v-2h2h4v2v13h2V38z"></path>
            </svg>
          )}
        </label>
        <Form.Item
          name="noOfEmployee"
          rules={
            userPlan === 1 || userPlan === 2
              ? []
              : [{ required: false, message: "Select Number of Employees" }]
          }
        >
          <Select
            id="noOfEmployee"
            showSearch
            bordered={false}
            placeholder={
              userPlan === 1 || userPlan === 2
                ? "Please upgrade your plan to unlock this field"
                : "Select Number of Employees"
            }
            className={`${classes["custom-input"]} ${
              userPlan == 1 || userPlan == 2 ? classes["disabled-field"] : ""
            }`}
            options={noOfEmployeesArr}
            disabled={userPlan === 1 || userPlan === 2}
          />
        </Form.Item>
      </Tooltip>

      <Tooltip
        title={
          userPlan === 1 || userPlan === 2
            ? "Please upgrade your plan to unlock this field"
            : ""
        }
      >
        <label htmlFor="keyCompetitors" className={classes["form-label"]}>
          Key competitors{" "}
          {(userPlan === 1 || userPlan === 2) && (
            <svg
              onClick={handleSvgClick}
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="15"
              height="15"
              viewBox="0 0 50 50"
              style={{ cursor: "pointer" }}
            >
              <path d="M25,2C12.297,2,2,12.297,2,25s10.297,23,23,23s23-10.297,23-23S37.703,2,25,2z M25,11c1.657,0,3,1.343,3,3s-1.343,3-3,3 s-3-1.343-3-3S23.343,11,25,11z M29,38h-2h-4h-2v-2h2V23h-2v-2h2h4v2v13h2V38z"></path>
            </svg>
          )}
        </label>
        <Form.Item
          name="keyCompetitors"
          rules={
            userPlan === 1 || userPlan === 2
              ? []
              : [
                  {
                    required: false,
                    message: "Please Enter Your competitors",
                  },
                  {
                    min: 2,
                    max: 1000,
                    message:
                      "Key Competitors should be minimum 2 and maximum 1000 letter.",
                  },
                ]
          }
        >
          <Input.TextArea
            id="keyCompetitors"
            rows={4}
            placeholder={
              userPlan === 1 || userPlan === 2
                ? "Please upgrade your plan to unlock this field"
                : "Enter Key competitors"
            }
            className={`${classes["custom-input"]} ${
              userPlan == 1 ? classes["disabled-field"] : ""
            }`}
            disabled={userPlan === 1 || userPlan === 2}
          />
        </Form.Item>
      </Tooltip>

      <Button
        type="primary"
        htmlType="submit"
        className={classes["submit-btn"]}
        loading={saveData}
      >
        Save
      </Button>
      {/* <Button
        type="default"
        htmlType="button"
        className={classes["next-btn"]}
        onClick={nextClickHandler}
      >
        Next
      </Button> */}
      <Modal
        title="Upgrade Required"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <p>Please Upgrade Plan to View this Information</p>
        <Button
          type="primary"
          onClick={() => {
            navigate("/app/settings");
            handleModalClose();
          }}
        >
          Subscribe Now
        </Button>
      </Modal>
    </Form>
  );
};

export default CompanyInfoForm;
