import APIConstant from "../constant/APIConstant";
import Request from "./UtilsService";

export async function getCompanyInformation(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.COMPANY_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getMemberInformation(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.MEMBER_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export async function getCategoryAndProductInformation(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.CATEGORY_PRODUCT_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getArtworkInformation(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.ARTWORK_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getQualityAssuranceInformation(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.QUALITY_ASSURANCE_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteFile(data) {
  return await Request({
    url: APIConstant.USER_BASE_URL + APIConstant.DELETE_FILE,
    method: "DELETE",
    body: data,

    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteProfileFile(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.DELETE_FILE,
    method: "DELETE",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteArtworkFile(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.ARTWORK_DETAILS +
      APIConstant.DELETE_FILE,
    method: "DELETE",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deletePrivateBrand(data) {
  return await Request({
    url: APIConstant.USER_BASE_URL + APIConstant.DELETE_BRAND,
    method: "DELETE",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
