import { configureStore } from "@reduxjs/toolkit";
import authSlice, { loadTokenFromStorage } from "./slice/auth_slice";
import chatReducer from "./slice/chat_slice";
import flyoutReducer from "./slice/flyout_slice";
import generalReducer, {
  getCategories,
  getDepartments,
  getLocation,
  getTimezone,
} from "./slice/generalData_slice";
import percentageReducer, {
  fetchPercentage,
} from "./slice/getProfilePercentage_slice";
import userReducer, { fetchUser } from "./slice/user_slice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    percentage: percentageReducer,
    generalData: generalReducer,
    chat: chatReducer,
    flyout: flyoutReducer,
    user: userReducer,
  },
});

store.dispatch(fetchUser());
store.dispatch(loadTokenFromStorage());
store.dispatch(fetchPercentage());
store.dispatch(getCategories());
store.dispatch(getDepartments());
store.dispatch(getLocation());
store.dispatch(getTimezone());
export default store;
