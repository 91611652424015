import APIConstant from "../constant/APIConstant";
import Request from "./UtilsService";

//for retailer create profiles api start
export async function companyInformationRetailer(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.COMPANY_DETAILS,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function memberInformationRetailer(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.MEMBER_DETAILS,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function categoryAndProductInformationRetailer(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.CATEGORY_PRODUCT_DETAILS,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function artworkInformationRetailer(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.ARTWORK_DETAILS,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function qualityAssuranceInformationRetailer(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.QUALITY_ASSURANCE_DETAILS,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
//for retailer create profiles api end

//for supplier create profiles api start
export async function companyInformationSupplier(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.COMPANY_DETAILS,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function memberInformationSupplier(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.MEMBER_DETAILS,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function categoryAndProductInformationSupplier(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.CATEGORY_PRODUCT_DETAILS,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function artworkInformationSupplier(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.ARTWORK_DETAILS,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function qualityAssuranceInformationSupplier(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.QUALITY_ASSURANCE_DETAILS,
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//for supplier create profiles api end

//get profile complition percentage start

export async function getProfileComplitionPercentage(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.GET_PROFILE_PERCENTAGE +
      "/" +
      data.userId,
    method: "GET",
    //  body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//get profile complition percentage end

//for supplier get create profile data start
export async function getCompanyInformationSupplier(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.COMPANY_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getmemberInformationSupplier(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.MEMBER_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export async function getCategoryAndProductInformationSupplier(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.CATEGORY_PRODUCT_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getArtworkInformationSupplier(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.ARTWORK_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getQualityAssuranceInformationSupplier(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.QUALITY_ASSURANCE_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
//for supplier get create profile data end

//for retailer get create profile data start
export async function getCompanyInformationRetailer(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.COMPANY_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getmemberInformationRetailer(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.MEMBER_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export async function getCategoryAndProductInformationRetailer(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.CATEGORY_PRODUCT_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getArtworkInformationRetailer(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.ARTWORK_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getQualityAssuranceInformationRetailer(data) {
  return await Request({
    url:
      APIConstant.USER_BASE_URL +
      APIConstant.UPDATE_PROFILE +
      APIConstant.QUALITY_ASSURANCE_DETAILS +
      "/" +
      data.userId,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//for retailer get create profile data end
