import { connect } from "react-redux";
import ChatPage from "../components/Chats/ChatPage";
import {
  setActiveChat,
  setMessages,
  updateMessage,
} from "../store/slice/chat_slice";

const ChatsPage = (props) => {
  return <ChatPage {...props} />;
};

const mapStateToProps = (state) => ({
  userId: state.user.data.id,
  activeChat: state.chat.activeChat,
  chatList: state.chat.chatList,
  chatListLoading: state.chat.chatListLoading,
  chatMessages: state.chat.chatMessages,
  chatMessageLoading: state.chat.chatMessageLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setMessages: (messages) => dispatch(setMessages(messages)),
  updateMessage: (message) => dispatch(updateMessage(message)),
  setActiveChat: (item) => dispatch(setActiveChat(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatsPage);
