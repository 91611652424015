import axios from "axios";
import APIConstant from "../constant/APIConstant";

const RequestGeneral = async (options) => {
  const BASE_URL = process.env.REACT_APP_JAVA_APP_API_PATH;

  const config = {
    headers: options["headers"],
    url: BASE_URL + options["url"],
    method: options["method"],
    data: options["body"],
  };

  return axios
    .request(config)
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        return error;
      } else {
        return error.response.data;
      }
    });
};

export async function getBannerByLocation(data) {
  return await RequestGeneral({
    url:
      APIConstant.BANNER_BASE_URL +
      APIConstant.GET_ALL_BANNER_BY_LOCATION +
      "?location=" +
      data.location,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
