import React, { useState } from "react";
import styled from "styled-components";

const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImagesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;
`;

const Image = styled.img`
  max-width: 45%;
  cursor: pointer;
`;

const Close = styled.img`
  cursor: pointer;
  position: absolute;
  left: 69%;
  top: 24%;
  bottom: 12.5%;
  width: 23.41px;
  height: 24px;
  display: flex;
`;

const Popup = ({ onClose }) => {
  const handleImageClick = (url) => {
    window.location.href = url;
    onClose();
  };

  return (
    <PopupWrapper>
      <PopupContent>
        <h2>Select Your Role</h2>
        <h3>Tell Us Which Title Suits You Better And Get Ahead</h3>
        <Close
          src={require("../../images/model/close.png")}
          alt="Image 3"
          onClick={() => handleImageClick("/")}
        />
        <ImagesWrapper>
          <Image
            src={require("../../images/model/Retailer.png")}
            alt="Image 1"
            onClick={() => handleImageClick("/RetailerSignup")}
          />
          <Image
            src={require("../../images/model/Supplier.png")}
            alt="Image 2"
            onClick={() => handleImageClick("/SupplierSignup")}
          />
        </ImagesWrapper>
      </PopupContent>
    </PopupWrapper>
  );
};

const App = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  return (
    <div>
      <button onClick={handleButtonClick}>Sign Up</button>
      {showPopup && <Popup onClose={handlePopupClose} />}
    </div>
  );
};

export default App;
