import { Button, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getMemberInformation } from "../../../services/MyProfileService";
import classes from "./MemberInfoTab.module.css";

//Plan Details Start
//T1 plan =1, Premium
//T2 plan =2, Standard
//T3 plan =3, Basic (Free)

//Plan Details End

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    render: (text) => <span className={classes.dataheading}>{text}</span>,
  },
  {
    title: "",
    dataIndex: "data",
    key: "data",
    align: "right",
    render: (text) => <span className={classes.dataContent}>{text}</span>,
  },
];

const MemberInfoTab = (props) => {
  const navigate = useNavigate();
  const [memberDetails, setMemberDetails] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const generalData = useSelector((state) => state.generalData);
  const params = useParams();
  const dataObj = {
    name: { name: "Name", required: [1, 2, 3] },
    title: { name: "Title", required: [1, 2, 3] },
    country: { name: "Location", required: [1, 2, 3] },
    address: { name: "Address", required: [2, 3] },
    website: { name: "Website", required: [2, 3] },
    ...(props.userType !== "RETAILER" && {
      isBroker: { name: "Is Broker", required: [2, 3] },
      email: { name: "Email", required: [3] },
      phone: { name: "Phone", required: [3] },
    }),
    // departmentId: { name: "Department" },

    // memberTimezoneId: { name: "Timezone" },
  };
  async function fetchData() {
    try {
      setLoading(true);
      const res = await getMemberInformation({ userId: params.supplierId });
      // if (res && res.data) {
      const data = res.data || {};

      const orderedKeys = Object.keys(dataObj);

      const infoData = orderedKeys.map((key) => {
        const value = data[key];
        const item = dataObj[key];
        const userPlan =
          parseInt(localStorage.getItem("subscription"), 10) || 1;

        let mappedName = item?.name;
        let displayValue =
          value === null || value === undefined || value === ""
            ? "Field not entered by profile owner"
            : value;

        if (key === "memberTimezoneId") {
          displayValue = generalData.timezone.find(
            (obj) => obj.id == value,
          )?.timezone;
        } else if (key === "isBroker") {
          displayValue = value === true ? "Yes" : "No";
        } else if (key === "memberLogoURL") {
          return []; // Skip this element
        }

        const isAllowed = item.required.includes(userPlan);

        return {
          name: mappedName,
          data: isAllowed ? displayValue : null,
          isAllowed,
        };
      });

      setMemberDetails(infoData);
      // }
    } catch (err) {
      // Handle error
    } finally {
      setLoading(false); // Stop loading after data fetch
    }
  }

  useEffect(() => {
    fetchData();
  }, [generalData, props.currentPlan]);

  const SkellotonCtrl = (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );

  if (loading) {
    return SkellotonCtrl;
  }

  const tableData = memberDetails.map((item) => {
    if (!item.isAllowed) {
      return {
        name: item.name,
        data: (
          <span>
            <p>Please Upgrade Plan to View this Information</p>
            <Button type="primary" onClick={() => navigate("/app/settings")}>
              Subscribe Now
            </Button>
          </span>
        ),
      };
    }

    return {
      name: item.name,
      data: item.data,
    };
  });

  return loading ? (
    SkellotonCtrl
  ) : (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      showHeader={false}
      bordered={false}
    />
  );
};
export default MemberInfoTab;
