import { useState } from "react";
import classes from "./VerifyOtp.module.css";
import { Button, Col, Form, Row } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { verifyOtp } from "../../services/AuthService";
import { toast } from "react-toastify";
import RegisterFlowLayout from "../Layout/RegisterFlowLayout";
import { InputOTP } from "antd-input-otp";

const initialValues = {
  otp: "",
};

const VerifyOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formLoading, setFormLoading] = useState(false);

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    // The value will be array of string
    // Check the field if there is no value, or value is undefined/empty string
    const { otp } = values;
    if (!otp || otp.includes(undefined) || otp.includes("")) {
      return form.setFields([
        {
          name: "otp",
          errors: ["OTP is invalid."],
        },
      ]);
    }
    const otpNumber = +otp.join("");
    submitHandler({ otp: otpNumber });
  };

  // For removing the error message
  const handleValuesChange = (changedValues) => {
    const name = Object.keys(changedValues)?.[0];
    const errors = form.getFieldError(name);
    if (errors.length < 1) return;
    form.setFields([{ name, errors: [] }]);
  };

  const submitHandler = async (values) => {
    try {
      if (!location?.state?.email) {
        return;
      }
      setFormLoading(true);
      const objData = {};
      objData.email = location.state.email;
      objData.otp = values.otp;

      const res = await verifyOtp(objData);
      if (res && res.success === true) {
        toast.success(res.message);
        navigate("/public/resetpassword", {
          state: { email: location.state.email, otp: values.otp },
        });
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <RegisterFlowLayout title={"Enter OTP"}>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleFinish}
        onValuesChange={handleValuesChange}
        autoComplete="off"
      >
        <Form.Item name="otp">
          <InputOTP inputType="numeric" length={6} />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          className={classes["submit-btn"]}
          loading={formLoading}
        >
          Submit
        </Button>

        <Row>
          <Col span={24}>
            <p className={classes.terms}>
              By continuing you agree to our{" "}
              <Link to="/public/terms">Terms of Usage</Link> and{" "}
              <Link to="/public/privacy">Privacy Policy</Link>
            </p>
          </Col>
          <Col span={24}>
            <p className={classes.terms}>
              Don’t have an account? <Link to="/public/signup">Sign Up</Link>
            </p>
          </Col>
        </Row>
      </Form>
    </RegisterFlowLayout>
  );
};

export default VerifyOtp;
