import { Button, Checkbox, Form, Input } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { emailSchema, passwordSchemaSignin } from "../../schemas";
import { loginUser } from "../../services/AuthService";
import { login, setToken } from "../../store/slice/auth_slice";
import {
  getCategories,
  getLocation,
  getTimezone,
} from "../../store/slice/generalData_slice";
import { fetchPercentage } from "../../store/slice/getProfilePercentage_slice";
import { fetchUser } from "../../store/slice/user_slice";
import classes from "./SigninForm.module.css";

const initialValues = {
  email: "",
  password: "",
  isRemember: false,
};

const SignInForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formLoading, setFormLoading] = useState(false);

  const submitHandler = async (values) => {
    try {
      setFormLoading(true);
      const res = await loginUser(values);
      if (res && res.data) {
        toast.success("Login Successfully");

        //add in redux start
        dispatch(login(res.data));
        dispatch(setToken(res.data.auth_token));
        localStorage.setItem("token", res.data.auth_token);
        //localStorage.setItem("username", res.data.userName);
        //localStorage.setItem("usertype", res.data.user_type);
        localStorage.setItem("id", res.data.id);
        //localStorage.setItem("isVerified", res?.data?.isVerified);
        //add in redux end
        localStorage.setItem("subscription", res?.userSubscription?.planId);

        dispatch(fetchPercentage());
        dispatch(getCategories());
        dispatch(getLocation());
        dispatch(getTimezone());
        dispatch(fetchUser());
        navigate("/");
      } else {
        throw new Error(res.message);
      }
    } catch (err) {
      toast.error(err.message);
      //console.log()
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <Form onFinish={submitHandler} initialValues={initialValues}>
      <label htmlFor="email">Email</label>
      <Form.Item name="email" rules={emailSchema}>
        <Input
          id="email"
          placeholder="Email"
          className={classes["custom-input"]}
        />
      </Form.Item>

      <label htmlFor="password">Password</label>
      <Form.Item
        name="password"
        rules={passwordSchemaSignin}
        className={classes["mb-0"]}
      >
        <Input.Password
          id="password"
          type="password"
          placeholder="Password"
          className={classes["custom-input"]}
        />
      </Form.Item>

      <div style={{ marginBottom: "10px" }}>
        <Link to="/public/forgotpassword">Forgot Password?</Link>
      </div>

      <Form.Item name="isRemember" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        className={classes["submit-btn"]}
        loading={formLoading}
      >
        Sign In
      </Button>
    </Form>
  );
};

export default SignInForm;
