import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIConstant from "../../constant/APIConstant";
import {
  GetAllCategory,
  GetAllDepartment,
} from "../../services/GeneralService";
import axios from "axios";

const initialState = {
  categories: [],
  departments: [],
  timezone: [],
  location: [],
  products: "",
  loading: null,
};

export const getCategories = createAsyncThunk(
  "generalData/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      if (!localStorage.getItem("token")) {
        throw new Error("User not logged in");
      }
      const response = await GetAllCategory({ pagination: false });
      return response.data.content;
    } catch (err) {
      rejectWithValue(err.message);
    }
  },
);
export const getDepartments = createAsyncThunk(
  "generalData/getDepartments",
  async (_, { rejectWithValue }) => {
    try {
      if (!localStorage.getItem("token")) {
        throw new Error("User not logged in");
      }
      const response = await GetAllDepartment({});
      return response.data.content;
    } catch (err) {
      rejectWithValue(err.message);
    }
  },
);
export const getLocation = createAsyncThunk(
  "generalData/getLocation",
  async (_, { rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      if (!authToken) {
        throw new Error("User not logged in");
      }

      const url =
        process.env.REACT_APP_JAVA_APP_API_PATH +
        APIConstant.COUNTRY_BASE_URL +
        APIConstant.GET_ALL_COUNTRY;

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          // "access-token": authToken,
          Authorization: `Bearer ${authToken}`,
        },
      });
      return response.data.data;
    } catch (err) {
      rejectWithValue(err.message);
    }
  },
);
export const getTimezone = createAsyncThunk(
  "generalData/getTimezone",
  async (_, { rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      if (!authToken) {
        throw new Error("User not logged in");
      }

      const url =
        process.env.REACT_APP_JAVA_APP_API_PATH +
        APIConstant.COUNTRY_BASE_URL +
        APIConstant.GET_ALL_TIMEZONE;

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          // "access-token": authToken,
          Authorization: `Bearer ${authToken}`,
        },
      });
      return response.data.data;
    } catch (err) {
      rejectWithValue(err.message);
    }
  },
);

export const getAllProducts = createAsyncThunk(
  "products/getAllProducts",
  async (data, { rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      if (!authToken) {
        throw new Error("User not logged in");
      }

      const url =
        process.env.REACT_APP_JAVA_APP_API_PATH +
        APIConstant.PRODUCT_BASE_URL +
        APIConstant.GET_ALL_PRODUCT_OF_USER +
        `?offset=${data.offset}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&userId=${data.userId}`;

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

const generalData = createSlice({
  name: "generalData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(getCategories.rejected, (state) => {
        state.categories = [];
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.products = action.payload.data.totalElements;
        state.loading = false;
      })
      .addCase(getAllProducts.pending, (state) => {
        state.products = "";
        state.loading = true;
      })
      .addCase(getAllProducts.rejected, (state) => {
        state.products = "";
        state.loading = false;
      })
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.departments = action.payload;
      })
      .addCase(getDepartments.rejected, (state) => {
        state.departments = [];
      })
      .addCase(getLocation.fulfilled, (state, action) => {
        state.location = action.payload;
      })
      .addCase(getLocation.rejected, (state) => {
        state.location = [];
      })
      .addCase(getTimezone.fulfilled, (state, action) => {
        state.timezone = action.payload;
      })
      .addCase(getTimezone.rejected, (state) => {
        state.timezone = [];
      });
  },
});

export default generalData.reducer;
