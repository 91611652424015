import { Result } from "antd";
import React from "react";
import NoItem from "../../images/LandingPage/no-item-found.png";

const NoItemFound = () => {
  return (
    <Result
      icon={<img src={NoItem} alt="no-item" />}
      subTitle="You don’t have any results here! Please search something."
    />
  );
};

export default NoItemFound;
