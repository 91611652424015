import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";

import "./assets/fonts/DM_Sans/DMSans-Regular.ttf";
import store from "./store";
import themeConfig from "./config/ThemeConfig";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider theme={themeConfig}>
          <App />
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
    <ToastContainer autoClose={800} />
  </>,
);

reportWebVitals();
