import SignInForm from "./SigninForm";
import RegisterFlowLayout from "../Layout/RegisterFlowLayout";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import classes from "./Signin.module.css";

const SignIn = () => {
  return (
    <div className={classes.container}>
      <RegisterFlowLayout title={"Sign In"}>
        <SignInForm />
        <Row>
          <Col span={24}>
            <p className={classes.terms}>
              By continuing you agree to our{" "}
              <Link to="/public/terms">Terms of Usage</Link> and{" "}
              <Link to="/public/privacy">Privacy Policy</Link>
            </p>
          </Col>
          <Col span={24}>
            <p className={classes.terms}>
              Don’t have an account? <Link to="/public/signup">Sign Up</Link>
            </p>
          </Col>
        </Row>
      </RegisterFlowLayout>
    </div>
  );
};

export default SignIn;
