//CP= Create Profile
const FileValidationConstant = {
  ALLOWED_FILE_TYPE_LOGO_CP_RETAILER_FORM1: [".jpg", ".jpeg", ".png"], // Allowed file extensions
  ALLOWED_FILE_SIZE_LOGO_CP_RETAILER_FORM1: 1 * 1024 * 1024, // 1MB

  ALLOWED_FILE_TYPE_NDA_CP_RETAILER_FORM3: [".jpg", ".jpeg", ".png", ".pdf"], // Allowed file extensions
  ALLOWED_FILE_SIZE_NDA_CP_RETAILER_FORM3: 5 * 1024 * 1024, // 5MB
  ALLOWED_FILE_TYPE_BRANDLOGO_CP_RETAILER_FORM3: [
    ".jpg",
    ".jpeg",
    ".png",
    ".pdf",
  ], // Allowed file extensions
  ALLOWED_FILE_SIZE_BRANDLOGO_CP_RETAILER_FORM3: 5 * 1024 * 1024, // 5MB

  ALLOWED_FILE_TYPE_ARTWORKREQ_CP_RETAILER_FORM4: [".pdf", ".docx", ".doc"], // Allowed file extensions
  ALLOWED_FILE_SIZE_ARTWORKREQ_CP_RETAILER_FORM4: 5 * 1024 * 1024, // 5MB

  ALLOWED_FILE_TYPE_LOGO_CP_SUPPLIER_FORM1: [".jpg", ".jpeg", ".png"], // Allowed file extensions
  ALLOWED_FILE_SIZE_LOGO_CP_SUPPLIER_FORM1: 1 * 1024 * 1024, // 1MB

  ALLOWED_FILE_TYPE_LOGO_CP_FORM2: [".jpg", ".jpeg", ".png"], // Allowed file extensions
  ALLOWED_FILE_SIZE_LOGO_CP_FORM2: 1 * 1024 * 1024, // 1MB

  ALLOWED_FILE_TYPE_AUDIT_CP_SUPPLIER_FORM3: [".jpg", ".jpeg", ".png", ".pdf"], // Allowed file extensions
  ALLOWED_FILE_SIZE_AUDIT_CP_SUPPLIER_FORM3: 5 * 1024 * 1024, // 5MB
  ALLOWED_FILE_TYPE_COI_CP_SUPPLIER_FORM3: [".jpg", ".jpeg", ".png", ".pdf"], // Allowed file extensions
  ALLOWED_FILE_SIZE_COI_CP_SUPPLIER_FORM3: 5 * 1024 * 1024, // 5MB
  ALLOWED_FILE_TYPE_OTHER_CP_SUPPLIER_FORM3: [".jpg", ".jpeg", ".png", ".pdf"], // Allowed file extensions
  ALLOWED_FILE_SIZE_OTHER_CP_SUPPLIER_FORM3: 5 * 1024 * 1024, // 5MB

  ALLOWED_FILE_TYPE_PRODUCTCATALOG_CP_SUPPLIER_FORM4: [".pdf", ".docx", ".doc"], // Allowed file extensions
  ALLOWED_FILE_SIZE_PRODUCTCATALOG_CP_SUPPLIER_FORM4: 5 * 1024 * 1024, // 5MB
  ALLOWED_FILE_TYPE_ARTWORKREQ_CP_SUPPLIER_FORM4: [".pdf", ".docx", ".doc"], // Allowed file extensions
  ALLOWED_FILE_SIZE_ARTWORKREQ_CP_SUPPLIER_FORM4: 5 * 1024 * 1024, // 5MB

  ALLOWED_FILE_TYPE_ADD_PRODUCT: [".jpg", ".jpeg", ".png", ".mp4"], // Allowed file extensions
  ALLOWED_FILE_SIZE_ADD_PRODUCT: 10 * 1024 * 1024, // 10MB
};

export default FileValidationConstant;
