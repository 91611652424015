import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Row, Skeleton, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCategoryAndProductInformation } from "../../../services/MyProfileService";
import classes from "../ProfileInfoTabs.module.css";

const avgTime = {
  1: "30 Days",
  2: "60 Days",
  3: "90 Days",
  4: "120 Days",
};

const edi = {
  [true]: "Yes",
  [false]: "No",
};
const minOrderVal = {
  1: "Case Pack",
  2: "Units",
};

const CategoryAndProductInfoData = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [coiArr, setCOIArr] = useState([]);
  const [auditReportArr, setAuditReportArr] = useState([]);
  const [ndaArr, setNdaArr] = useState([]);
  const [otherCertiArr, setOtherCertiArr] = useState([]);
  const [privateBrandsArr, setPrivateBrandsArr] = useState([]);
  const [infoData, setInfoData] = useState([]);
  const userObj = useSelector((state) => state.user.data);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await getCategoryAndProductInformation({ userId: auth.id });
      if (res && res.data) {
        const data = res.data;

        const infoData = Object.entries(data)?.flatMap(([name, value]) => {
          let mappedName = name; // Default value for name

          if (name === "averageTimeRequire") {
            mappedName =
              "Average Print, Pack, and Ship Time requirement after receiving PO";
            value = avgTime[value];
          } else if (name === "certificateOfInsurancePath") {
            if (value === null) {
              return []; // Skip this element
            }
            const updatedArray = [value];
            setCOIArr(updatedArray);
            return [];
          } else if (name === "edi" && value !== undefined) {
            if (value === null) return [];
            mappedName =
              "EDI (Electronic Data Interchange) Compliance Required";
            value = edi[value];
          } else if (name === "createdAt") {
            return [];
          } else if (name === "updatedAt") {
            return [];
          } else if (name === "otherLink" && value !== undefined) {
            if (value === null || value === "") return [];
            mappedName = "Other Link";
          } else if (name === "documentAndForumPath" && value === null) {
            return [];
          } else if (name === "EDI") {
            return [];
          } else if (name === "deletedDate" && value !== undefined) {
            if (value === null || value === "") return [];
            mappedName = "Deleted Date";
          } else if (name === "factoryAuditReportPath") {
            if (
              value ===
              `${process.env.REACT_APP_S3_URL}/user-profile/factory-audit-report/null`
            ) {
              return []; // Skip this element
            }
            const updatedArray = [value];
            setAuditReportArr(updatedArray);
            return []; // Skip this element
          } else if (name === "minimumOrder") {
            mappedName = "Category Minimum Order Quantity Requirement";
            value = minOrderVal[value];
          } else if (name === "NDAPath") {
            if (
              value ===
                `${process.env.REACT_APP_S3_URL}/user-profile/NDA/null` ||
              null
            ) {
              return [];
            }
            const updatedArray = [value];
            setNdaArr(updatedArray);
            return [];
          } else if (name === "NDAPath" || name === "ndapath") {
            if (
              value ===
                `${process.env.REACT_APP_S3_URL}/user-profile/NDA/null` ||
              null
            ) {
              return [];
            }
            const updatedArray = [value];
            setNdaArr(updatedArray);
            return [];
          } else if (name === "otherCertificatePath") {
            if (
              value ===
                `${process.env.REACT_APP_S3_URL}/user-profile/other-certificate/${userObj.id}/null` ||
              null
            ) {
              return []; // Skip this element
            }
            if (Array.isArray(value)) {
              setOtherCertiArr(value);
            } else {
              setOtherCertiArr([value]);
            }
            return []; // Skip this element
          } else if (name === "pickupDetails") {
            mappedName = "Pickup Details";
          } else if (name === "pickupPoints") {
            if (userObj.user_type === "RETAILER") {
              mappedName = "Shipping";
            }
            mappedName = "Pickup Points";
          } else if (name === "pickupRequired") {
            mappedName = "Pickup Required";
            if (value === 1) {
              value = "Yes";
            } else if (value === 2) {
              value = "No";
            } else {
              value = "Maybe";
            }
          } else if (name === "workWithAllowance") {
            mappedName = "Work with allowance program";
            value = value ? "Yes" : "No";
          } else if (name === "workWithDeadnet") {
            mappedName = "Work with deadnet";
            value = value ? "Yes" : "No";
          } else if (name === "privateBrand") {
            mappedName = "Private Brands";
            setPrivateBrandsArr(value);
            return []; // Skip this element
          }

          return {
            name: mappedName,
            data: value,
          };
        });

        setInfoData(infoData);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const infoCmp = infoData.map((item, index) => (
    <Col
      xs={24}
      md={24}
      lg={12}
      xl={12}
      xxl={12}
      className={classes["data-item"]}
      key={`info-list-${item.name}-${index}`}
    >
      <Title level={5} className={classes.itemHeading}>
        {item.name}
      </Title>
      <p className={classes.itemData}>{item.data}</p>
    </Col>
  ));

  const handleDownload = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const coiCmp = coiArr.map((item, index) => {
    const startString = "certificate-of-insurance/";
    const endString = "-T-";
    const startIndex = item.indexOf(startString) + startString.length;
    const endIndex = item.indexOf(endString);
    const fileExtension = item.split(".").pop();
    const item1 = item.substring(startIndex, endIndex) + "." + fileExtension;
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ margin: "5px 0" }}
        key={`coi-${index}`}
      >
        <Typography.Text>{item1}</Typography.Text>
        <Button onClick={() => handleDownload(item)}>
          Download <DownloadOutlined />
        </Button>
      </div>
    );
  });

  // If coiArr has valid items, coiCmp will be rendered, otherwise, it won't render anything.
  const auditReportCmp = auditReportArr.map((item, index) => {
    const startString = "factory-audit-report/";
    const endString = "-T-";
    const startIndex = item?.indexOf(startString) + startString.length;
    const endIndex = item?.indexOf(endString);
    const fileExtension = item?.split(".")?.pop();
    const item1 = item?.substring(startIndex, endIndex) + "." + fileExtension;

    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ margin: "5px 0" }}
        key={`audit-report-${index}`}
      >
        <Typography.Text>{item1}</Typography.Text>
        <Button onClick={() => handleDownload(item)}>
          Download <DownloadOutlined />
        </Button>
      </div>
    );
  });

  const ndaCmp = ndaArr.map((item, index) => {
    const startString = "NDA/";
    const endString = "-T-";
    const startIndex = item?.indexOf(startString) + startString.length;
    const endIndex = item?.indexOf(endString);
    const fileExtension = item?.split(".")?.pop();
    const item1 = item?.substring(startIndex, endIndex) + "." + fileExtension;
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ margin: "5px 0" }}
        key={`nda-${index}`}
      >
        <Typography.Text>{item1}</Typography.Text>
        <Button onClick={() => handleDownload(item)}>
          Download <DownloadOutlined />
        </Button>
      </div>
    );
  });

  const otherCertificateCmp = otherCertiArr?.map((item, index) => {
    const startString = "other-certificate/" + userObj.id + "/";
    const endString = "-T-";
    const startIndex = item?.indexOf(startString) + startString.length;
    const endIndex = item?.indexOf(endString);
    const fileExtension = item?.split(".")?.pop();
    const item1 = item?.substring(startIndex, endIndex) + "." + fileExtension;

    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ margin: "5px 0" }}
        key={`other-certificate-${index}`}
      >
        <Typography.Text>{item1}</Typography.Text>
        <Button
          onClick={() => handleDownload(item)}
          style={{ marginTop: "3px", marginBottom: "3px" }}
        >
          Download <DownloadOutlined />
        </Button>
      </div>
    );
  });

  const privateBrandsCmp = privateBrandsArr?.map((item, index) => (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ margin: "5px 0" }}
      key={`pri-brand-${index}`}
    >
      <Typography.Text>{item?.name}</Typography.Text>
      <Button onClick={() => handleDownload(item?.logoPath)}>
        Download <DownloadOutlined />
      </Button>
    </div>
  ));

  return loading ? (
    <>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
    </>
  ) : infoData?.length !== 0 ? (
    <Row gutter={12} className={classes["data-container"]}>
      {infoCmp}

      {coiArr?.length > 0 && coiArr[0] !== null && (
        <Col span={24} className={classes["data-item"]}>
          <Title level={5} className={classes.itemHeading}>
            Certificate Of Insurance
          </Title>
          {coiCmp}
        </Col>
      )}
      {auditReportArr?.length > 0 && auditReportArr[0] !== null && (
        <Col span={24} className={classes["data-item"]}>
          <Title level={5} className={classes.itemHeading}>
            Audit report
          </Title>
          {auditReportCmp}
        </Col>
      )}
      {ndaArr?.length > 0 && ndaArr[0] !== null && (
        <Col span={24} className={classes["data-item"]}>
          <Title level={5} className={classes.itemHeading}>
            NDA
          </Title>
          {ndaCmp}
        </Col>
      )}
      {otherCertiArr?.length > 0 && otherCertiArr[0] !== null && (
        <Col span={24} className={classes["data-item"]}>
          <Title level={5} className={classes.itemHeading}>
            Other Certificates
          </Title>
          {otherCertificateCmp}
        </Col>
      )}
      {privateBrandsArr?.length !== 0 && (
        <Col span={24} className={classes["data-item"]}>
          <Title level={5} className={classes.itemHeading}>
            Private Brands
          </Title>
          {privateBrandsCmp}
        </Col>
      )}
    </Row>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="Your profile is incomplete. Click below button to complete your profile."
    >
      <Button type="primary" onClick={() => navigate("/app/createprofile")}>
        Complete Now
      </Button>
    </Empty>
  );
};

export default CategoryAndProductInfoData;
