import React, { useCallback, useEffect, useMemo } from "react";
import classes from "./ChatMessages.module.css";
import { Divider, Empty } from "antd";
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../services/firebase";
import {
  addTimelineInMessages,
  getMessagesFromSnapShot,
  getNewMessage,
} from "../../utils/chat.helper";

import ChatMessageHeader from "./MessagesComponents/Header";
import ChatMessageInput from "./MessagesComponents/Input";
import ChatMessageList from "./MessagesComponents/List";

const ChatMessageSection = (props) => {
  const {
    activeChat,
    chatMessages,
    chatMessageLoading,
    setMessages,
    updateMessage,
  } = props;
  const id = localStorage.getItem("id");
  const anotherUser = activeChat?.users?.find(
    (user) => Number(user.id) !== Number(id),
  );
  const lastMsg = activeChat
    ? chatMessages[chatMessages.length - 1]?.createdAt
    : null;

  const getInitialMessages = useCallback(
    async (mq) => {
      try {
        const snapshot = await getDocs(mq);
        const list = await getMessagesFromSnapShot(snapshot);
        const messages = await addTimelineInMessages(list);
        setMessages(messages);
      } catch (err) {
        console.log("err on chat messages--", err);
      }
    },
    [setMessages],
  );
  const startListenerForMessages = useCallback(
    (mq) => {
      let initialCall = true;
      return onSnapshot(mq, (snapshot) => {
        if (initialCall) {
          initialCall = false;
          return;
        }
        let newMsg;
        snapshot.docChanges()?.forEach(async (changes) => {
          try {
            newMsg = await getNewMessage(changes);
            if (newMsg) updateMessage(newMsg);
          } catch (err) {
            throw new Error(err);
          }
        });
      });
    },
    [updateMessage],
  );

  useEffect(() => {
    let unSubscribe = () => {};
    if (activeChat?.chatId) {
      const mq = query(
        collection(db, "messages", activeChat?.chatId, "list"),
        orderBy("createdAt"),
      );

      getInitialMessages(mq);
      unSubscribe = startListenerForMessages(mq);
    }
    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat]);

  const messageHeader = useMemo(
    () => (
      <ChatMessageHeader
        id={activeChat?.id}
        anotherUser={anotherUser}
        lastMsgTime={lastMsg}
      />
    ),
    [activeChat?.id, anotherUser, lastMsg],
  );
  const messageList = useMemo(
    () => (
      <ChatMessageList
        activeChatId={activeChat?.id}
        chatMessages={chatMessages}
        chatMessageLoading={chatMessageLoading}
      />
    ),
    [activeChat?.id, chatMessageLoading, chatMessages],
  );
  const messageInput = useMemo(
    () => (
      <ChatMessageInput
        sender={id}
        receiver={anotherUser?.id}
        chatDocId={activeChat?.id}
        chatId={activeChat?.chatId}
      />
    ),
    [activeChat?.chatId, activeChat?.id, anotherUser?.id, id],
  );

  return activeChat?.id ? (
    <>
      {messageHeader}
      <Divider className={classes["divider"]} />
      {messageList}
      {messageInput}
    </>
  ) : (
    <div className={classes["chat-message-empty-container"]}>
      <Empty
        rootClassName={classes["chat-message-empty"]}
        description="No Item Selected."
      />
    </div>
  );
};

export default ChatMessageSection;
