const APIConstant = {
  ADMIN_BASE_URL: "/admin",
  RETAILER_BASE_URL: "/retailer",
  SUPPLIER_BASE_URL: "/supplier",
  USER_BASE_URL: "/user",
  DEPARTMENT_BASE_URL: "/department",
  CATEGORY_BASE_URL: "/category",
  PRODUCT_BASE_URL: "/product",
  COUNTRY_BASE_URL: "/country",
  TIMEZONE_BASE_URL: "/timezone",
  BANNER_BASE_URL: "/banner",
  STRIPE_BASE_URL: "/subscriptions",
  AUTH_BASE_URL: "/auth",

  REGISTER_USER: "/register",
  LOGIN_USER: "/login",

  SEND_OTP: "/send-otp",
  VERIFY_OTP: "/verify-otp",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  CHANGE_PASSWORD: "/change-password",
  DELETE_ACCOUNT: "/delete-account",

  UPDATE_PROFILE: "/profile",
  COMPANY_DETAILS: "/company",
  MEMBER_DETAILS: "/member",
  CATEGORY_PRODUCT_DETAILS: "/product",
  ARTWORK_DETAILS: "/artwork",
  QUALITY_ASSURANCE_DETAILS: "/qa",
  GET_PROFILE_PERCENTAGE: "/percentage",
  DELETE_FILE: "/delete-file",
  DELETE_BRAND: "/delete-brand",

  GET_ALL_DEPARTMENT: "/all",
  GET_ALL_CATEGORY: "/all",
  GET_ALL_COUNTRY: "/all",
  GET_ALL_TIMEZONE: "/timezones",
  GET_ALL_CATEGORY_OF_DEPARTMENT: "/get-all-category-department-id",
  GET_DEPARTMENT_BY_ID: "/specific",

  ADD_PRODUCT: "/add",
  GET_ALL_PRODUCT_OF_USER: "/all",
  DELETE_PRODUCT: "/delete",

  //for searching start
  SEARCH_USER: "/search",
  ADD_TO_MY_NETWORK: "/add-to-network",

  //for banner data get start
  GET_ALL_BANNER_BY_LOCATION: "/location",
  //for banner data get end

  //for stripe start
  ALL_SUBSCRIPTION: "/allSubscriptions",
  CANCEL_SUBSCRIPTION: "/cancelSubscription",
  CUSTOMERS: "/customers",
  UPGRADE_SUBSCRIPTION: "/upgradeSubscription",
  GET_SUBSCRIPTION: "/get",
  CUSTOMER: "/customer",
  DETAILS: "/details",
  //for stripe end

  //for send and verify email start
  SEND_VERIFICATION_EMAIL: "/send-verify-email",
  VERIFY_EMAIL: "/verify-email",
  //for send and verify email end

  GET_ALL_STATIC_PAGE: "/get-all-static-page",
};

export default APIConstant;
