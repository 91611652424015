import { Button, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { unsubscribeNewsletter } from "../../services/GeneralService"; // Import the unsubscribe service
import classes from "./UnsubscribeNewsletter.module.css"; // Import your CSS module

const UnsubscribeNewsletter = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    unsubscribeFunction();
  }, [params?.email]);

  const unsubscribeFunction = async () => {
    try {
      setLoading(true);
      const res = await unsubscribeNewsletter(params.email);
      console.log(res);
      setData(res.success);
    } catch (error) {
      console.error("Error unsubscribing:", error);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        {!loading ? (
          <div className="d-flex flex-column align-items-center">
            <Result
              status={data ? "success" : "error"}
              title={
                data ? "Successfully Unsubscribed!" : "Unsubscription Failed"
              }
              subTitle={
                data
                  ? "You have been unsubscribed from our newsletter."
                  : "Please try again later."
              }
            />
            <Button
              onClick={() => {
                navigate("/");
              }}
            >
              Go To Pribr
            </Button>
          </div>
        ) : (
          <Spin tip="Processing Unsubscription..." size="large" />
        )}
      </div>
    </div>
  );
};

export default UnsubscribeNewsletter;
