import classes from "../ProfileInfoTabs.module.css";
import { useSelector } from "react-redux";
import { Button, Col, Empty, Row, Skeleton } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { getQualityAssuranceInformation } from "../../../services/MyProfileService";
import { useNavigate } from "react-router-dom";

const type = {
  qaContact: "Primary contact for QA",
  qaEmail: "Email",
  qaLab: "Labs you work with",
  // qaLabName: "Added Lab",
  sourcingPartners: "Sourcing Partners",
};

const QualityAssuranceInfoData = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const [infoData, setInfoData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await getQualityAssuranceInformation({ userId: auth.id });
      if (res && res.data) {
        const data = res.data;

        const filteredData = Object.entries(data)
          .filter(([key]) => key in type)
          .map(([key, value]) => {
            return {
              name: type[key],
              data: value,
            };
          });

        setInfoData(filteredData);
      }
    } catch (err) {
      console.error("Error fetching QA information:", err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const infoCmp = infoData.map((item, index) => (
    <Col
      xs={24}
      md={24}
      lg={12}
      xl={12}
      xxl={12}
      className={classes["data-item"]}
      key={`list-item-${item.name}-${index}`}
    >
      <Title level={5} className={classes.itemHeading}>
        {item.name}
      </Title>
      <p className={classes.itemData}>{item.data}</p>
    </Col>
  ));

  return loading ? (
    <>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
    </>
  ) : infoData.length !== 0 ? (
    <Row gutter={12} className={classes["data-container"]}>
      {infoCmp}
    </Row>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="Your profile is incomplete. Click below button to complete your profile."
    >
      <Button type="primary" onClick={() => navigate("/app/createprofile")}>
        Complete Now
      </Button>
    </Empty>
  );
};

export default QualityAssuranceInfoData;
