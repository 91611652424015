import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Row, Skeleton, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getArtworkInformation } from "../../../services/MyProfileService";
import classes from "../ProfileInfoTabs.module.css";

const artworkVal = {
  1: "Full InHouse label design",
  2: "Through our Ad Agency",
  3: "Basic InHouse capabilities",
  4: "Require vendors/suppliers to provide artwork",
};
const artworkCostVal = {
  1: "Retailer covers artwork cost",
  2: "Retailer requires vendors to cover for artwork cost",
  3: "Retailer shares artwork cost",
  4: "Retailer may share some artwork cost, depending on the order",
};

const ArtworkInfoData = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [infoData, setInfoData] = useState([]);
  const [artworkRequirementArr, setArtworkRequirementArr] = useState([]);
  const [productCatalogArr, setProductCatalogArr] = useState([]);
  const userObj = useSelector((state) => state.user.data);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await getArtworkInformation({ userId: auth.id });
      if (res && res.data) {
        const data = res.data;

        const infoData = Object.entries(data).flatMap(([name, value]) => {
          let mappedName = name; // Default value for name

          // Conditionally update mappedName based on the desired condition
          if (name === "adAgencyName") {
            if (value === null || value === "") return [];
            mappedName = "Agency Name";
          } else if (name === "artworkContact") {
            if (value === null || value === "") return [];
            mappedName = "Primary contact for Artwork";
          } else if (name === "artworkEmail") {
            if (value === null || value === "") return [];
            mappedName = "Email";
          } else if (name === "createdAt") {
            return [];
          } else if (name === "updatedAt") {
            return [];
          } else if (name === "artworkLabel") {
            mappedName = "Artwork and Label Design Capabilities";
            const valueArray = value.split(",");
            const labelsArray = valueArray.map((val) => {
              if (val) {
                return artworkVal[val.trim()];
              } else {
                return "Unknown value"; // Or handle any other default case if needed
              }
            });
            value = labelsArray.join(", ");
          } else if (name === "artworkRequirements") {
            if (userObj.user_type === "RETAILER") {
              mappedName = "Retailer is able to provide artwork requirements";
            } else {
              mappedName = "Supplier is able to provide artwork requirements";
            }

            if (value) {
              value = "Yes";
            } else {
              value = "No";
            }
          } else if (name === "artworkRequirementPath") {
            mappedName = "Artwork Requirement";
            if (
              // eslint-disable-next-line eqeqeq
              value ==
              `${process.env.REACT_APP_S3_URL}/user-profile/artwork-req/null`
            ) {
              return []; // Skip this element
            }
            const updatedArray = [value];
            setArtworkRequirementArr(updatedArray);
            return []; // Skip this element
          } else if (name === "dielinesPath") {
            mappedName = "Dielines";
            return []; // Skip this element
          } else if (name === "printerSpecsPath") {
            mappedName = "Printer Specs";
            return []; // Skip this element
          } else if (name === "deletedDate" && value !== undefined) {
            if (value === null || value === "") return [];
            mappedName = "Deleted Date";
          } else if (name === "productCatalogPath") {
            if (
              // eslint-disable-next-line eqeqeq
              value === null
            ) {
              return []; // Skip this element
            } else {
              mappedName = "Full Product Catalog";
            }
            if (Array.isArray(value)) {
              setProductCatalogArr(value);
            } else {
              setProductCatalogArr([value]);
            }
            return []; // Skip this element
          }
          //for retailers start
          else if (name === "artworkCostPreference") {
            if (value === null || value === "") return [];
            mappedName = "Artwork Cost Preference";
            value = artworkCostVal[value];
          }
          //for retailers end

          return {
            name: mappedName,
            data: value,
          };
        });

        setInfoData(infoData);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const infoCmp = infoData.map((item, index) => (
    <Col
      xs={24}
      md={24}
      lg={12}
      xl={12}
      xxl={12}
      className={classes["data-item"]}
      key={`info-list-${item.name}-${index}`}
    >
      <Title level={5} className={classes.itemHeading}>
        {item.name}
      </Title>
      <p className={classes.itemData}>{item.data}</p>
    </Col>
  ));

  const handleDownload = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const productCatalogCmp =
    productCatalogArr[0] !== null &&
    productCatalogArr.map((item, index) => {
      const startString = "product-catalog/" + userObj?.id + "/";
      const endString = "-T-";
      const startIndex = item?.indexOf(startString) + startString?.length;
      const endIndex = item?.indexOf(endString);
      const fileExtension = item?.split(".")?.pop();
      const item1 = item?.substring(startIndex, endIndex) + "." + fileExtension;
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ margin: "5px 0" }}
          key={`other-certificate-${index}`}
        >
          <Typography.Text>{item1}</Typography.Text>
          <Button onClick={() => handleDownload(item)}>
            Download <DownloadOutlined />
          </Button>
        </div>
      );
    });

  const artworkRequirementCmp = artworkRequirementArr.map((item, index) => {
    const startString = "artwork-req/";
    const endString = "-T-";
    const startIndex = item?.indexOf(startString) + startString.length;
    const endIndex = item?.indexOf(endString);
    const fileExtension = item?.split(".")?.pop();
    const item1 = item?.substring(startIndex, endIndex) + "." + fileExtension;
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ margin: "5px 0" }}
        key={`artwork-req-${index}`}
      >
        <Typography.Text>{item1}</Typography.Text>
        <Button onClick={() => handleDownload(item)}>
          Download <DownloadOutlined />
        </Button>
      </div>
    );
  });

  return loading ? (
    <>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
    </>
  ) : infoData?.length !== 0 ? (
    <Row gutter={12} className={classes["data-container"]}>
      {infoCmp}

      {productCatalogArr?.length !== 0 && (
        <Col span={24} className={classes["data-item"]}>
          <Title level={5} className={classes.itemHeading}>
            Product Catalog
          </Title>
          {productCatalogCmp}
        </Col>
      )}
      {artworkRequirementArr?.length !== 0 ||
        (null && (
          <Col span={24} className={classes["data-item"]}>
            <Title level={5} className={classes.itemHeading}>
              Artwork Requirement
            </Title>
            {artworkRequirementCmp}
          </Col>
        ))}
    </Row>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="Your profile is incomplete. Click below button to complete your profile."
    >
      <Button type="primary" onClick={() => navigate("/app/createprofile")}>
        Complete Now
      </Button>
    </Empty>
  );
};

export default ArtworkInfoData;
