import {
  LoadingOutlined,
  PaperClipOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Button, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  addDoc,
  collection,
  doc,
  increment,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../../../services/firebase";
import { getUserPlan } from "../../../services/GeneralService";
import classes from "./Input.module.css";

const ChatMessageInput = ({ sender, receiver, chatId, chatDocId }) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [userPlan, setUserPlan] = useState(1);
  const auth = useSelector((state) => state.auth);

  async function fetchUserPlan() {
    try {
      const userId = auth?.id;
      const newplanid = await getUserPlan({ userId });
      if (newplanid.data) {
        setUserPlan(newplanid.data.plan.id);
      }
    } catch (error) {
      console.error("Error fetching user plan:", error);
    }
  }

  useEffect(() => {
    fetchUserPlan();
  });

  const onSubmit = async () => {
    try {
      if (!chatId || !sender || !receiver) {
        throw new Error("Error happened. Reload the page.");
      }
      setLoading(true);
      let data = {
        text: message,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        isRead: false,
        messageType: "text",
        mediaUrl: "",
        chatId,
        receiver,
        sender,
      };
      await addDoc(collection(db, "messages", chatId, "list"), data);
      delete data.isRead;
      await updateDoc(doc(db, "chats", chatDocId), {
        lastMessage: data,
        updatedAt: serverTimestamp(),
        [`unread_${receiver}`]: increment(1),
      });
      setMessage("");
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <form
      className={classes["chat-message-footer"]}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <TextArea
        suffix={<PaperClipOutlined />}
        placeholder="Type your message here"
        className={classes["chat-message-input"]}
        autoSize
        disabled={userPlan == 1}
        maxLength={255}
        bordered={false}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (
            (e.ctrlKey || e.altKey) &&
            e.key === "Enter" &&
            !loading &&
            message?.trim()
          ) {
            e.preventDefault();
            onSubmit();
          }
        }}
      />
      <Button
        type="primary"
        htmlType="submit"
        size="large"
        icon={
          loading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />}
            />
          ) : (
            <SendOutlined />
          )
        }
        className={classes["chat-message-sent-btn"]}
        disabled={!message?.trim() || loading || userPlan == 1}
      />
    </form>
  );
};

export default ChatMessageInput;
