import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";

const SearchInput = ({ chatList, setList }) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    const id = localStorage.getItem("id");
    if (search?.trim()) {
      const filteredList = chatList.filter((item) =>
        item.users?.some(
          (user) =>
            user?.id !== id &&
            user?.name?.toLowerCase()?.includes(search.trim()?.toLowerCase()),
        ),
      );
      setList(filteredList);
    } else {
      setList(chatList);
    }
  }, [search, chatList, setList]);

  return (
    <Input
      addonBefore={<SearchOutlined />}
      allowClear
      size="large"
      className="custom-search-input"
      placeholder="Search"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
    />
  );
};

export default SearchInput;
