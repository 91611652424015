import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Pagination,
  Row,
  Select,
  Skeleton,
  Space,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import { SearchUser } from "../../services/DashboardService";
import { GetAllCategoryByDepartmentId } from "../../services/GeneralService";
import { getDepartments } from "../../store/slice/generalData_slice";
import Card from "./Card";
import NoItemFound from "./NoItemFound";
import ProfileCompleteNoticeDash from "./ProfileCompleteNoticeDash";
import classes from "./Suppliers.module.css";
const Suppliers = () => {
  const location = useLocation();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const [departmentData, setDepartmentData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [department, setDepartment] = useState("Department");
  const [category, setCategory] = useState("All Categories");
  const [searchStr, setSearchStr] = useState(location.state || null);
  const [userList, setUserList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateUsers, setUpdateUsers] = useState(false);
  const [loading, setLoading] = useState(false);
  const userObj = useSelector((state) => state.user.data);

  const selection = useSelector((state) => state.flyout);
  const percentage = useSelector((state) => state.percentage.value);
  const departments = useSelector((state) => state.generalData.departments);
  const showbadge = percentage != 100;
  const auth = useSelector((state) => state.auth);
  const [selectedUserType, setSelectedUserType] = useState("User Type");
  // const userType = userObj.user_type == "SUPPLIER" ? "RETAILER" : "SUPPLIER";
  const userTypeOptions = [
    { label: "Supplier", value: "SUPPLIER" },
    { label: "Retailer", value: "RETAILER" },
  ];

  let searchDTO = {
    // userType: userType,
    key: location.state || "",
    forNetwork: location.pathname === "/app/dashboard",
  };

  const handleUserTypeChange = (value) => {
    setSelectedUserType(value);
    setCurrentPage(1); // Optionally reset to the first page

    // Build the updated search parameters
    const updatedSearchDTO = {
      ...buildSearchDTO(),
      userType: value, // Update userType with the selected value
      pageNumber: 1, // Reset to first page
    };

    // Trigger the search with the updated parameters
    searchUserFunction(updatedSearchDTO, true);
  };

  const buildSearchDTO = () => {
    const dto = {
      userType: selectedUserType || "",
      key: searchStr || "",
      forNetwork: location.pathname === "/app/dashboard",
      userId: userObj.id || auth?.id,
      pageNumber: currentPage,
    };

    if (department !== "Department") {
      dto.departmentId = department;
    }

    if (category !== "All Categories") {
      dto.categoryId = category;
    }

    return dto;
  };

  searchDTO.userId = userObj.id || auth?.id;

  useEffect(() => {
    setSearchStr(location?.state);
  }, [location]);

  useEffect(() => {
    if (!departments?.length) {
      dispatch(getDepartments());
    } else {
      const response = departments?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setDepartmentData(response);
    }
  }, [departments, dispatch]);

  useEffect(() => {
    searchDTO.pageNumber = 1;
    searchDTO.userId = userObj.id || auth?.id;
    if (location.pathname === "/app/users") {
      if (selection.departmentId) {
        // if (selection.categoryId) {
        //   // searchDTO.categoryId = selection.categoryId;
        //   console.log("categoryData: ", categoryData);
        //   const selectedCategoryUser = categoryData.find(
        //     (category) => category.value == selection.categoryId,
        //   );
        //   console.log(selectedCategoryUser);

        //   if (
        //     selectedCategoryUser &&
        //     selectedCategoryUser.label.startsWith("All")
        //   ) {
        //     delete searchDTO.categoryId;
        //   } else {
        //     searchDTO.categoryId = selection.categoryId;
        //   }
        //   console.log("searchDTO.categoryId: ", searchDTO.categoryId);
        // }
        setDepartment(selection.departmentId);
        handleDepartmentChange(selection.departmentId);
        setCategory(selection.categoryId);

        searchDTO.departmentId = selection.departmentId;
      }
    } else if (searchDTO.key !== "") {
      searchUserFunction(searchDTO, true);
    } else {
      searchUserFunction(searchDTO, true);
    }
  }, [selection, updateUsers]);

  const searchUserFunction = async (dto, bSetPageNo) => {
    try {
      setLoading(true);
      const res = await SearchUser(dto);
      if (res?.data) {
        // const filteredUsers = res.data.data.filter((user) => user.inNetwork);
        //  setUserList(filteredUsers);

        // setUserList(filteredUsers);
        // setUserList(res?.data?.data);
        let filteredUsers = res.data.data;
        if (searchStr?.trim()) {
          filteredUsers = filteredUsers.filter((user) => user.inNetwork);
        }

        setUserList(filteredUsers);
        if (bSetPageNo) {
          setTotalPages(res?.data?.totalPages - 1);
        }
      } else {
        throw new Error(res?.message);
      }
    } catch (error) {
      console.error("Error searching users:", error);
      setUserList([]);
    } finally {
      setLoading(false);
    }
  };

  async function handleDepartmentChange(value) {
    GetAllCategoryByDepartmentId({ departmentId: value })
      .then((res) => {
        if (res?.data && Array.isArray(res.data)) {
          const response = res.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setCategoryData(response);
        } else {
          setCategoryData([]);
        }
      })
      .catch(() => {
        setCategoryData([]);
      });
    setDepartment(value);

    setCategory("All Categories");

    searchDTO.key = searchStr;
    searchDTO.pageNumber = 1;
    searchDTO.departmentId = value;
    searchDTO.userId = userObj.id || auth?.id;

    await searchUserFunction(searchDTO, true);

    delete searchDTO.categoryId;
  }

  function handleSearchChange(event) {
    setSearchStr(event.target.value);
  }

  useEffect(() => {
    searchDTO.pageNumber = 1;
    if (department !== "Department") {
      searchDTO.departmentId = department;
    }
    if (category !== "All Categories") {
      searchDTO.categoryId = category;
    }
    searchDTO.userId = userObj.id || auth?.id;
    if (searchStr?.trim()) {
      let timerOut = setTimeout(() => {
        searchDTO.key = searchStr;

        searchUserFunction(searchDTO, true);
      }, 1500);

      return () => clearTimeout(timerOut);
    } else if (searchStr !== null) {
      searchDTO.key = "";
      searchUserFunction(searchDTO, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStr]);

  function handleCategoryChange(value) {
    setCategory(value);
    searchDTO.key = searchStr;
    searchDTO.pageNumber = 1;
    searchDTO.departmentId = department;
    // searchDTO.categoryId = value;
    searchDTO.userId = userObj.id || auth?.id;
    const selectedCategory = categoryData.find(
      (category) => category.value == value,
    );

    if (selectedCategory && selectedCategory.label.startsWith("All")) {
      delete searchDTO.categoryId;
    } else {
      searchDTO.categoryId = value;
    }
    searchUserFunction(searchDTO, true);
  }

  const handleEnterSearch = () => {
    //search api call start
    searchDTO.key = searchStr;
    searchDTO.pageNumber = 1;
    searchDTO.userId = userObj.id || auth?.id;
    searchDTO.forNetwork = true;
    if (department !== "Department") {
      searchDTO.departmentId = department;
    }
    if (category !== "All Categories") {
      searchDTO.categoryId = category;
    }

    searchUserFunction(searchDTO, true);
    //search api call end
  };

  const handlePageChanged = (pageNo) => {
    setCurrentPage(pageNo);
    searchDTO.key = searchStr;
    searchDTO.userId = userObj.id || auth?.id;
    if (department !== "Department") {
      searchDTO.departmentId = department;
    }
    if (category !== "All Categories") {
      searchDTO.categoryId = category;
    }
    searchDTO.pageNumber = pageNo;

    searchUserFunction(searchDTO, false);
  };

  const clearFilter = () => {
    setSearchStr("");
    setDepartment("Department");
    setCategory("All Categories");
    setSelectedUserType("User Type");
    searchDTO.userType = "";
    searchDTO.key = "";
    searchDTO.pageNumber = 1;
    searchDTO.userId = userObj.id || auth?.id;

    delete searchDTO.categoryId;
    delete searchDTO.departmentId;

    searchUserFunction(searchDTO, true);
  };

  const SkeletonCtrl = (
    <>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
    </>
  );

  return (
    <>
      {width < 1175 && showbadge && (
        <Col className={classes["container2"]}>
          <ProfileCompleteNoticeDash />
        </Col>
      )}
      <Row>
        <Col
          className={`${classes["container"]} ${
            showbadge && width > 1176 ? "w-80" : "w-100"
          }`}
        >
          <Title level={3} className={classes["title"]}>
            Users
          </Title>

          {width < 720 ? (
            <>
              <Space.Compact direction="vertical" className="w-100">
                <Select
                  defaultValue="Department"
                  options={departmentData}
                  size="small"
                  className="responsive-custom-group"
                  onChange={handleDepartmentChange}
                  value={department}
                />
                <Select
                  defaultValue="All Categories"
                  options={categoryData}
                  size="small"
                  className="responsive-custom-group mt-10 mb-10"
                  value={category}
                  onChange={handleCategoryChange}
                />
                <Select
                  placeholder="User Type"
                  options={userTypeOptions}
                  size="small"
                  className="responsive-custom-group mt-10 mb-10"
                  value={selectedUserType}
                  onChange={handleUserTypeChange}
                  allowClear
                />
                {/* <Select
                  defaultValue="All Suppliers"
                  options={options}
                  size="small"
                  className="responsive-custom-group"
                /> */}
              </Space.Compact>
              <Input
                defaultValue="Hangzhou"
                size="small"
                allowClear
                placeholder={
                  location.pathname === "/app/dashboard"
                    ? "Search in my network..."
                    : "Search from global..."
                }
                className="mt-10 responsive-custom-group"
                onPressEnter={handleEnterSearch}
                value={searchStr}
                onChange={handleSearchChange}
              />
              <Button
                type="primary"
                shape="round"
                style={{ width: "100%", marginTop: "8px" }}
                onClick={clearFilter}
              >
                Clear Filter
              </Button>
            </>
          ) : (
            <div className="d-flex align-items-center">
              <Input
                className="custom-group-input"
                addonBefore={
                  <>
                    <Select
                      defaultValue="Department"
                      options={departmentData}
                      className={classes.selectContainer}
                      size="small"
                      onChange={handleDepartmentChange}
                      value={department}
                    />

                    <Select
                      defaultValue="All Categories"
                      options={categoryData}
                      className={classes.selectContainer}
                      size="small"
                      value={category}
                      onChange={handleCategoryChange}
                    />
                    <Select
                      placeholder="User Type"
                      options={userTypeOptions}
                      className="mr-10"
                      size="small"
                      value={selectedUserType}
                      onChange={handleUserTypeChange}
                      allowClear
                    />
                    <SearchOutlined />
                  </>
                }
                defaultValue="Hangzhou"
                size="large"
                allowClear
                onPressEnter={handleEnterSearch}
                value={searchStr}
                onChange={handleSearchChange}
                placeholder={
                  location.pathname === "/app/dashboard"
                    ? "Search in my network..."
                    : "Search from global..."
                }
              />
              <Button
                type="primary"
                shape="round"
                style={{ marginLeft: "10px" }}
                onClick={clearFilter}
              >
                Clear Filter
              </Button>
            </div>
          )}
        </Col>
        {width > 1176 && showbadge && (
          <Col className={classes["container2"] + " w-19"}>
            <ProfileCompleteNoticeDash />
          </Col>
        )}
      </Row>
      <Row className={classes["container"] + " mt-10"}>
        <Col className="w-100">
          {/* <div className="d-flex justify-content-between align-items-center">
            <div className={classes["select-all"]}>
              <Checkbox>Select All</Checkbox>
            </div>
            <Button className={classes["favorite-btn"]} type="primary" disabled>
              Favorite
            </Button>
          </div> */}
          <Row gutter={{ md: 8, sm: 0 }}>
            {!loading
              ? userList.map((user, index) => (
                  <Col
                    sm={24}
                    md={12}
                    lg={8}
                    className="w-100"
                    key={`user-list-${index}`}
                    style={{ marginBottom: "8px" }}
                  >
                    <Card
                      user={user}
                      setUpdateUsers={() => {
                        setUpdateUsers(!updateUsers);
                      }}
                    />
                  </Col>
                ))
              : SkeletonCtrl}
          </Row>
          {userList.length == 0 && !loading && <NoItemFound />}
        </Col>
      </Row>
      {(totalPages == 0 || userList.length < 4) && (
        <div style={{ marginBottom: "228px" }}></div>
      )}
      {totalPages > 0 && (
        <Pagination
          total={totalPages * 12}
          defaultCurrent={currentPage}
          className={classes["pagination"]}
          onChange={handlePageChanged}
        />
      )}
    </>
  );
};

export default Suppliers;
