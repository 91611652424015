import { Button, Modal, Tooltip } from "antd";
import Title from "antd/es/typography/Title";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import AddEditProduct from "./Forms/AddEditProduct";
import classes from "./MyProducts.module.css";
import ProductList from "./ProductList";

const MyProducts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editProduct, setEditProduct] = useState(null);
  const [tempCount, setTempCount] = useState(1);
  const [productCount, setProductCount] = useState(0);
  const [maxProductLimit, setMaxProductLimit] = useState(1);
  const loading = useSelector((state) => state.generalData.loading);
  const handleOk = useCallback(() => {
    setTempCount((prevCount) => prevCount + 1);

    setEditProduct(null);
    setIsModalOpen(false);
  }, []);
  const handleCancel = useCallback(() => {
    setEditProduct(null);
    setIsModalOpen(false);
  }, []);
  const handleOpen = useCallback(() => {
    setEditProduct(null);
    setIsModalOpen(true);
  }, []);
  const handleEditProduct = useCallback((item) => {
    setTempCount((prevCount) => prevCount + 1);
    setEditProduct(item);
    setIsModalOpen(true);
  }, []);

  let userPlan = parseInt(localStorage.getItem("subscription"), 10) ?? 1;

  useEffect(() => {
    switch (userPlan) {
      case 1:
        setMaxProductLimit(1);
        break;
      case 2:
        setMaxProductLimit(3);
        break;
      case 3:
        setMaxProductLimit(20);
        break;
      default:
        setMaxProductLimit(1);
        break;
    }
  }, []);
  const list = useMemo(
    () => (
      <ProductList
        handleEditProduct={handleEditProduct}
        updateProduct={tempCount}
        setProductCount={setProductCount}
      />
    ),
    [handleEditProduct, tempCount],
  );
  const isButtonDisabled = productCount >= maxProductLimit;
  return (
    <div className={classes.container}>
      <div className="d-flex align-items-center justify-content-between">
        {/* Our Products */}
        <Title level={3} className="m-0 mb-0">
          Our Products
        </Title>
        <Tooltip
          title={
            isButtonDisabled
              ? "Please upgrade to a premium plan to add more products"
              : ""
          }
        >
          <Button
            type="primary"
            htmlType="button"
            size="large"
            onClick={handleOpen}
            disabled={isButtonDisabled || loading}
          >
            + Add Product
          </Button>
        </Tooltip>
      </div>
      {list}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        width={776}
      >
        <AddEditProduct product={editProduct} handleOk={handleOk} />
      </Modal>
    </div>
  );
};

export default MyProducts;
