import { Divider, Empty, Skeleton } from "antd";
import { Fragment, useEffect, useMemo } from "react";
import ChatMessageItem from "./Item";
import {
  getMessageTimelineTime,
  showTimeline,
} from "../../../utils/chat.helper";
import classes from "./List.module.css";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "../../../services/firebase";

const ChatMessageList = ({
  floating,
  activeChatId,
  chatMessageLoading,
  chatMessages,
}) => {
  const id = localStorage.getItem("id");

  useEffect(() => {
    if (chatMessages?.length) {
      let timer = null;
      const scroller = document.getElementById("scroller");
      scroller?.scrollIntoView({ behavior: "instant", block: "start" });

      const lastMsg = chatMessages[chatMessages.length - 1];
      if (!lastMsg.isRead && lastMsg.receiver === id) {
        timer = setTimeout(readAllMessages, 1500, lastMsg);
      }
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessages, id]);

  const readAllMessages = async (msg) => {
    try {
      await updateDoc(doc(db, "chats", activeChatId), { [`unread_${id}`]: 0 });
      const batch = writeBatch(db);
      const mq = query(
        collection(db, "messages", msg.chatId, "list"),
        where("receiver", "==", id),
        where("isRead", "==", false),
      );
      const snapshot = await getDocs(mq);
      snapshot?.forEach((snap) => batch.update(snap.ref, { isRead: true }));
      batch.commit();
    } catch (err) {
      console.log("err on read messages - ", err.message);
    }
  };

  const messages = useMemo(
    () =>
      chatMessages?.map((message, index) => {
        const time = getMessageTimelineTime(message?.createdAt?.seconds);
        const showNewMsg = showTimeline(
          message?.createdAt?.seconds,
          chatMessages[index - 1]?.createdAt?.seconds,
        );
        return (
          <Fragment
            key={`chat-messages-${message?.id}-${index}-${Number(floating)}`}
          >
            {showNewMsg ? <Divider plain>{time}</Divider> : null}
            {message?.showNewMsg ? (
              <Fragment>
                <div id="scroller" />
                <Divider style={{ color: "#45566d" }} plain>
                  New Messages
                </Divider>
              </Fragment>
            ) : null}
            <ChatMessageItem id={id} item={message} />
            {index === chatMessages.length - 1 ? <div id="scroller" /> : null}
          </Fragment>
        );
      }),
    [chatMessages, floating, id],
  );

  return (
    <div
      className={classes["chat-message-list"]}
      style={{ height: floating ? "360px" : "calc(100vh - 266px)" }}
    >
      {chatMessageLoading ? (
        Array.from({ length: 4 }).map((_, index) => (
          <Skeleton
            key={`skeleton-${index}`}
            paragraph={{ rows: (index + 1) % 2 === 0 ? 2 : 1 }}
          />
        ))
      ) : chatMessages?.length ? (
        messages
      ) : (
        <Empty
          rootClassName={classes["chat-message-empty"]}
          description="No Chat Messages Found."
        />
      )}
    </div>
  );
};

export default ChatMessageList;
