import { Button, Col, Modal, Row } from "antd";
import classes from "./SubscriptionModal.module.css";
import { Typography } from "antd";

const { Title } = Typography;

const SubscriptionModal = (props) => {
  const TitleCMP = (
    <div className={classes.heading}>
      <Title level={4} style={{ marginBottom: "0px" }}>
        Select Subscription Plan
      </Title>
      <p style={{ fontSize: "12px", color: "gray", marginTop: "0px" }}>
        Choose the Perfect Subscription Plan for Your Needs and Budget
      </p>
    </div>
  );

  const plans = [
    {
      name: "Basic",
      defaultPrice: "Free",
      description: "Access to limited features & content for free",
      features: [
        "No cost, limited access",
        "Try before committing",
        "Unique content available",
        "Basic customer support",
      ],
    },
    {
      name: "Standard",
      defaultPrice: 85,
      description: "Access to more features at a reasonable cost",
      features: [
        "More features, content",
        "Better customer support",
        "Money-saving option",
        "Fewer limitations",
      ],
    },
    {
      name: "Premium",
      defaultPrice: 115,
      description: "Access to the full range of features and content",
      features: [
        "Full access, all features",
        "Exclusive content, features",
        "Best customer support",
        "Additional benefits included",
      ],
    },
  ];

  const PlanCmp = plans.map((plan, index) => (
    <Col xs={8} sm={8} md={8} lg={8} key={`plans-list-${plan?.name}-${index}`}>
      <div className={classes.container}>
        <div className={classes.headerdiv}>
          <Title level={5} className={classes.title}>
            {plan.name}
          </Title>
          {plan.name === "Basic" ? (
            <Title level={2} className={classes.title}>
              {plan.defaultPrice}
            </Title>
          ) : (
            <div className="d-flex align-items-center">
              <p style={{ fontSize: "14px", margin: "5px" }}>$</p>
              <Title level={2} className={classes.title}>
                {plan.defaultPrice}
              </Title>
              <span className={classes.monthTxt}>/month</span>
            </div>
          )}
          <p>{plan.description}</p>
        </div>
        <ul>
          {plan.features.map((feature, i) => (
            <li key={`features-${feature}-${i}`}>{feature}</li>
          ))}
        </ul>
        <Button
          type="primary"
          className="w-100"
          onClick={() => props.selectPlan(plan)}
        >
          Select
        </Button>
      </div>
    </Col>
  ));

  return (
    <Modal
      title={TitleCMP}
      open={props.isModalOpen}
      onCancel={props.modalClose}
      footer={null}
      width="80vw"
    >
      <Row gutter={16}>{PlanCmp}</Row>
    </Modal>
  );
};

export default SubscriptionModal;
