import { Avatar, Badge, Divider, Typography } from "antd";
import { getChatListTime } from "../../../utils/chat.helper";
import classes from "./Item.module.css";

const { Text, Paragraph, Title } = Typography;

const ChatItem = ({
  item,
  unreadCount,
  anotherUser,
  activeChat,
  divider,
  iconSize,
  onClick,
}) => {
  const time =
    item?.lastMessage?.createdAt?.seconds &&
    getChatListTime(item?.lastMessage?.createdAt?.seconds);
  return (
    <>
      <div
        className={
          classes["chat-item-container"] +
          (activeChat ? " " + classes["active"] : "")
        }
        onClick={!activeChat ? onClick : undefined}
      >
        <Avatar
          size={iconSize}
          style={{
            minWidth: `${iconSize}px`,
            minHeight: `${iconSize}px`,
            width: iconSize,
            height: iconSize,
          }}
          src={anotherUser?.avatar}
          alt={anotherUser?.name}
        >
          {anotherUser?.name[0] || "U"}
        </Avatar>
        <div
          style={{ width: `calc(100% - ${iconSize + 8}px)` }}
          className={classes["chat-item-detail"]}
        >
          <div className={classes["chat-item-title"]}>
            <Title
              level={5}
              ellipsis={{ tooltip: anotherUser?.name || "User" }}
              className={
                classes["chat-item-name"] +
                " " +
                (unreadCount > 0 ? classes["unread"] : "")
              }
            >
              {anotherUser?.name || "User"}
            </Title>
            <Text className={classes["chat-item-time"]}>{time}</Text>
          </div>
          <div className={classes["chat-item-desc"]}>
            <Paragraph
              ellipsis={{ rows: 2 }}
              className={classes["chat-item-message"]}
            >
              {item.lastMessage?.messageType === "text"
                ? item.lastMessage?.text
                : item.lastMessage?.messageType === "file"
                  ? "File"
                  : "No message"}
            </Paragraph>
            <Badge count={unreadCount} />
          </div>
        </div>
      </div>
      {divider && <Divider className="m-0" />}
    </>
  );
};

export default ChatItem;
