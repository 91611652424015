import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import {
  addWindow,
  setChatList,
  updateChatList,
} from "./store/slice/chat_slice";
import { db } from "./services/firebase";
import { RenderRoutes } from "./routes";
import useWindowSize from "./hooks/useWindowSize";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

import { getChatListFromSnapShot } from "./utils/chat.helper";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { width } = useWindowSize();
   // Load Google Analytics dynamically
   const loadGoogleAnalytics = () => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-16GPQXQJVP";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      gtag("js", new Date());
      gtag("config", "G-16GPQXQJVP");
    };
  };
    // Check cookie consent on first load
    useEffect(() => {
      if (getCookieConsentValue("userConsentCookie") === "true") {
        loadGoogleAnalytics();
      }
    }, []);

  const token = localStorage.getItem("token");

  // Below useEffect is for chat
  useEffect(() => {
    let unSubscribe = () => {};
    let initialCall = true;
    const id = localStorage.getItem("id");
    const cq = query(collection(db, "chats"), orderBy("updatedAt"));
    const getInitialData = async () => {
      try {
        const snapshot = await getDocs(cq);
        const list = await getChatListFromSnapShot(snapshot);
        dispatch(setChatList(list));
      } catch (err) {
        console.log("err", err);
      }
    };
    const startListenerForData = () => {
      let newChat;
      return onSnapshot(cq, (snapshot) => {
        snapshot.docChanges()?.forEach(async (changes) => {
          try {
            const data = JSON.parse(JSON.stringify(changes.doc.data()));
            if (
              (changes.type === "added" && data.createdAt) ||
              changes.type === "modified"
            ) {
              newChat = { ...data, id: changes.doc.id };
            }
          } catch (err) {
            throw new Error(err.message);
          }
        });
        const currentUser = newChat?.users?.some((u) => String(u.id) === id);
        if (currentUser) dispatch(updateChatList(newChat));
        const currentUserInitChat =
          typeof newChat?.lastMessage === "object" &&
          Object.keys(newChat?.lastMessage)?.length === 0
            ? String(newChat?.lastMessage?.sender) === id
            : true;
        if (initialCall) {
          initialCall = false;
        } else if (
          location.pathname !== "/app/chat" &&
          currentUser &&
          currentUserInitChat
        ) {
          dispatch(addWindow({ windowType: "chat" }));
          if (newChat) {
            dispatch(addWindow({ windowType: "message", activeChat: newChat }));
          }
        }
      });
    };
    if (token) {
      getInitialData();
      if (width > 767) {
        unSubscribe = startListenerForData();
      }
    }
    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line
  }, [token, width]);

  const routes = useMemo(() => <RenderRoutes />, []);

  return (
    <div>
      {routes}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        enableDeclineButton
        onAccept={loadGoogleAnalytics}
        onDecline={() => console.log("User declined cookies")}
        cookieName="userConsentCookie"
        expires={365}
      >
        This website uses cookies to enhance the user experience.{" "}
        <a href="/public/privacy" style={{ color: "white" }}>Learn more</a>
      </CookieConsent>
    </div>
  );
}

export default App;
