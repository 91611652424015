import { CodeOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Image, Row, Tabs, Typography } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import CompanyInfoTab from "./Details/CompanyInfoTab";
import classes from "./SupplierDetail.module.css";

import {
  addDoc,
  collection,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import notFoundImage from "../../../src/images/NoResult/not-found.jpg";
import useWindowSize from "../../hooks/useWindowSize";
import { db } from "../../services/firebase";
import { getUserPlan } from "../../services/GeneralService";
import { getAllProducts } from "../../services/MyProductService";
import {
  getCompanyInformation,
  getMemberInformation,
} from "../../services/MyProfileService";
import {
  GetAllSubscriptions,
  GetUserSubscription,
} from "../../services/StripeService";
import { addWindow, setActiveChat } from "../../store/slice/chat_slice";
import { fireStoreAutoId } from "../../utils/chat.helper";
import ArtworkInfoTab from "./Details/ArtworkInfoTab";
import CategoryProductInfoTab from "./Details/CategoryProductInfoTab";
import MemberInfoTab from "./Details/MemberInfoTab";
import QualityAssuranceInfoTab from "./Details/QualityAssuranceInfoTab";
import ProductDetailModal from "./Modal/ProductDetailModal";

const { Title } = Typography;

const SupplierDetail = () => {
  const [carousalItems, setCarousalItems] = useState([]);
  const [initializingChat, setInitializingChat] = useState(false);
  const chatListLoading = useSelector((state) => state.chat.chatListLoading);
  const chatList = useSelector((state) => state.chat.chatList);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [userCurrentPlan, setuserCurrentPlan] = useState(3);
  const [companyName, setCompanyName] = useState("");
  const [memberName, setMemberName] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [userType, setUserType] = useState("");
  const userObj = useSelector((state) => state.user.data);
  const [userPlan, setUserPlan] = useState(1);
  const auth = useSelector((state) => state.auth);

  async function fetchUserPlan() {
    try {
      const userId = auth?.id;
      const newplanid = await getUserPlan({ userId });
      if (newplanid.data) {
        setUserPlan(newplanid.data.plan.id);
      }
    } catch (error) {
      console.error("Error fetching user plan:", error);
    }
  }

  useEffect(() => {
    fetchUserPlan();
  });

  const tabInfo = [
    {
      tab: "Company Information",
      key: "1",
      content: (
        <CompanyInfoTab currentPlan={userCurrentPlan} userType={userType} />
      ),
    },
    {
      tab: "Member Information",
      key: "2",
      content: (
        <MemberInfoTab currentPlan={userCurrentPlan} userType={userType} />
      ),
    },
    {
      tab: "Sourcing & Shipping Information",
      key: "3",
      content: (
        <CategoryProductInfoTab
          currentPlan={userCurrentPlan}
          userType={userType}
        />
      ),
    },
    {
      tab: "Artwork Information",
      key: "4",
      content: (
        <ArtworkInfoTab currentPlan={userCurrentPlan} userType={userType} />
      ),
    },
    {
      tab: "Quality Assurance Information",
      key: "5",
      content: (
        <QualityAssuranceInfoTab
          currentPlan={userCurrentPlan}
          userType={userType}
        />
      ),
    },
  ];

  const TabCtrl = (
    <Tabs defaultActiveKey="1">
      {tabInfo.map((tab) => (
        <TabPane tab={tab.tab} key={tab.key} style={{ width: "100%" }}>
          {tab.content}
        </TabPane>
      ))}
    </Tabs>
  );

  async function fetchData() {
    try {
      const res = await getCompanyInformation({ userId: params.supplierId });
      if (res && res.data) {
        const data = res.data;
        // if (data.companyLogoURL) {
        //   setLogoUrl(data.companyLogoURL);
        //   setCompanyName(data.companyName);
        // }

        setCompanyName(data.companyName);
      }

      //for member name start
      const res11 = await getMemberInformation({ userId: params.supplierId });
      if (res11 && res11.data) {
        const data = res11.data;
        if (data?.name) {
          setMemberName(data?.name);
        }
        if (data?.profilePath) {
          setProfilePic(data?.profilePath);
        }
        if (data?.userType) {
          setUserType(data?.userType);
        }
      }

      //for mamber name end

      const body = {
        offset: 0,
        userId: params.supplierId,
      };

      const res2 = await getAllProducts(body);
      if (res2 && res2.data) {
        const newArray = res2?.data?.content?.map((element) => ({
          src: element.mediaURLs[0],
          product: element,
        }));
        setCarousalItems(newArray);
      }
    } catch (err) {
      // Handle error
    }
  }
  //for get user subscription plan start

  const getSubscriptionData = async () => {
    try {
      const res = await GetAllSubscriptions();

      var Plans = [];
      if (res && res.data) {
        const sortedPlans = res.data
          .slice()
          .sort((a, b) => a.defaultPrice - b.defaultPrice);
        Plans.length = 0;
        Plans = [...sortedPlans];
      }

      //get user subscription start
      const res2 = await GetUserSubscription({
        userId: userObj.id,
      });

      if (res2 && res2.data) {
        const targetIndex = Plans.findIndex(
          (item) => item.id === res2.data?.productId,
        );

        if (targetIndex == 0) {
          setuserCurrentPlan(2); //beacuse we set standard plan index 2
        } else if (targetIndex == 1) {
          setuserCurrentPlan(1); ////beacuse we set Premium plan index 1
        }
      } else {
        setuserCurrentPlan(3); ////beacuse we set Basic plan index 3
      }
      //get user subscription end
    } catch (error) {
      /* empty */
    }
  };

  //for user subscription plan end

  useEffect(() => {
    fetchData();
    getSubscriptionData(); //for user subscription plan
    // eslint-disable-next-line
  }, []);

  const initiateChat = async () => {
    try {
      const id = userObj.id;
      const username = userObj.userName;
      const receiverId = String(params.supplierId);
      if (id === receiverId) {
        throw new Error("You can't chat with your own.");
      }
      setInitializingChat(true);
      const chatExist = chatList.find((item) =>
        item.users.every(
          (u) => String(u.id) === id || String(u.id) === receiverId,
        ),
      );
      if (chatExist) {
        if (width < 768) {
          dispatch(setActiveChat(chatExist));
          navigate("/app/chat");
        } else {
          dispatch(addWindow({ windowType: "chat" }));
          dispatch(addWindow({ windowType: "message", activeChat: chatExist }));
        }
        setInitializingChat(false);
        return;
      }
      const receiverUserDetails = await getMemberInformation({
        userId: receiverId,
      });
      if (!id || !username || !receiverId || !receiverUserDetails?.data?.name) {
        throw new Error("Required user details not found.");
      }
      const sender = {
        id: Number(id),
        name: username || "",
        avatar: userObj?.profilePath || "",
      };
      const receiver = {
        id: Number(receiverId),
        name: receiverUserDetails?.data?.name || "",
        avatar: receiverUserDetails?.data?.profilePath || "",
      };
      const users = [sender, receiver];
      const data = {
        chatId: fireStoreAutoId(),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        lastMessage: {},
        users,
        [`unread_${receiverId}`]: 0,
        [`unread_${id}`]: 0,
      };
      const newChat = await addDoc(collection(db, "chats"), data);
      const newChatTemp = await getDoc(newChat);
      const newActiveChat = { ...newChatTemp.data(), id: newChatTemp.id };
      if (width < 768) {
        dispatch(setActiveChat(newActiveChat));
        navigate("/app/chat");
      } else {
        dispatch(addWindow({ windowType: "chat" }));
        dispatch(
          addWindow({ windowType: "message", activeChat: newActiveChat }),
        );
      }
      setInitializingChat(false);
    } catch (err) {
      setInitializingChat(false);
      console.log("err on chat add---", err);
    }
  };

  const settings = {
    arrows: true,
    infinite: true,
    slidesToShow: carousalItems.length > 6 ? 6 : carousalItems.length,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    className: classes["slider-root"],
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: carousalItems.length > 4 ? 4 : carousalItems.length,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: carousalItems.length > 3 ? 3 : carousalItems.length,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: carousalItems.length > 2 ? 2 : carousalItems.length,
        },
      },
    ],
  };

  //products details model start
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProduct, setCurrentProdct] = useState({});

  const carasoulClickHandler = (product) => {
    setCurrentProdct(product);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  //products details model end

  const carasoul = carousalItems.map((item, index) => (
    <div key={`carasoul-${item.src}-${index}`}>
      <Image
        src={item.src ? item.src : notFoundImage}
        className={classes.carasoulItem}
        rootClassName={classes.carsoulRootImage}
        onClick={() => {
          carasoulClickHandler(item.product);
        }}
        preview={false}
      />
    </div>
  ));

  const title =
    userType == "SUPPLIER" ? "Supplier details" : "Retailer details";

  return (
    <section className={classes["containerSection"] + " dashboard"}>
      <Row className={classes["container"]}>
        <Col className="w-100">
          <Title level={3}>{title}</Title>
          <section className={classes["containerChild"]}>
            <div className={classes["user-detail"]}>
              <Avatar
                src={profilePic}
                size="large"
                className={classes["avatar"]}
              />

              <div className={classes["user-info"]}>
                <Title level={4}>{memberName}</Title>
                <div className={classes["user-tags"]}>
                  <div
                    className="d-flex align-items-center"
                    style={{ marginRight: "10px", color: "black" }}
                  >
                    <CodeOutlined
                      style={{
                        fontSize: "20px",
                        marginRight: "5px",
                        color: "black",
                      }}
                    />
                    <p className="m-0">{companyName}</p>
                  </div>
                  {/* <div
                    className="d-flex align-items-center"
                    style={{ marginRight: "10px" }}
                  >
                    <CodeOutlined
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <p className="m-0">Bredablick Facility Services</p>
                  </div> */}
                </div>
              </div>
            </div>

            <div style={{ marginLeft: "auto" }}>
              <Button
                htmlType="button"
                size="large"
                onClick={() => initiateChat()}
                disabled={chatListLoading || initializingChat || userPlan == 1}
              >
                Chat
              </Button>
            </div>
          </section>

          <section className={classes["containerChild2"]}>{TabCtrl}</section>

          <section className={classes["containerChild2"]}>
            <Title level={4}>Products</Title>
            <Slider {...settings}>{carasoul}</Slider>
          </section>
        </Col>
      </Row>
      {isModalOpen && (
        <ProductDetailModal
          handleOk={handleOk}
          handleCancel={handleOk}
          showModel={isModalOpen}
          product={currentProduct}
        />
      )}
    </section>
  );
};

export default SupplierDetail;
