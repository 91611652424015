import React, { useEffect, useState } from "react";
import "./PrivacyPolicyPage.css";
import { Col, Row, Skeleton } from "antd";
import { getStaticPage } from "../../services/GeneralService";

const PrivacyPolicyPage = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getStaticPage({ searchKeyword: "" });

      const targetObject = res.data.content.find(
        (obj) => obj.pageName === "Privacy Policy",
      );
      if (targetObject) {
        setData(targetObject?.content);
        setLoading(false);
      } else {
        setData("");
        setLoading(false);
      }
    } catch (error) {
      setData("");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const SkellotonCtrl = (
    <>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
    </>
  );
  return (
    <Row justify="center">
      <Col xs={22} md={22} lg={22} xl={22} xxl={22}>
        {!loading ? (
          <div
            className="privacy-policy"
            dangerouslySetInnerHTML={{ __html: data }}
          />
        ) : (
          SkellotonCtrl
        )}
      </Col>
    </Row>
  );
};

export default PrivacyPolicyPage;
