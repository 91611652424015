const getDateObj = (d) => {
  const dateObj = new Date();
  dateObj.setUTCFullYear(1970);
  dateObj.setUTCMonth(0);
  dateObj.setUTCDate(1);
  dateObj.setUTCHours(0);
  dateObj.setUTCMinutes(0);
  dateObj.setUTCSeconds(d);

  const date = dateObj.getDate();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();

  return { dateObj, date, month, year };
};

export const getTime = (d) => {
  const { dateObj } = getDateObj(d);
  const hours = dateObj.getHours() % 12 || 12;
  const minutes = dateObj.getMinutes();

  return hours + ":" + minutes + " " + (hours >= 12 ? "pm" : "am");
};

export const getChatListTime = (d) => {
  const { date, month, year } = getDateObj(d);
  const s = Math.ceil(new Date().getTime() / 1000);
  const { date: tDate, month: tMonth, year: tYear } = getDateObj(s);

  const finalTime = getTime(d);
  if (date === tDate && month === tMonth && year === tYear) {
    return finalTime;
  } else {
    return date + "/" + month + "/" + year;
  }
};

const months = [
  "Jan",
  "Fab",
  "March",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getMessageTimelineTime = (d) => {
  const { date, month, year } = getDateObj(d);
  return date + " " + months[month] + ", " + year;
};

export const showTimeline = (currentTime, prevTime) => {
  const { date, month, year } = getDateObj(currentTime);
  const { date: pDate, month: pMonth, year: pYear } = getDateObj(prevTime);
  return date !== pDate || month !== pMonth || year !== pYear;
};

export const fireStoreAutoId = () => {
  const CHARS =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let autoId = "";

  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
  }
  return autoId;
};

export const getChatListFromSnapShot = (snapshot) =>
  new Promise((resolve, reject) => {
    try {
      const list = [];
      const id = localStorage?.getItem("id");
      snapshot.forEach(async (doc) => {
        try {
          const data = JSON.parse(JSON.stringify(doc.data()));
          const exist = data?.users?.some((user) => user.id === id);
          if (exist) list.push({ ...data, id: doc.id });
        } catch (err) {
          throw new Error(err.message);
        }
      });
      resolve(list);
    } catch (err) {
      reject(err.message);
    }
  });

export const getMessagesFromSnapShot = (snapshot) =>
  new Promise((resolve, reject) => {
    try {
      const list = [];
      snapshot.forEach(async (doc) => {
        try {
          const data = JSON.parse(JSON.stringify(doc.data()));
          list.push({ ...data, id: doc.id });
        } catch (err) {
          throw new Error(err.message);
        }
      });
      resolve(list);
    } catch (err) {
      reject(err.message);
    }
  });

export const addTimelineInMessages = (msgList) =>
  new Promise((resolve, reject) => {
    try {
      const finalMsgList = [];
      let firstUnread = true;
      const id = localStorage?.getItem("id");
      msgList?.forEach((item) => {
        if (item?.createdAt) {
          const showNewLine =
            item.receiver === id && item.isRead === false && firstUnread;
          if (showNewLine) firstUnread = false;
          finalMsgList.push({ ...item, showNewMsg: !!showNewLine });
        }
      });
      resolve(finalMsgList);
    } catch (err) {
      reject(err.message);
    }
  });

export const getNewMessage = (changes) =>
  new Promise((resolve, reject) => {
    try {
      const data = JSON.parse(JSON.stringify(changes.doc.data()));
      if (
        (changes.type === "added" || changes.type === "modified") &&
        data.createdAt
      ) {
        const dataObj = { ...data, id: changes.doc.id, showNewMsg: false };
        resolve(dataObj);
      }
    } catch (err) {
      reject(err);
    }
  });
