import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Image,
  List,
  Pagination,
  Popconfirm,
  Skeleton,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import notFoundImage from "../../../src/images/NoResult/not-found.jpg";
import { deleteProduct } from "../../services/MyProductService";
import { getAllProducts } from "../../store/slice/generalData_slice";
import classes from "./ProductList.module.css";

const ProductList = ({ handleEditProduct, updateProduct, setProductCount }) => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const dispatch = useDispatch();
  const userObj = useSelector((state) => state.user.data);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [paginationFields, setPaginationField] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: 0,
    totalElements: 0,
  });

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isSmallScreen = screenWidth < 600;

  const getProductData = async (data) => {
    try {
      if (!data?.userId) {
        throw Error("No user details found.");
      }
      const res = await dispatch(getAllProducts(data)).unwrap();
      setProductCount(res?.data?.totalElements);
      setProducts(res?.data?.content);
      setPaginationField({
        totalPage: res?.data?.totalPage,
        pageSize: res?.data?.pageSize,
        totalElements: res?.data?.totalElements,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Error:", err);
    }
  };

  const onPageChange = (page) => {
    const id = userObj.id;
    const data = {
      offset: 0,
      pageNumber: page - 1,
      pageSize: paginationFields.pageSize,
      userId: id,
    };
    getProductData(data);
  };

  useEffect(() => {
    const id = userObj?.id;
    const data = {
      offset: 0,
      pageNumber: paginationFields?.currentPage - 1 || 1,
      pageSize: paginationFields?.pageSize,
      userId: id,
    };
    getProductData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProduct, userObj]);

  const pagination = useMemo(
    () => (
      <Pagination
        hideOnSinglePage
        current={paginationFields.currentPage}
        pageSize={paginationFields.pageSize}
        total={paginationFields.totalElements}
        onChange={onPageChange}
        style={{ textAlign: "center", marginBlock: "10px" }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationFields],
  );

  const onDelete = useCallback(
    async (item) => {
      try {
        const res = await deleteProduct({ id: item?.id });
        if (res) {
          toast.success(res.message);
          const id = userObj.id;
          const data = {
            offset: 0,
            pageNumber: paginationFields.currentPage - 1,
            pageSize: paginationFields.pageSize,
            userId: id,
          };
          getProductData(data);
        } else {
          throw new Error(res.message);
        }
      } catch (err) {
        toast.error(err);
      }
    },
    [paginationFields.currentPage, paginationFields.pageSize],
  );

  const renderItem = useCallback(
    (item) => (
      <List.Item
        actions={[
          <Button
            key={`edit-btn`}
            className={classes.ediDltBtn}
            onClick={() => handleEditProduct(item)}
          >
            <EditOutlined />
            {isSmallScreen ? null : "Edit"}
          </Button>,
          <Popconfirm
            key={`popup-btn`}
            title="Delete Product"
            description="Are you sure to delete this product?"
            onConfirm={() => onDelete(item)}
            okText="Yes"
            cancelText="No"
          >
            <Button className={classes.ediDltBtn}>
              <DeleteOutlined />
              {isSmallScreen ? null : "Delete"}
            </Button>
          </Popconfirm>,
        ]}
      >
        <Skeleton avatar title={false} loading={loading} active>
          <Image
            src={item?.mediaURLs?.length ? item.mediaURLs[0] : notFoundImage}
            rootClassName={classes["img-container"]}
            className={classes.img}
            height={isSmallScreen ? 50 : 100}
            width={isSmallScreen ? 50 : 100}
          />
          <List.Item.Meta
            title={item?.name}
            description={item?.description}
            style={{ marginLeft: "10px", marginTop: "-10px" }}
          />
        </Skeleton>
      </List.Item>
    ),
    [loading, onDelete, handleEditProduct],
  );

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3 className={classes.listHeading + " ml-10"}></h3>
        <h3 className={classes.listHeading + " mr-10"}>Action</h3>
      </div>
      <Divider className="m-0" />
      {products?.length > 0 && (
        <List
          loading={loading}
          itemLayout="horizontal"
          loadMore={pagination}
          dataSource={products}
          renderItem={renderItem}
          // style={{
          //   paddingBottom: products.length < 3 ? "243px" : "0",
          // }}
        />
      )}
    </>
  );
};

export default ProductList;
