import axios from "axios";

const Request = (options) => {
  const BASE_URL = process.env.REACT_APP_JAVA_APP_API_PATH;

  const authToken = localStorage.getItem("token");
  const headers = options.headers || {};
  if (authToken) {
    headers["access-token"] = authToken;
  }
  const config = {
    headers,
    url: BASE_URL + options.url,
    method: options.method,
    data: options.body,
  };

  if (!(options.body instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }

  return axios
    .request(config)
    .then((response) => {
      if (response?.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        localStorage.clear();
        location.replace("/public/signin");
        return error;
      } else {
        return error.response.data;
      }
    });
};

export default Request;
