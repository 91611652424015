import { LikeOutlined } from "@ant-design/icons";
import { Button, Progress } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserIcon from "../../images/LandingPage/user_icon.png";
import classes from "./ProfileCompleteNotice.module.css";

const ProfileCompleteNotice = () => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const percentage = useSelector((state) => state.percentage.value);
  const navigate = useNavigate();
  const userObj = useSelector((state) => state.user.data);

  const completeNowClickHandler = () => {
    navigate("/app/createprofile");
  };

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isSmallScreen = screenWidth < 767;

  const titleForBadge =
    userObj?.user_type === "SUPPLIER" ? "Supplier" : "Retailer";
  return (
    <div className={classes["bar"]}>
      <div className="d-flex align-items-center">
        <img src={UserIcon} alt="user_icon" className={classes["img"]} />
        <div>
          <h3 className={classes["text1"]}>
            Complete Your {titleForBadge} Profile for Maximum Visibility!
          </h3>
          <h5 className={classes["text2"]}>
            You won’t be able to access some of the key features of this
            platform without completing the profile.
          </h5>
        </div>
      </div>
      <div className={classes["percentage"]}>
        <div className="d-flex align-items-center">
          <Progress
            type="circle"
            percent={percentage}
            size={"small"}
            width={isSmallScreen ? 20 : 40}
            height={isSmallScreen ? 20 : 40}
            strokeWidth={12}
            strokeColor="#fff"
          />
          <div className={classes["percentageSection"]}>
            <h3 className={classes["text"]}>
              {percentage}%
              {percentage === "100" && (
                <LikeOutlined style={{ marginLeft: "5px" }} />
              )}
            </h3>
            <h5 className={classes["textProfile"]}>Profile Complete</h5>
          </div>
        </div>
        <Button
          className={classes["complete-btn"]}
          onClick={completeNowClickHandler}
        >
          {percentage === "100" ? "Update Profile" : "Complete Now"}
        </Button>
      </div>
    </div>
  );
};

export default ProfileCompleteNotice;
